import React, { useState, useEffect } from "react";
import { FaArchive, FaEdit, FaPause } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import { AlertModal } from "./components/alert-modal";
import { DebugModal } from "./components/debug-modal";
import { InterventionModal } from "./components/intervention-modal";
import { Input } from "@headlessui/react";
import { formatDate } from "../util/dateUtils";

const RcaAlert = ({ alertText, onClick }) => {
  return (
    <div
      className="p-4 hover:bg-gray-50 transition-colors cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center space-x-4">
        <div className="flex-1">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm font-medium text-gray-900">{alertText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function RCA() {
  const [isLoadingStatusCounts, setIsLoadingStatusCounts] = useState(true);
  const [isLoadingAlerts, setIsLoadingAlerts] = useState(true);
  const reportStatusCounts = {};
  const rcaAlerts = [
    "FCCD distribution is possibily wider by 20% than optimal at M1 FCCD",
    "Etch failing, array is out of bound for lot impacting point of contact",
  ];

  const [selectedAlert, setSelectedAlert] = useState(null);
  const [isDebuggedModalOpen, setIsDebuggedModalOpen] = useState(false);
  const [isInterventionModalOpen, setIsInterventionModalOpen] = useState(false);

  const handleClick = (id) => {
    // Implement upload logic here
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingStatusCounts(false);
      setIsLoadingAlerts(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const StatsSkeleton = () => (
    <div className="animate-pulse bg-white rounded-lg shadow-sm border border-gray-200 border-solid p-4">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center space-x-2">
          <div className="w-8 h-8 bg-gray-200 rounded-lg"></div>
          <div className="h-4 w-20 bg-gray-200 rounded"></div>
        </div>
        <div className="h-8 w-12 bg-gray-200 rounded"></div>
      </div>
      <div className="h-3 w-32 bg-gray-200 rounded"></div>
    </div>
  );

  const ActivitySkeleton = () => (
    <div className="px-4 py-3 flex items-center justify-between animate-pulse">
      <div className="flex items-center space-x-3">
        <div className="h-7 w-7 rounded-full bg-gray-200"></div>
        <div>
          <div className="h-4 w-24 bg-gray-200 rounded mb-1"></div>
          <div className="h-3 w-16 bg-gray-200 rounded"></div>
        </div>
      </div>
      <div className="h-3 w-20 bg-gray-200 rounded"></div>
    </div>
  );

  const AlertsSkeleton = () => (
    <tr className="animate-pulse">
      <td className="px-6 py-4 w-12">
        <div className="h-4 w-4 bg-gray-200 rounded"></div>
      </td>
      <td className="px-6 py-4">
        <div className="h-4 w-48 bg-gray-200 rounded"></div>
      </td>
      <td className="px-6 py-4">
        <div className="h-4 w-24 bg-gray-200 rounded"></div>
      </td>
      <td className="px-6 py-4">
        <div className="h-4 w-24 bg-gray-200 rounded"></div>
      </td>
      <td className="px-6 py-4">
        <div className="h-4 w-24 bg-gray-200 rounded"></div>
      </td>
    </tr>
  );

  const currentItems = [
    {
      email_id: "irving@thirdaiautomation.com",
      action: "Uploaded a document",
      timestamp: "2023-10-01 10:00",
    },
    {
      email_id: "mark@thirdaiautomation.com",
      action: "Deleted a file",
      timestamp: "2023-10-01 11:00",
    },
    {
      email_id: "helena@thirdaiautomation.com",
      action: "Updated profile",
      timestamp: "2023-10-01 12:00",
    },
  ];

  return (
    <div className="rca-container">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="mb-6 sticky top-4 bg-white z-10">
          <Input
            type="text"
            placeholder="Search..."
            className="w-full p-4 border border-solid border-gray-200 shadow-md rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          {isLoadingStatusCounts ? (
            <>
              <StatsSkeleton />
              <StatsSkeleton />
              <StatsSkeleton />
              <StatsSkeleton />
            </>
          ) : (
            [
              {
                label: "Alerts",
                value: reportStatusCounts?.completed || 2,
                icon: <BiSolidError className="h-5 w-5 text-red-500" />,
                description: "Reports completed this month",
                colors: "bg-red-50 group-hover:bg-red-100",
              },
              {
                label: "Debugged",
                value: reportStatusCounts?.draft || 3,
                icon: <FaEdit className="w-5 h-5 text-blue-600" />,
                description: "Reports in draft state",
                colors: "bg-blue-50 group-hover:bg-blue-100",
                onClick: () => setIsDebuggedModalOpen(true),
              },
              {
                label: "Intervention Needed",
                value: reportStatusCounts?.hold || 1,
                icon: <FaPause className="w-5 h-5 text-yellow-600" />,
                description: "Reports currently on hold",
                colors: "bg-yellow-50 group-hover:bg-yellow-100",
                onClick: () => setIsInterventionModalOpen(true),
              },
              {
                label: "Pinned",
                value: reportStatusCounts?.archived || 5,
                icon: <FaArchive className="w-5 h-5 text-gray-600" />,
                description: "Archived reports",
                colors: "bg-gray-50 group-hover:bg-gray-100",
              },
            ].map((stat) => (
              <div
                key={stat.label}
                className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid p-4 hover:shadow-md transition-all duration-200 group cursor-pointer"
                onClick={stat.onClick}
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center space-x-2">
                    <div
                      className={`p-1.5 rounded-lg transition-colors duration-200 ${stat.colors}`}
                    >
                      {stat.icon}
                    </div>
                    <h3 className="text-base font-medium text-gray-900">
                      {stat.label}
                    </h3>
                  </div>
                  <span className="text-2xl font-semibold text-gray-900 tabular-nums">
                    {stat.value}
                  </span>
                </div>
                <p className="text-xs text-gray-500">{stat.description}</p>
              </div>
            ))
          )}
        </div>

        {/* Alert box */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
          <div className="px-4 py-3 border-b border-gray-200">
            <h3 className="text-base font-medium text-gray-900">Alerts</h3>
          </div>
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 w-full overflow-x-scroll">
            {isLoadingAlerts ? (
              <>
                <AlertsSkeleton />
                <AlertsSkeleton />
                <AlertsSkeleton />
                <AlertsSkeleton />
              </>
            ) : rcaAlerts.length === 0 ? (
              <div className="p-8 text-center text-gray-500">No alerts yet</div>
            ) : (
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 sticky top-0 z-10">
                  <tr>
                    <th scope="col" className="px-6 py-3 w-12">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                    </th>
                    {[
                      "Description",
                      "Module",
                      "Status",
                      "Priority",
                      "Time",
                    ].map((col) => (
                      <th
                        key={col}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {col}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {rcaAlerts.map((alert, index) => (
                    <tr
                      key={index}
                      className="group hover:bg-gray-50 transition-colors duration-150"
                    >
                      <td className="px-6 py-4 w-12 shrink-0">
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                      </td>
                      <td className="px-6 py-4 min-w-[250px]">
                        <div className="text-sm text-gray-900">
                          <RcaAlert
                            key={index}
                            alertText={alert}
                            onClick={() => setSelectedAlert(alert)}
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-500">
                          {alert.module || "N/A"}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-500">
                          {alert.status || "Open"}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-500">
                          {alert.priority || "Medium"}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-500">
                          {formatDate(new Date("Jan 16, 2025"), "MMM d, yyyy")}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              // <ul className="divide-y divide-gray-200">
              //   {rcaAlerts.map((alert, index) => (
              //     <RcaAlert
              //       key={index}
              //       alertText={alert}
              //       onClick={() => setSelectedAlert(alert)}
              //     />
              //   ))}
              // </ul>
            )}
          </div>
        </div>

        {/* Activity Log */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid">
          <div className="px-4 py-3 border-b border-gray-200">
            <h3 className="text-base font-medium text-gray-900">
              Recent Activity
            </h3>
          </div>
          <div className="divide-y divide-gray-200">
            {isLoadingStatusCounts ? (
              <>
                <ActivitySkeleton />
                <ActivitySkeleton />
                <ActivitySkeleton />
              </>
            ) : (
              currentItems.map((item, i) => (
                <div
                  key={i}
                  className="px-4 py-3 flex items-center justify-between hover:bg-gray-50 transition-colors duration-150"
                >
                  <div className="flex items-center space-x-3">
                    <div className="h-7 w-7 rounded-full bg-gray-100 flex items-center justify-center">
                      <span className="text-xs text-gray-600">
                        {item.email_id.charAt(0).toUpperCase()}
                      </span>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {item.email_id}
                      </p>
                      <p className="text-xs text-gray-500">{item.action}</p>
                    </div>
                  </div>
                  <span className="text-xs text-gray-500">
                    {item.timestamp}
                  </span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {/* Alert Modal */}
      <AlertModal
        selectedAlert={selectedAlert}
        onClose={() => setSelectedAlert(null)}
      />
      {/* Debugged Modal */}
      <DebugModal
        isOpen={isDebuggedModalOpen}
        onClose={() => setIsDebuggedModalOpen(false)}
      />
      {/* Intervention Modal */}
      <InterventionModal
        isOpen={isInterventionModalOpen}
        onClose={() => setIsInterventionModalOpen(false)}
      />
    </div>
  );
}
