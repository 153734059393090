import { useEffect, useState, useRef } from "react";
import useWorkspaceStore from "../../../stores/workspaceStore";
import { Toast } from "primereact/toast";
import { useUser } from "../../../context/userContext";

export const LeaveWorkspaceModal = ({
  isOpen,
  onClose,
  workspace,
  onSuccess = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { deleteWorkspaceUser } = useWorkspaceStore();
  const { user } = useUser();
  const toast = useRef(null);

  if (!isOpen || !workspace) return null;

  const handleLeave = async () => {
    setIsLoading(true);
    try {
      await deleteWorkspaceUser(workspace.workspace_id, user.id);
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Failed to leave workspace:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <Toast ref={toast} position="bottom-right" />
      <div className="bg-white rounded-lg w-full max-w-md px-6 py-3">
        <div className="flex justify-between items-center">
          <h3 className="text-[20px] leading-none font-semibold">
            Leave Workspace
          </h3>
          <button onClick={onClose} className="text-gray-400 p-1">
            <i className="pi pi-times text-lg" />
          </button>
        </div>

        <div>
          <p className="text-[16px] text-gray-700 mb-8">
            Are you sure you want to leave the workspace "{workspace.name}"?
          </p>

          <div className="flex justify-end gap-3 pb-3">
            <button
              onClick={onClose}
              className="px-5 py-2 text-[15px] text-gray-600 hover:bg-gray-50 rounded"
            >
              Cancel
            </button>
            <button
              onClick={handleLeave}
              disabled={isLoading}
              className="px-5 py-2 text-[15px] text-white bg-red-500 hover:bg-red-600 rounded disabled:opacity-50"
            >
              {isLoading ? "Leaving..." : "Leave"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
