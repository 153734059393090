import React, { useEffect, useState, useCallback, memo, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "lodash";
import QuestionItem from "./question-item";
import { useDropzone } from "react-dropzone";
import { AiOutlineFilePdf, AiOutlineUpload } from "react-icons/ai";
import { TbTemplate } from "react-icons/tb";
import { FaEye, FaList, FaTable } from "react-icons/fa6";
import S3Service from "../../service/S3service";
import ReportService from "../../service/ReportService";
import WorkspaceService from "../../service/WorkspaceService";
import TemplateOne from "./templates/TemplateOne";
import TemplateTwo from "./templates/TemplateTwo";
import TemplateThree from "./templates/TemplateThree";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useReportStore from "../../stores/reportStore";
import ChooseTemplateModal from "./choose-template-modal";
import { exportReportToPDF } from "./pdfExport";
import MultiApproverSelector from "./multi-approver-selector";
import { useUser } from "../../context/userContext";
import { FaCheck, FaEllipsisH, FaTimes } from "react-icons/fa";
import ApprovalRejectionModal from "./approvalRejectionModal";
import ApproversHoverView from "./approvers-hover-view";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSeparator,
} from "@headlessui/react";
import { formatDate, formatLastUpdated } from "../util/dateUtils";

const templateComponents = {
  template1: TemplateOne,
  template2: TemplateTwo,
  template3: TemplateThree,
  default: TemplateOne,
};

const ReportDetails = ({ reportId, onShowToast }) => {
  const [report, setReport] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [processingApproval, setProcessingApproval] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("template1");
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [showLogoUpload, setShowLogoUpload] = useState(false);
  const { user } = useUser();
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [commentAction, setCommentAction] = useState(null);
  const [summary, setSummary] = useState(null);
  const [tableOfContent, setTableOfContent] = useState(null);
  const [isReportSubmitting, setISReportSubmitting] = useState(false);

  const {
    fetchReportApprovers,
    updateReportApprover,
    isLoadingApprovers,
    isUpdatingApprover,
  } = useReportStore();

  const fetchAndsetLatestReport = useCallback(async () => {
    const reportData = await ReportService.getReportById(reportId);
    const approvers = await fetchReportApprovers(reportId);
    setReport({ ...reportData, approvers });
  }, [reportId, fetchReportApprovers]);

  const initializeReportData = useCallback(async () => {
    setIsLoading(true);
    try {
      await fetchAndsetLatestReport();
    } catch (error) {
      console.error("Error fetching report:", error);
      onShowToast("error", "Error", "Failed to fetch report");
    } finally {
      setIsLoading(false);
    }
  }, [fetchAndsetLatestReport]);

  useEffect(() => {
    if (reportId) {
      initializeReportData();
    }
  }, [reportId, initializeReportData]);

  const handlePreview = useCallback(() => {
    const previewUrl = `${window.location.origin}/preview/report/${reportId}`;
    window.open(previewUrl, "_blank");
  }, [reportId]);

  const handleSummary = useCallback(async () => {
    const reportSummary = await ReportService.getReportSummary(reportId);
    console.log("reportSummary", reportSummary);
    setSummary(reportSummary?.summarizer?.summary);
  }, [reportId]);

  const handleTableOfContents = useCallback(async () => {
    const reportTOC = await ReportService.getReportTableOfContents(reportId);
    console.log("reportTOC", reportTOC);
    setTableOfContent(reportTOC?.toc);
  }, [reportId]);

  const submitWithdrawReport = useCallback(async () => {
    try {
      setISReportSubmitting(true);
      const reportId = report?.report_id;
      await ReportService.updateReport(reportId, {
        is_submitted: !report.is_submitted,
      });
      const isSubmitting = !report.is_submitted;
      isSubmitting ? 
        onShowToast("success", "Report Submitted", "Report has been submitted")
        : onShowToast("success", "Report Withdrawn", "Report has been withdrawn");
      await fetchAndsetLatestReport();
    } catch (error) {
      console.error(error);
    } finally {
      setISReportSubmitting(false);
    }
  }, [report, setISReportSubmitting]);

  const debouncedUpdateReport = useCallback(
    debounce(async (updatedReport) => {
      try {
        await ReportService.updateReport(
          updatedReport.report_id,
          updatedReport,
        );
        setReport(updatedReport);
      } catch (error) {
        console.error("Error updating report:", error);
        onShowToast("error", "Error", "Failed to update report");
      }
    }, 500),
    [onShowToast],
  );

  useEffect(() => {
    return () => {
      debouncedUpdateReport.cancel();
    };
  }, [debouncedUpdateReport]);

  const handleLogoUpload = useCallback(
    async (file) => {
      if (!file || !report) return;

      setIsUploadingLogo(true);
      try {
        const response = await WorkspaceService.uploadFile(file);

        if (response.acknowledgement === "success") {
          const updatedReport = {
            ...report,
            logo: response.file_url,
            updatedAt: new Date().toISOString(),
          };
          setReport(updatedReport);
          debouncedUpdateReport(updatedReport);
        }
      } catch (error) {
        console.error("Error uploading logo:", error);
        onShowToast("error", "Upload Failed", "Failed to upload logo");
      } finally {
        setIsUploadingLogo(false);
      }
    },
    [report, debouncedUpdateReport, onShowToast],
  );
  const handleOnDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      // Handle drops into compliance section
      if (
        result.destination.droppableId === "new-compliance-question" ||
        result.destination.droppableId.startsWith("compliance-")
      ) {
        const questionId = result.draggableId;
        const cellId = result.destination.droppableId;

        // Check if question is already mapped somewhere
        const isQuestionMapped = Object.values(
          report.metadata?.cellMappings || {},
        ).includes(questionId);
        if (isQuestionMapped) {
          return; // Don't allow dropping if already mapped
        }

        // If it's a new compliance question
        if (cellId === "new-compliance-question") {
          const newQuestion = { id: uuidv4() };
          const updatedReport = {
            ...report,
            metadata: {
              ...report.metadata,
              complianceQuestions: [
                ...(report.metadata?.complianceQuestions || []),
                newQuestion,
              ],
              cellMappings: {
                ...report.metadata?.cellMappings,
                [`compliance-${newQuestion.id}`]: questionId,
              },
            },
            updatedAt: new Date().toISOString(),
          };

          setReport(updatedReport);
          debouncedUpdateReport(updatedReport);
          return;
        }

        // If it's an existing compliance question
        const updatedReport = {
          ...report,
          metadata: {
            ...report.metadata,
            cellMappings: {
              ...report.metadata?.cellMappings,
              [cellId]: questionId,
            },
          },
          updatedAt: new Date().toISOString(),
        };

        setReport(updatedReport);
        debouncedUpdateReport(updatedReport);
        return;
      }

      // Handle regular question reordering
      const items = Array.from(report.questions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const updatedQuestions = items.map((question, index) => ({
        ...question,
        position: index,
      }));

      const updatedReport = {
        ...report,
        questions: updatedQuestions,
        updatedAt: new Date().toISOString(),
      };

      setReport(updatedReport);
      debouncedUpdateReport(updatedReport);
    },
    [report, setReport, debouncedUpdateReport],
  );

  const handleTitleChange = useCallback(
    (newTitle) => {
      if (!report) return;
      const updatedReport = {
        ...report,
        title: newTitle,
        updatedAt: new Date().toISOString(),
      };
      setReport(updatedReport);
      debouncedUpdateReport(updatedReport);
    },
    [report, debouncedUpdateReport],
  );
  const handleConfigureTemplate = useCallback(() => {
    setIsTemplateModalOpen(true);
  }, []);

  const handleTemplateSelect = useCallback(
    (templateId) => {
      setSelectedTemplateId(templateId);
      setIsTemplateModalOpen(false);

      if (!report) return;
      const updatedReport = {
        ...report,
        template_id: templateId,
        updatedAt: new Date().toISOString(),
      };
      setReport(updatedReport);
      debouncedUpdateReport(updatedReport);

      onShowToast(
        "success",
        "Template Updated",
        "Report template has been updated",
      );
    },
    [onShowToast, report, debouncedUpdateReport],
  );
  const handleMarkAsCompleted = useCallback(() => {
    if (!report) return;

    const previousReport = report;
    const updatedReport = {
      ...report,
      completed: true,
      updatedAt: new Date().toISOString(),
    };
    setReport(updatedReport);
    try {
      debouncedUpdateReport(updatedReport);
      onShowToast(
        "success",
        "Report Completed",
        "Report has been marked as completed",
      );
    } catch (error) {
      setReport(previousReport);
      onShowToast(
        "error",
        "Update Failed",
        "Failed to mark report as completed",
      );
    }
  }, [report, debouncedUpdateReport, onShowToast]);

  const handleExport = useCallback(async () => {
    try {
      await exportReportToPDF({
        reportId,
        title: report?.title,
        onProgress: (stage, message) => {
          switch (stage) {
            case "start":
            case "processing":
              onShowToast("info", "Export", message);
              break;
            case "complete":
              onShowToast("success", "Export Complete", message);
              break;
            case "error":
              onShowToast("error", "Export Failed", message);
              break;
          }
        },
      });
    } catch (error) {
      console.error("Export failed:", error);
      onShowToast(
        "error",
        "Export Failed",
        "Failed to generate PDF. Please try again.",
      );
    }
  }, [reportId, report?.title, onShowToast]);
  const handleAddQuestion = useCallback(() => {
    setReport((prevReport) => {
      if (!prevReport) return prevReport;
      const tempId = uuidv4();

      const newQuestion = {
        question_id: tempId,
        question: "",
        answer: "",
        comment: "",
        status: "draft",
        visualization: null,
        position: prevReport.questions.length,
      };
      const updatedQuestions = [...prevReport.questions, newQuestion];

      return {
        ...prevReport,
        questions: updatedQuestions,
        updatedAt: new Date().toISOString(),
      };
    });
  }, []);

  const unansweredCount = useMemo(() => {
    if (!report || !report.questions) {
      return 0;
    }
    return report?.questions?.filter((question) => question.answer === "")
      .length;
  }, [report]);

  const questionMapping = useMemo(() => {
    if (!report || !report.questions) {
      return {};
    }
    const mapping = {};
    report?.questions?.forEach((question) => {
      mapping[question.question_id] = question.question;
    });
    return mapping;
  }, [report]);

  useEffect(() => {
    if (report && unansweredCount === 0 && !report?.is_submitted) {
      handleAddQuestion();
    }
  }, [report, unansweredCount, handleAddQuestion]);

  const handleDeleteQuestion = useCallback(
    async (questionId) => {
      if (!report) return;

      const updatedQuestions = report.questions.filter(
        (q) => q.question_id !== questionId,
      );
      const updatedReport = {
        ...report,
        questions: updatedQuestions,
        updatedAt: new Date().toISOString(),
      };
      setReport(updatedReport);

      try {
        if (questionId) {
          await ReportService.deleteQuestion(report.report_id, questionId);
        }
      } catch (error) {
        console.error("Failed to delete question:", error);
        setReport(report);
        onShowToast(
          "error",
          "Delete Failed",
          "Failed to delete question. Please try again.",
        );
      }
    },
    [report, onShowToast],
  );
  const { startReportWithQuestionnaire } = useReportStore();
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file || !report) return;

      setIsUploading(true);
      try {
        // Start questionnaire process
        const fileDetails = await startReportWithQuestionnaire(
          report.report_id,
          file,
        );

        if (fileDetails) {
          onShowToast(
            "success",
            "File Uploaded",
            "Your file has been uploaded successfully. Processing questions...",
          );

          // Refresh report data to get new questions
          const reportData = await ReportService.getReportById(
            report.report_id,
          );
          setReport(reportData);
        }
      } catch (error) {
        console.error("Error processing file:", error);
        onShowToast(
          "error",
          "Upload Failed",
          "There was an error processing your file. Please try again.",
        );
      } finally {
        setIsUploading(false);
      }
    },
    [report, onShowToast],
  );

  // Mock fetch function
  const fetchGeneratedQuestions = async (fileURL) => {
    // Simulate a delay for fetching questions
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          "What are the key findings from the document?",
          "Can you elaborate on the main challenges discussed?",
          "What recommendations are provided in the document?",
        ]);
      }, 1000);
    });
  };

  // Initialize react-dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [] },
    multiple: false,
    disabled: isUploading,
  });

  const SelectedTemplate =
    templateComponents[report?.template_id] || templateComponents.default;

  // Check if current user is an approver
  const isCurrentUserApprover = useMemo(() => {
    if (!report || !report.approvers || !user) return false;
    return report.approvers.some((approver) => approver.user_id == user.id);
  }, [report?.approvers, user.id]);
  // Get current user's approver status
  const currentUserApproverStatus = useMemo(() => {
    if (!isCurrentUserApprover || !report?.approvers) return null;
    const userApprover = report.approvers.find(
      (approver) => approver.user_id == user.id,
    );
    return userApprover ? userApprover.status : null;
  }, [isCurrentUserApprover, report?.approvers, user]);

  // Handle report approval
  const handleApproveClick = () => {
    setCommentAction("approve");
    setIsCommentModalOpen(true);
  };

  // Handle report rejection click
  const handleRejectClick = () => {
    setCommentAction("reject");
    setIsCommentModalOpen(true);
  };

  // Handle comment submission
  const handleCommentSubmit = async (comment) => {
    try {
      setProcessingApproval(true);
      await updateReportApprover(
        reportId,
        user.id,
        commentAction === "approve" ? "approved" : "rejected",
        comment,
      );
      setIsCommentModalOpen(false);
      onShowToast(
        "success",
        commentAction === "approve" ? "Report Approved" : "Report Rejected",
        commentAction === "approve"
          ? "You have successfully approved this report"
          : "You have rejected this report",
      );
      await fetchAndsetLatestReport();
    } catch (error) {
      console.error(`Error ${commentAction}ing report:`, error);
      onShowToast(
        "error",
        `${commentAction === "approve" ? "Approval" : "Rejection"} Failed`,
        `Failed to ${commentAction} the report. Please try again.`,
      );
    } finally {
      setProcessingApproval(false);
    }
  };

  if (!reportId) {
    return (
      <div className="col-span-3 p-4 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">
          Select a report or create a new one to get started.
        </p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="col-span-3 w-full p-6 bg-white overflow-y-scroll h-full mx-auto">
        <div className="mb-8 animate-pulse">
          <div className="h-12 w-3/4 bg-gray-200 rounded mb-2"></div>
          <div className="flex items-center text-sm text-gray-500">
            <div className="h-4 w-32 bg-gray-200 rounded mr-2"></div>
            <span>•</span>
            <div className="h-4 w-40 bg-gray-200 rounded ml-2"></div>
          </div>
        </div>
        {[1, 2, 3].map((i) => (
          <div key={i} className="mb-6 animate-pulse">
            <div className="h-24 bg-gray-200 rounded mb-4"></div>
            <div className="h-16 bg-gray-100 rounded"></div>
          </div>
        ))}
        <div className="fixed bottom-6 right-6 flex space-x-2">
          <div className="h-10 w-32 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-10 w-24 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    );
  }

  if (!report) {
    return (
      <div className="col-span-3 p-4 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">Report not found or failed to load.</p>
      </div>
    );
  }

  return (
    <>
      <div className="col-span-3 w-full p-6 bg-white overflow-y-scroll h-full mx-auto">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="mb-8">
            <SelectedTemplate
              report={report}
              showLogoUpload={showLogoUpload}
              setShowLogoUpload={setShowLogoUpload}
              isUploadingLogo={isUploadingLogo}
              handleLogoUpload={handleLogoUpload}
              handleTitleChange={handleTitleChange}
              updateReport={debouncedUpdateReport}
            />
            {/* created, last updated, approvers */}
            <div className="p-5 bg-gray-50 font-medium rounded-md mt-4">
              <div className="flex flex-col text-sm text-gray-600 space-y-4">
                <div>
                  <span className="mr-2">Created: </span>
                  {formatDate(report.created_at)}
                </div>
                <div>
                  <span className="mr-2">Last Updated: </span>
                  {formatLastUpdated(report.updated_at)}
                </div>
                <div className="w-full">
                  <div className="flex items-center gap-2">
                    <span className="mr-2">Approvers: </span>
                    <MultiApproverSelector
                      onShowToast={onShowToast}
                      approvers={report?.approvers || []}
                      fetchAndsetLatestReport={fetchAndsetLatestReport}
                    />
                    {report?.approvers && report.approvers.length > 0 && (
                      <ApproversHoverView
                        approvers={report.approvers}
                        isLoading={isLoadingApprovers}
                      />
                    )}
                    {isCurrentUserApprover &&
                      currentUserApproverStatus === "pending" && (
                        <div className="flex space-x-2">
                          <button
                            onClick={handleApproveClick}
                            disabled={isUpdatingApprover || processingApproval}
                            className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            <FaCheck className="mr-2" />
                            {isUpdatingApprover ? "Processing..." : "Approve"}
                          </button>
                          <button
                            onClick={handleRejectClick}
                            disabled={isUpdatingApprover || processingApproval}
                            className="flex items-center px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            <FaTimes className="mr-2" />
                            {isUpdatingApprover ? "Processing..." : "Reject"}
                          </button>
                        </div>
                      )}
                    {isCurrentUserApprover &&
                      currentUserApproverStatus === "approved" && (
                        <div className="px-4 py-2 bg-green-100 text-green-800 rounded flex items-center">
                          <FaCheck className="mr-2" />
                          You have approved this report
                        </div>
                      )}
                    {isCurrentUserApprover &&
                      currentUserApproverStatus === "rejected" && (
                        <div className="px-4 py-2 bg-red-100 text-red-800 rounded flex items-center">
                          <FaTimes className="mr-2" />
                          You have rejected this report
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* summary */}
          {!!summary && (
            <div className="mb-8 p-5 bg-gray-50 font-medium rounded-md">
              <h3 className="text-lg font-semibold text-gray-700 m-0 mb-2">
                Summary
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: summary.replace(/\n/g, "<br />"),
                }}
              ></p>
            </div>
          )}
          {/* toc */}
          {!!tableOfContent && (
            <div className="mb-8 p-5 bg-gray-50 font-medium rounded-md">
              <h3 className="text-lg font-semibold text-gray-700 m-0 mb-2">
                Table of Contents
              </h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Question
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Object.entries(tableOfContent).map(([category, ids]) => {
                      if (category === "report_id") return null;

                      return ids.map((id, index) => (
                        <tr key={category + index}>
                          {index === 0 && (
                            <td
                              rowSpan={ids.length}
                              className="px-6 py-4 text-sm font-medium text-gray-900 text-center"
                            >
                              {category}
                            </td>
                          )}
                          <td className="px-6 py-4 text-sm text-gray-500">
                            {questionMapping[id] || ""}
                          </td>
                        </tr>
                      ));
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <Droppable droppableId="questions">
            {(provided) => (
              <div
                className="questions-list mb-20"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {report.questions
                  .sort((a, b) => a.position - b.position)
                  .map((question, index) => (
                    <Draggable
                      key={question.question_id}
                      draggableId={question.question_id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <QuestionItem
                            question={question}
                            report={report}
                            setReport={setReport}
                            onDeleteQuestion={() =>
                              handleDeleteQuestion(question.question_id)
                            }
                            dragHandleProps={provided.dragHandleProps}
                            disableDeleteButton={unansweredCount === 1}
                            onShowToast={onShowToast}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {false &&
          report.questions.length === 0 /** removing for 18th demo */ && (
            <>
              <p className="text-lg font-medium text-gray-700 mb-4">
                Get started by uploading questionare
              </p>
              <div
                className={`mb-4 p-4 border-2 border-dashed rounded-md transition-colors duration-200 ${
                  isDragActive
                    ? "border-blue-500 bg-blue-50"
                    : "border-gray-300 hover:border-blue-500"
                } ${
                  isUploading
                    ? "opacity-50 cursor-not-allowed"
                    : "cursor-pointer"
                }`}
              >
                <div {...getRootProps()} className="text-center py-10">
                  <input {...getInputProps()} />
                  {isUploading ? (
                    <div className="space-y-3">
                      <div className="h-12 w-12 mx-auto bg-gray-200 rounded-full animate-pulse"></div>
                      <div className="h-4 w-3/4 mx-auto bg-gray-200 rounded animate-pulse"></div>
                      <div className="h-4 w-1/2 mx-auto bg-gray-200 rounded animate-pulse"></div>
                    </div>
                  ) : (
                    <>
                      <AiOutlineFilePdf className="mx-auto h-12 w-12 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" />
                      <p className="mt-2 text-gray-600 text-sm font-medium group-hover:text-blue-600 transition-colors duration-200">
                        {isDragActive
                          ? "Drop the PDF file here"
                          : "Drag and drop a PDF file here, or click to select a file"}
                      </p>
                      <p className="mt-1 text-gray-500 text-xs group-hover:text-blue-500 transition-colors duration-200">
                        Supported file type: PDF
                      </p>
                      <button className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <AiOutlineUpload className="mr-2" />
                        Select PDF
                      </button>
                    </>
                  )}
                </div>
              </div>
              <p className="text-lg font-medium text-gray-700 mb-4">
                Or, start from scratch by adding a question
              </p>
            </>
          )}
        {!report?.is_submitted && <div>
          <button
            className="flex w-full text-left p-2 bg-transparent hover:bg-gray-100 rounded transition-colors duration-200 text-gray-600 hover:text-gray-800"
            onClick={handleAddQuestion}
          >
            + Add a question
          </button>
        </div>}

        <div className="fixed bottom-6 right-20 flex space-x-2">
          {report.completed ? (
            <button
              className={`
                px-4 py-2 text-white rounded bg-green-500 hover:bg-green-600 transition-all
                ${isReportSubmitting ? "cursor-not-allowed" : ""}
                ${report?.is_submitted ? "bg-yellow-500 hover:bg-yellow-600" : ""}
                `}
              disabled={isReportSubmitting}
              onClick={submitWithdrawReport}
            >
              {!report?.is_submitted ? "Submit" : "Withdraw"}
            </button>
          ) : (
            <button
              onClick={handleMarkAsCompleted}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={report.completed || isUploading}
            >
              {report.completed ? "Completed" : "Mark as completed"}
            </button>
          )}
          <button
            onClick={handleExport}
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isUploading}
          >
            Export
          </button>
          <Menu as="div" className="relative inline-block text-left">
            <MenuButton className="text-gray-500 bg-gray-200 flex hover:bg-gray-200 rounded p-2">
              <FaEllipsisH className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <MenuItems
              anchor="bottom end"
              className="absolute border border-solid border-gray-200 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-lg overflow-hidden bg-transparent backdrop-blur-lg shadow-lg focus:outline-none"
            >
              <MenuItem>
                {({ focus }) => (
                  <button
                    onClick={handleConfigureTemplate}
                    disabled={isUploading}
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-transparent text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                  >
                    <TbTemplate className="mr-2" />
                    Configure Template
                  </button>
                )}
              </MenuItem>
              <MenuSeparator className="h-px bg-gray-200" />
              <MenuItem>
                {({ focus }) => (
                  <button
                    onClick={handleSummary}
                    disabled={isUploading}
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-transparent text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                  >
                    <FaList className="mr-2" />
                    View Summary
                  </button>
                )}
              </MenuItem>
              <MenuSeparator className="h-px bg-gray-200" />
              <MenuItem>
                {({ focus }) => (
                  <button
                    onClick={handleTableOfContents}
                    disabled={isUploading}
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-transparent text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                  >
                    <FaTable className="mr-2" />
                    View Table Of Contents
                  </button>
                )}
              </MenuItem>
              <MenuSeparator className="h-px bg-gray-200" />
              <MenuItem>
                {({ focus }) => (
                  <button
                    onClick={handlePreview}
                    disabled={isUploading}
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-transparent text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                  >
                    <FaEye className="mr-2" />
                    Preview
                  </button>
                )}
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </div>
      {isTemplateModalOpen && (
        <ChooseTemplateModal
          isOpen={isTemplateModalOpen}
          onClose={() => setIsTemplateModalOpen(false)}
          selectedTemplateId={selectedTemplateId}
          onTemplateSelect={handleTemplateSelect}
        />
      )}

      <ApprovalRejectionModal
        isOpen={isCommentModalOpen}
        onClose={() => setIsCommentModalOpen(false)}
        onSubmit={handleCommentSubmit}
        title={`${commentAction === "approve" ? "Approve" : "Reject"} Report`}
        action={commentAction}
      />
    </>
  );
};

export default memo(
  ReportDetails,
  (prevProps, nextProps) => prevProps.reportId === nextProps.reportId,
); // for now its ok as we are not using passed reports anymore
