import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const RcaSelector = ({ isCollapsed }) => {
  const location = useLocation();

  const rcaOptions = [
    { id: "rca", name: "RCA", icon: "pi pi-chart-bar", path: "/portal/rca" },
    {
      id: "rca-adv",
      name: "Advanced",
      icon: "pi pi-wrench",
      path: "/portal/rca-advanced",
    },
    {
      id: "dev",
      name: "Developer",
      icon: "pi pi-code",
      path: "/portal/rca-developer",
    },
    {
      id: "knowledge",
      name: "Knowledge",
      icon: "pi pi-book",
      path: "/portal/knowledge",
    },
  ];

  return (
    <div className="space-y-4">
      {/* Heading and Divider */}
      {!isCollapsed && (
        <>
          <h3 className="pl-2 text-xs font-semibold text-gray-500 uppercase tracking-wide">
            RCA Tools
          </h3>
        </>
      )}

      {/* Always-visible Options */}
      <div className="space-y-0.5">
        {rcaOptions.map((option) => {
          const isActive = location.pathname === option.path;
          return (
            <Link
              key={option.id}
              to={option.path}
              className="block focus:outline-none"
            >
              <button
                className={`flex w-full items-center px-4 py-2 text-sm text-gray-800 hover:bg-[#ebebeb] rounded-md transition-colors duration-200
                ${isActive ? "bg-[#e8e8e8] rounded-md" : "bg-transparent"}
                ${isCollapsed ? "justify-center" : "pl-4 pr-3"}`}
              >
                <i className={`${option.icon} ${!isCollapsed && "mr-3"}`} />
                {!isCollapsed && (
                  <span className="flex-1 text-left truncate">
                    {option.name}
                  </span>
                )}
              </button>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
