import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { classNames } from "primereact/utils";
import TabLayout from "../layout/dataModelTabLayout";
import S3Service from "../../service/S3service";
import staticData from "./data/staticData.json";
import CsvDataTable from "../table/csvTable/csvDataTable";
import { useNavigate } from "react-router-dom";
import ModelService from "../../service/ModelService";
import ActivityService from "../../service/ActivityService";
import { AutoComplete } from "primereact/autocomplete";
import CsvUtils from "../util/CsvUtil";
import "./model.css";

const Model = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [files, setFiles] = useState([]);
  const [dropdownDataProducts, setDropdownDataProducts] = useState([]);
  const [dropdownDataResources, setDropdownDataResources] = useState([]);
  const [dropdownDataMultiSelect, setDropdownDataMultiSelect] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fileData, setFileData] = useState([]);
  const [modelName, setModelName] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelNames, setModelNames] = useState(new Set());
  const [filteredModelNames, setFilteredModelNames] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFiles();
    fetchModelNames();
  }, []);

  const fetchFiles = async () => {
    try {
      const filesData = await S3Service.getAllObjects();

      // Filter to include only CSV files and remove folder paths
      const csvFiles = filesData
        .filter((file) => file.key.endsWith(".csv")) // Filter to keep only .csv files
        .map((file) => {
          const fileName = file.key.split("/").pop(); // Extract the file name from the path
          return { label: fileName, value: file.key }; // Use fileName for label and full key for value
        });

      setFiles(csvFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const logActivity = async (action, name) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const activityLog = {
        email_id: user?.email,
        action: `${action} - ${name}`,
      };
      await ActivityService.addActivityLog(activityLog, user.id);
    } catch (error) {
      console.error("Failed to log activity:", error);
    }
  };

  const fetchModelNames = async () => {
    try {
      const response = await ModelService.getAllModelName();
      const names = response.data.map((name) => name.toLowerCase()); // Convert names to lowercase
      setModelNames(new Set(names)); // Store names in lowercase
    } catch (error) {
      console.error("Error fetching model names:", error);
    }
  };

  const handleSubmit = async (data) => {
    try {
      // URLs of the static CSV files
      const fileUrls = [
        "/csv-data/job-specific/prediction/Time_True_Pred.csv",
        "/csv-data/job-specific/health.csv",
        "/csv-data/job-specific/utilization.csv",
      ];

      // Fetch and convert each file to a Blob and then to a File with a dynamic file name
      const files = await Promise.all(
        fileUrls.map(async (fileUrl) => {
          const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
          const response = await fetch(fileUrl);
          const csvContent = await response.text();
          const csvBlob = new Blob([csvContent], { type: "text/csv" });
          return new File([csvBlob], fileName, { type: "text/csv" });
        })
      );

      // Upload CSVs and get the folder GUID
      let s3FolderGuid = "";
      if (selectedFileName) {
        const uploadResult = await S3Service.uploadCsvAndCreateFolder(files);
        s3FolderGuid = uploadResult?.data?.folder_name;
      }

      // Prepare the model payload with s3FolderGuid
      const modelPayload = {
        ...data,
        s3folder_guid: s3FolderGuid,
      };

      // Fetch and process data
      const newDataArray = await fetchAndProcessData(modelPayload);
      console.log("Data to be added:", newDataArray);

      // Create an array of promises for adding JSON data and calling the dynamic API
      const apiPromises = newDataArray.map((newData) => {
        // Call addJsonData API
        const addJsonDataPromise = ModelService.addJsonData(newData);

        const dynamicApiPayload = {
          jobId: modelPayload.s3folder_guid,
          inputFile: newData.file,
          predictor: newData.predictors,
          products: newData.product,
          response: newData.response,
          modelname: newData.model,
          triggeredFrom: "train",
        };

        // Call dynamicAPI
        const dynamicApiPromise = ModelService.dynamicAPI(dynamicApiPayload);

        // Return both promises in an array
        return Promise.all([addJsonDataPromise, dynamicApiPromise]);
      });

      // Wait for all promises (both APIs) to resolve
      await Promise.all(apiPromises);
      console.log("All data and dynamic API calls completed successfully");

      // Navigate to the result page
      navigate("/portal/result");
      console.log("Navigation to /portal/result");

      // Log activity after navigation
      await logActivity("Created Model", data.modelName);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      modelName: "",
      selectedFiles: "",
      selectedProducts: [],
      selectedPredictor: [],
      selectedResponse: [],
    },
    validate: (data) => {
      let errors = {};
      if (!data.modelName) {
        errors.modelName = "Model Name is required.";
      }
      if (!data.selectedFiles) {
        errors.selectedFiles = "Select Files is required.";
      }
      if (!data.selectedProducts.length) {
        errors.selectedProducts = "Product is required.";
      }
      if (!data.selectedResponse.length) {
        errors.selectedResponse = "Response is required.";
      }
      return errors;
    },
    onSubmit: handleSubmit,
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const handleFileSelection = async (selectedFile) => {
    try {
      setLoading(true);

      // Download the file as a Blob
      const blob = await S3Service.downloadFile(selectedFile);

      // Convert the Blob to a string
      const csvString = await blob.text();

      // Parse the CSV string
      const { headers, data } = CsvUtils.parseCsv(csvString);

      // Update the state with the parsed data
      setHeaders(headers);
      setFileData(data);
      setSelectedFileName(selectedFile);
      formik.setFieldValue("selectedPredictor", headers);
      formik.setFieldValue("selectedResponse", headers);

      // Convert headers to lowercase for case-insensitive comparison
      const lowerCaseHeaders = headers.map((header) => header.toLowerCase());
      const productColumnName = "product";

      if (lowerCaseHeaders.includes(productColumnName)) {
        // Find the actual case-sensitive column name
        const actualProductColumnName =
          headers[lowerCaseHeaders.indexOf(productColumnName)];

        // Extract values from the "Product" column
        const products = data.map((row) => row[actualProductColumnName]);

        // Extract unique values from the "Product" column
        const uniqueProducts = [
          ...new Set(
            products.filter((value) => value !== undefined && value !== "")
          ),
        ];

        const productOptions = uniqueProducts.map((product) => ({
          label: product.toString(),
          value: product.toString(),
        }));
        // Set all values as selected in the formik field
        formik.setFieldValue(
          "selectedProducts",
          productOptions.map((option) => option.value)
        );
        setDropdownDataProducts(productOptions);
      } else {
        // Handle the case where the product column is not found
        setDropdownDataProducts([{ label: "-", value: "-" }]);
        formik.setFieldValue("selectedProducts", ["-"]); // Reset to an empty array instead of '-'
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAndProcessData = async (formData) => {
    console.log("formdata", formData);

    // Function to generate a random value within a specified range
    const generateRandomFloatInRange = (min, max, decimals = 2) => {
      const randomValue = Math.random() * (max - min) + min;
      return parseFloat(randomValue.toFixed(decimals));
    };

    // Simulated data fetching and processing
    const mockDataArray = formData.selectedResponse.map(
      (multiSelectValue, index) => {
        const uniqueIdentifier = Date.now() + index;

        return {
          file: formData.selectedFiles,
          options: "Action",
          product: formData.selectedProducts,
          response_ops: `Oper ${uniqueIdentifier}`,
          model: formData.modelName,
          status: "Active",
          response: multiSelectValue,
          mae: generateRandomFloatInRange(0.01, 0.1),
          rmse: generateRandomFloatInRange(0.01, 0.1),
          r_square: generateRandomFloatInRange(0.85, 0.95),
          mae_control_range: generateRandomFloatInRange(0.01, 0.1),
          count: uniqueIdentifier,
          s3folder_guid: formData.s3folder_guid,
          predictors: formData.selectedPredictor,
        };
      }
    );

    return mockDataArray;
  };

  const checkModelNameUnique = async (name) => {
    const existingNames = Array.from(modelNames);
    return !existingNames.includes(name.toLowerCase());
  };

  const handleModelNameBlur = async () => {
    if (formik.values.modelName) {
      const isUnique = await checkModelNameUnique(formik.values.modelName);
      if (!isUnique) {
        formik.setFieldError("modelName", "Model Name already exists.");
      } else {
        formik.setFieldError("modelName", "");
      }
      formik.setFieldTouched("modelName", true); // Mark the field as touched
      await formik.validateForm(); // Validate the form after setting error

      // Log errors and touched fields
      console.log("Formik Errors:", formik.errors);
      console.log("Formik Touched:", formik.touched);
    }
  };

  return (
    <TabLayout activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
      <div className="model-container">
        <div className="upper-half">
          <Card className="model-card">
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="grid-container">
                <div className="formgrid grid">
                  <div className="field col">
                    <div className="dropdown-section">
                      <label htmlFor="selectedFiles">
                        Select Files <span style={{ color: "red" }}>*</span>
                      </label>
                      {getFormErrorMessage("selectedFiles")}
                      <Dropdown
                        id="selectedFiles"
                        name="selectedFiles"
                        value={formik.values.selectedFiles}
                        options={files}
                        onChange={(e) => {
                          formik.setFieldValue("selectedFiles", e.value);
                          handleFileSelection(e.value);
                        }}
                        placeholder="Select Files"
                        optionLabel="label"
                        optionValue="value"
                        className={`p-dropdown1 ${classNames({
                          "p-invalid": isFormFieldValid("selectedFiles"),
                        })}`}
                      />
                    </div>
                  </div>
                  <div className="field col">
                    <div className="dropdown-section">
                      <label htmlFor="selectedProducts">
                        Products <span style={{ color: "red" }}>*</span>
                      </label>
                      {getFormErrorMessage("selectedProducts")}
                      <MultiSelect
                        id="selectedProducts"
                        name="selectedProducts"
                        value={formik.values.selectedProducts}
                        options={dropdownDataProducts}
                        onChange={(e) =>
                          formik.setFieldValue("selectedProducts", e.value)
                        }
                        placeholder="Select product"
                        display="chip"
                        className={`p-multiselect1 ${classNames({
                          "p-invalid": isFormFieldValid("selectedProducts"),
                        })}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="formgrid grid">
                  <div className="field col">
                    <div className="dropdown-section">
                      <label htmlFor="selectedPredictor">
                        Predictor <span>(optional)</span>
                      </label>
                      {getFormErrorMessage("selectedPredictor")}
                      <MultiSelect
                        id="selectedPredictor"
                        name="selectedPredictor"
                        value={formik.values.selectedPredictor}
                        options={headers.map((header) => ({
                          label: header,
                          value: header,
                        }))}
                        onChange={(e) => {
                          formik.setFieldValue("selectedPredictor", e.value);
                        }}
                        placeholder="Select predictor"
                        display="chip"
                        className={`p-multiselect1 ${classNames({
                          "p-invalid": isFormFieldValid("selectedPredictor"),
                        })}`}
                      />
                    </div>
                  </div>
                  <div className="field col">
                    <div className="dropdown-section">
                      <label htmlFor="selectedResponse">
                        Response <span style={{ color: "red" }}>*</span>
                      </label>
                      {getFormErrorMessage("selectedResponse")}
                      <MultiSelect
                        id="selectedResponse"
                        name="selectedResponse"
                        value={formik.values.selectedResponse}
                        options={headers.map((header) => ({
                          label: header,
                          value: header,
                        }))}
                        onChange={(e) => {
                          formik.setFieldValue("selectedResponse", e.value);
                        }}
                        placeholder="Select Response"
                        display="chip"
                        className={`p-multiselect1 ${classNames({
                          "p-invalid": isFormFieldValid("selectedResponse"),
                        })}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="field col">
                  <div className="dropdown-section">
                    <label htmlFor="modelName">
                      Model Name <span style={{ color: "red" }}>*</span>
                    </label>
                    {getFormErrorMessage("modelName")}
                    <InputText
                      id="modelName"
                      name="modelName"
                      value={formik.values.modelName}
                      onChange={formik.handleChange}
                      onBlur={handleModelNameBlur}
                      className={classNames("p-inputtext1", {
                        "p-invalid": isFormFieldValid("modelName"),
                      })}
                      placeholder="Enter Model Name"
                    />
                  </div>
                </div>
              </div>
              <div className="button-section">
                <Button
                  type="button"
                  label="Cancel"
                  className="p-button-secondary button-class"
                  onClick={formik.handleReset}
                />
                <Button
                  type="submit"
                  label="Create Model"
                  className="p-button-primary button-class"
                  disabled={formik.isSubmitting}
                />
              </div>
            </form>
          </Card>
        </div>

        {selectedFileName && fileData.length > 0 && (
          <div className="lower-half">
            <CsvDataTable headers={headers} data={fileData} />
          </div>
        )}

        {loading && (
          <div className="lower-half">
            <p>Loading...</p>
          </div>
        )}
      </div>
    </TabLayout>
  );
};

export default Model;
