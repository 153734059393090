import React, { useEffect, useMemo, useState } from 'react';
import { formatDate } from '../../util/dateUtils';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { Droppable } from 'react-beautiful-dnd';
import { debounce } from 'lodash';
import ReportService from '../../../service/ReportService';
import { Editor } from '@tinymce/tinymce-react';
import { AiOutlineEdit } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';
const TemplateThree = ({ report, updateReport, showLogoUpload, setShowLogoUpload, isUploadingLogo, handleLogoUpload }) => {
  // Initialize metadata with defaults from the image
  const metadata = {
    prepared_by: 'Your Name/Team Name, ThirdAI Automation Inc.',
    overview: {
      purpose: 'Summarize the purpose, including regulatory context and relevance for SCE.'
    },
    project_scope: {
      scope: 'Define the scope in relation to SCE\'s distribution manual requirements.',
      objectives: 'List specific objectives aligned with regulatory requirements and SCE\'s distribution standards.'
    },
    qa_compliance: {},
    analysis: {
      findings: 'Summarize findings from Q&A, noting trends or critical insights.',
      recommendations: 'List suggested actions or adjustments for further regulatory compliance.'
    },
    complianceQuestions: report.metadata?.complianceQuestions || [],
    appendices: {
      documentation: 'Attach regulatory documents, compliance checklists, and resources.',
      contact: 'Primary Contact: Name, Position, Contact Information'
    },
    ...report.metadata
  };

  const handleMetadataChange = (section, subsection, value) => {
    const updatedReport = {
        ...report,
        metadata: {
          ...metadata,
          [section]: subsection 
            ? {
                ...metadata?.[section],
                [subsection]: value
              }
            : value
        }
      };
    updateReport(updatedReport);
  };



  return (
    <div className="max-w-4xl mx-auto p-8">
      {/* Logo Section */}
      <div className="mb-8 flex justify-start">
        <div
          className="relative group"
          onMouseEnter={() => setShowLogoUpload(true)}
          onMouseLeave={() => setShowLogoUpload(false)}
        >
          {report.logo ? (
            <img
              src={report.logo}
              alt="Report Logo"
              className="h-16 object-contain cursor-pointer hover:opacity-75 transition-opacity"
              onClick={() => document.getElementById('logo-upload').click()}
            />
          ) : (
            <div
              className="h-16 w-16 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors"
              onClick={() => document.getElementById('logo-upload').click()}
            >
              <AiOutlinePlus className="text-gray-400 text-xl" />
            </div>
          )}
          <input
            type="file"
            id="logo-upload"
            className="hidden"
            accept="image/*"
            onChange={(e) => handleLogoUpload(e.target.files[0])}
            disabled={isUploadingLogo}
          />
          {showLogoUpload && (
            <div className="absolute left-0 -bottom-6 text-xs text-gray-500 whitespace-nowrap">
              {isUploadingLogo ? 'Uploading...' : 'Click to add logo'}
            </div>
          )}
        </div>
      </div>

      {/* Report Table */}
      <table className="w-full border-collapse">
        <tbody className="[&>tr>td]:border [&>tr>td]:border-solid [&>tr>td]:border-gray-300 [&>tr>td]:p-2">
          <TableRow label="Section" content="Details" isHeader={true} />
          <TableRow 
            label="Report Title" 
            content={report.title || 'Specific Project/Topic Title'}
            onChange={(value) => updateReport({ ...report, title: value })} 
          />
          <TableRow 
            label="Date" 
            content={formatDate(report.created_at, 'DD/MM/YYYY')}
            readOnly={true}
          />
          <TableRow 
            label="Prepared by" 
            content={metadata.prepared_by}
            onChange={(value) => handleMetadataChange('prepared_by', null, value)} 
          />
          <TableRow 
            label="Overview" 
            content={<>
              <strong>Purpose of Report: </strong>
              {metadata.overview.purpose}
            </>}
            onChange={(value) => handleMetadataChange('overview', 'purpose', value)} 
          />
          <TableRow 
            label="Project Scope and Objectives" 
            content={<>
              <div><strong>Scope: </strong>{metadata.project_scope.scope}</div>
              <div className="mt-1"><strong>Objectives: </strong>{metadata.project_scope.objectives}</div>
            </>}
            onChange={(value) => handleMetadataChange('project_scope', 'content', value)} 
          />
          <TableRow 
            label="Q&A Compliance Section"
            readOnly={true} 
            content={
              <div className="space-y-2">
                {metadata.complianceQuestions.map((q) => (
                  <div key={q.id} className="relative border p-2 rounded bg-gray-50">
                    <Droppable droppableId={`compliance-${q.id}`}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`min-h-[60px] rounded ${
                            snapshot.isDraggingOver ? 'bg-blue-50' : ''
                          }`}
                        >
                          {metadata.cellMappings?.[`compliance-${q.id}`] ? (
                            <QuestionDisplay 
                              questionId={metadata.cellMappings[`compliance-${q.id}`]} 
                              report={report}
                              updateReport={updateReport}
                            />
                          ) : (
                            <div className="text-gray-400 italic">
                              Drop a question here
                            </div>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ))}
                {/* Add an empty droppable area for new questions */}
                <Droppable droppableId="new-compliance-question">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`min-h-[60px] border-2 border-dashed rounded ${
                        snapshot.isDraggingOver ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                      }`}
                    >
                      <div className="text-gray-400 italic text-center py-3">
                        Drop a question here to add to compliance section
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            }
          />
          <TableRow 
            label="Analysis and Recommendations" 
            content={<>
              <div><strong>Findings: </strong>{metadata.analysis.findings}</div>
              <div className="mt-1"><strong>Recommendations for Compliance: </strong>{metadata.analysis.recommendations}</div>
            </>}
            onChange={(value) => handleMetadataChange('analysis', 'content', value)} 
          />
          <TableRow 
            label="Appendices" 
            content={<>
              <div><strong>Supporting Documentation: </strong>{metadata.appendices.documentation}</div>
              <div className="mt-1"><strong>Contact Information: </strong>{metadata.appendices.contact}</div>
            </>}
            onChange={(value) => handleMetadataChange('appendices', 'content', value)} 
          />
        </tbody>
      </table>
    </div>
  );
};
function QuestionDisplay({ questionId, report, updateReport }) {
  const question = report.questions.find(q => q.question_id === questionId);
  const [editedQuestion, setEditedQuestion] = useState('');
  const [editedAnswer, setEditedAnswer] = useState('');
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (question) {
      setEditedQuestion(question.question);
      setEditedAnswer(question.answer?.text || '');
    }
  }, [question]);

  const debouncedUpdate = useMemo(
    () =>
      debounce((updatedQuestion, updatedAnswer) => {
        const updatedQuestions = report.questions.map(q =>
          q.question_id === questionId
            ? {
                ...q,
                question: updatedQuestion,
                answer: {
                  ...q.answer,
                  text: updatedAnswer
                }
              }
            : q
        );

        // Update local state
        updateReport({
          ...report,
          questions: updatedQuestions,
          updatedAt: new Date().toISOString()
        });

        // Call API to update question
        ReportService.updateQuestion(
          report.report_id,
          questionId,
          updatedQuestions.find(q => q.question_id === questionId)
        ).catch(error => {
          console.error("Error updating question:", error);
        });
      }, 5000),
    [questionId, report, updateReport]
  );

  const handleQuestionChange = (content) => {
    setEditedQuestion(content);
    debouncedUpdate(content, editedAnswer);
  };

  const handleAnswerChange = (content) => {
    setEditedAnswer(content);
    debouncedUpdate(editedQuestion, content);
  };

  const handleDeleteQuestion = () => {
    // Remove from metadata cell mappings if present
    const updatedMetadata = { ...report.metadata };
    // Remove from compliance questions if present
    if (updatedMetadata.complianceQuestions) {
      console.log(`Filtering compliance questions for deleted question ${questionId}`);
      updatedMetadata.complianceQuestions = updatedMetadata.complianceQuestions.filter(q => {
        const cellId = `compliance-${q.id}`;
        const isQuestionMapped = updatedMetadata.cellMappings?.[cellId] === questionId;
        if (isQuestionMapped) {
          console.log(`Removing compliance question ${q.id} because it was mapped to deleted question ${questionId}`);
        } else {
          console.log(`Keeping compliance question ${q.id} because it was not mapped to deleted question ${questionId}`);
        }
        return !isQuestionMapped;
      });
    }
    Object.keys(updatedMetadata.cellMappings || {}).forEach(key => {
      if (updatedMetadata.cellMappings[key] === questionId) {
        delete updatedMetadata.cellMappings[key];
      }
    });


    updateReport({
      ...report,
      metadata: updatedMetadata,
      updatedAt: new Date().toISOString()
    });
  };

  if (!question) return null;

  return (
    <div
      className="bg-white relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/* Question Section */}
      <div className="relative">
        <div
          className="w-full px-2 py-1 font-medium text-gray-900 bg-transparent hover:bg-gray-50 cursor-text"
          dangerouslySetInnerHTML={{ __html: editedQuestion }}
          onClick={() => setShowQuestionModal(true)}
          style={{ minHeight: '32px' }}
        />
        {isHovering && (
          <div className="absolute top-2 right-2 flex space-x-2">
            <button
              onClick={() => setShowQuestionModal(true)}
              className="p-1 bg-white rounded shadow hover:bg-gray-100"
            >
              <AiOutlineEdit className="text-gray-600" />
            </button>
            <button
              onClick={handleDeleteQuestion}
              className="p-1 bg-white rounded shadow hover:bg-gray-100"
            >
              <AiOutlineClose className="text-red-600" />
            </button>
          </div>
        )}
      </div>

      {/* Answer Section */}
      <div className="relative mt-2">
        <div
          className="w-full px-2 py-1 text-gray-600 bg-transparent hover:bg-gray-50 cursor-text"
          dangerouslySetInnerHTML={{ __html: editedAnswer }}
          onClick={() => setShowAnswerModal(true)}
          style={{ minHeight: '48px' }}
        />
        {isHovering && (
          <button
            onClick={() => setShowAnswerModal(true)}
            className="absolute top-2 right-2 p-1 bg-white rounded shadow hover:bg-gray-100"
          >
            <AiOutlineEdit className="text-gray-600" />
          </button>
        )}
      </div>

      {/* Question Edit Modal */}
      {showQuestionModal && (
        <Modal
          isOpen={showQuestionModal}
          onClose={() => setShowQuestionModal(false)}
          title="Edit Question"
        >
          <div className="min-h-[200px]">
            <Editor
              value={editedQuestion}
              apiKey='62has49h0g780q5azmalkqz9vqkdv9a65ukse9nwl5wsqq7b'
              
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                  'insertdatetime', 'code', 'help', 'wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help'
              }}
              onEditorChange={handleQuestionChange}
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowQuestionModal(false)}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Done
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* Answer Edit Modal */}
      {showAnswerModal && (
        <Modal
          isOpen={showAnswerModal}
          onClose={() => setShowAnswerModal(false)}
          title="Edit Answer"
        >
          <div className="min-h-[200px]">
            <Editor
              initialValue={editedAnswer}
              apiKey='62has49h0g780q5azmalkqz9vqkdv9a65ukse9nwl5wsqq7b'
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help'
              }}
              onEditorChange={handleAnswerChange}
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowAnswerModal(false)}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Done
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
const TableRow = ({ label, content, isHeader, onChange, readOnly }) => (
  <tr>
    <td className={`
      border border-solid border-gray-300
      p-2
      ${isHeader ? 'font-bold' : ''}
      w-[200px] align-top
    `}>
      {label}
    </td>
    <td 
      className={`
        border border-solid border-gray-300
        p-2
        ${isHeader ? 'font-bold' : ''}
        ${!readOnly ? 'cursor-pointer hover:bg-gray-50' : ''}
      `}
      contentEditable={!readOnly}
      suppressContentEditableWarning={true}
      onBlur={(e) => {
        if (!readOnly && onChange) {
          onChange(e.target.textContent);
        }
      }}
    >
      {content}
    </td>
  </tr>
);

const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
        {children}
      </div>
    </div>
  );
};


export default React.memo(TemplateThree);