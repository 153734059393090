import React, { useState, useRef, useEffect } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { FaTag, FaChevronDown, FaPlus } from "react-icons/fa";
import useWorkspaceStore from "../../stores/workspaceStore";
import useReportStore from "../../stores/reportStore";
import { useUser } from "../../context/userContext";

const LabelSelector = ({ questionId, reportId, labels: initialLabels }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreatingLabel, setIsCreatingLabel] = useState(false);
  const inputRef = useRef(null);
  const { user } = useUser();

  const {
    labels,
    selectedWorkspace,
    createLabel,
    isLoading: isLabelsLoading,
    error: labelsError,
  } = useWorkspaceStore();

  const {
    addQuestionLabel,
    deleteQuestionLabel,
    isLoading: isActionLoading,
    error: actionError,
  } = useReportStore();

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const filteredLabels = labels.filter((label) =>
    label.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreateLabel = async () => {
    if (!searchTerm || !selectedWorkspace?.workspace_id) return;

    const labelData = {
      name: searchTerm,
      color: "#" + Math.floor(Math.random() * 16777215).toString(16), // Random color
      created_by: user.id,
    };

    try {
      await createLabel(selectedWorkspace.workspace_id, labelData);
      // Clear search after successful creation
      setSearchTerm("");
      setIsCreatingLabel(false);
      // Close popover after creating
      setIsOpen(false);
    } catch (err) {
      // Error is already handled by the store
      console.error("Failed to create label:", err);
    }
  };

  const handleLabelClick = async (label) => {
    // Don't add if it's already added
    if (initialLabels?.some((l) => l.label_id === label.label_id)) return;

    try {
      // Optimistically add the label locally
      const newLabel = {
        ...label,
        question_label_id: `temp_${Date.now()}`, // Temporary ID for optimistic UI
      };

      // Update local state immediately
      initialLabels?.push(newLabel);

      await addQuestionLabel({
        report_id: reportId,
        question_id: questionId,
        label_id: label.label_id,
        created_by: user.id,
      });
    } catch (err) {
      // Revert optimistic update on error
      const index = initialLabels?.findIndex(
        (l) => l.label_id === label.label_id
      );
      if (index !== -1) {
        initialLabels?.splice(index, 1);
      }
      console.error("Failed to add label to question:", err);
    }
  };

  const handleLabelRemove = async (label) => {
    const index = initialLabels?.findIndex(
      (l) => l.label_id === label.label_id
    );
    const removedLabel = initialLabels?.splice(index, 1)[0];
    try {
      // Optimistically remove the label locally

      await deleteQuestionLabel({
        report_id: reportId,
        question_id: questionId,
        label_id: label.label_id,
      });
    } catch (err) {
      // Revert optimistic update on error
      if (removedLabel) {
        initialLabels?.push(removedLabel);
      }
      console.error("Failed to remove label from question:", err);
    }
  };

  if (labelsError || actionError) {
    return <div>Error: {labelsError || actionError}</div>;
  }

  return (
    <div className="flex items-center gap-2">
      {/* Display existing labels */}
      <div className="flex flex-wrap gap-1">
        {initialLabels?.map((label) => (
          <div
            key={label.label_id}
            className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-xs font-medium"
            style={{
              backgroundColor: `${label.color}20`,
              color: label.color,
            }}
          >
            <div
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: label.color }}
            />
            {label.name}
            <button
              onClick={() => handleLabelRemove(label)}
              className="hover:text-gray-600 bg-transparent ml-1"
              disabled={isActionLoading} // Prevent multiple clicks during loading
            >
              ×
            </button>
          </div>
        ))}
      </div>

      {/* Label selector popover */}
      <div className="relative inline-block text-left">
        <Popover>
          {({ open, close }) => (
            <>
              <PopoverButton className="flex items-center text-gray-700 bg-transparent hover:bg-gray-100 rounded p-1 text-sm">
                <FaPlus className="h-4 w-4 mr-2" />
                <span>Add</span>
                <FaChevronDown className="h-3 w-3 ml-2" />
              </PopoverButton>

              {open && (
                <PopoverPanel
                  static
                  className="absolute z-10 mt-1 w-64 max-h-80 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none right-0"
                >
                  <div className="p-2">
                    <input
                      ref={inputRef}
                      type="text"
                      placeholder="Search or create label..."
                      className="w-full px-3 py-2 text-sm leading-5 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <div className="py-1">
                    {isLabelsLoading ? (
                      <div className="px-4 py-2 text-sm text-gray-500">
                        Loading...
                      </div>
                    ) : (
                      <>
                        {filteredLabels.map((label) => (
                          <div
                            key={label.label_id}
                            className="flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleLabelClick(label)}
                          >
                            <div className="flex items-center">
                              <div
                                style={{ backgroundColor: label.color }}
                                className="w-4 h-4 rounded-full mr-3"
                              />
                              {label.name}
                            </div>
                            {initialLabels?.some(
                              (l) => l.label_id === label.label_id
                            ) && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleLabelRemove(label);
                                }}
                                className="text-gray-400 bg-transparent hover:text-gray-600"
                              >
                                ×
                              </button>
                            )}
                          </div>
                        ))}

                        {searchTerm && !filteredLabels.length && (
                          <button
                            className="flex items-center w-full bg-transparent px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={handleCreateLabel}
                          >
                            <FaPlus className="h-4 w-4 mr-3" />
                            Create "{searchTerm}"
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </PopoverPanel>
              )}
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};

export default LabelSelector;
