import React, { act, useState } from "react";
import { Fragment } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaPaperPlane } from "react-icons/fa";

export function DebugSummaryActionModal({ isOpen, onClose, action }) {
  const [rows, setRows] = useState([
    {
      id: 1,
      operation: "201598",
      product: "ProductA",
      change: "add",
      fileName: "file1.txt",
      buddyStatus: "Approved - Vivek V.",
    },
    {
      id: 2,
      operation: "201598",
      product: "ProductA",
      change: "delete",
      fileName: null,
      buddyStatus: null,
    },
  ]);

  // Add a new row to the table
  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        operation: "",
        product: "",
        change: "",
        buddyStatus: null,
      },
    ]);
  };

  // Remove a row from the table
  const handleRemoveRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  return (
    // <Transition appear show={isOpen} as={Fragment}>
    //   <Dialog as="div" className="relative z-50" onClose={onClose}>
    //     <TransitionChild
    //       as={Fragment}
    //       enter="ease-out duration-300"
    //       enterFrom="opacity-0"
    //       enterTo="opacity-100"
    //       leave="ease-in duration-200"
    //       leaveFrom="opacity-100"
    //       leaveTo="opacity-0"
    //     >
    //       <div className="fixed inset-0 bg-black bg-opacity-25" />
    //     </TransitionChild>

    //     <div className="fixed inset-0 flex items-center justify-center p-4">
    //       <TransitionChild
    //         as={Fragment}
    //         enter="ease-out duration-300"
    //         enterFrom="opacity-0 scale-95"
    //         enterTo="opacity-100 scale-100"
    //         leave="ease-in duration-200"
    //         leaveFrom="opacity-100 scale-100"
    //         leaveTo="opacity-0 scale-95"
    //       >
    //         <DialogPanel className="relative w-full max-w-5xl transform rounded-xl bg-white p-6 shadow-xl transition-all flex flex-col max-h-[90vh]">
    //           <DialogTitle
    //             as="h3"
    //             className="text-xl font-semibold sticky top-0 leading-6 text-gray-900"
    //           >
    //             Debug Summary Action Modal
    //           </DialogTitle>

    //           {/* Footer Actions */}
    //           <div className="sticky bottom-0 bg-white pt-6 flex justify-end items-center">
    //             <div className="flex space-x-3">
    //               <button
    //                 className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
    //                 onClick={onClose}
    //               >
    //                 Cancel
    //               </button>
    //               <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm">
    //                 Execute
    //               </button>
    //             </div>
    //           </div>
    //         </DialogPanel>
    //       </TransitionChild>
    //     </div>
    //   </Dialog>
    // </Transition>
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-4xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {/* Modal Header */}
                <div className="flex justify-between items-center mb-4">
                  <DialogTitle
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Tool Table Update
                  </DialogTitle>
                </div>

                {/* Table */}
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-sm font-bold text-gray-500"
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-sm font-bold text-gray-500"
                        >
                          Operation
                        </th>
                        {action === "Recipe update" && (
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-bold text-gray-500"
                          >
                            Product
                          </th>
                        )}
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-sm font-bold text-gray-500"
                        >
                          Change
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-sm font-bold text-gray-500"
                        >
                          Edit File
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-sm font-bold text-gray-500"
                        >
                          Buddy Request
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-sm font-bold text-gray-500"
                        >
                          Buddy Status
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-right text-sm font-bold text-gray-500"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {rows.map((row) => (
                        <tr key={row.id}>
                          <td className="px-6 py-3 text-sm">{row.id}</td>
                          <td className="px-6 py-3 text-sm">
                            <input
                              type="text"
                              value={row.operation}
                              onChange={(e) =>
                                setRows(
                                  rows.map((r) =>
                                    r.id === row.id
                                      ? { ...r, operation: e.target.value }
                                      : r,
                                  ),
                                )
                              }
                              className="px-4 py-2 w-24 border border-solid border-1 border-gray-200 rounded-lg"
                            />
                          </td>
                          {action === "Recipe update" && (
                            <td className="px-6 py-3 text-sm">
                              <input
                                type="text"
                                className="px-4 py-2 w-24 border border-solid border-1 border-gray-200 rounded-lg"
                                value={row.product}
                                onChange={(e) =>
                                  setRows(
                                    rows.map((r) =>
                                      r.id === row.id
                                        ? { ...r, product: e.target.value }
                                        : r,
                                    ),
                                  )
                                }
                              />
                            </td>
                          )}
                          <td className="px-6 py-3 text-sm">
                            {/* {row.change} */}
                            <input
                              type="text"
                              className="px-4 py-2 w-24 border border-solid border-1 border-gray-200 rounded-lg"
                              value={row.change}
                              onChange={(e) =>
                                setRows(
                                  rows.map((r) =>
                                    r.id === row.id
                                      ? { ...r, product: e.target.value }
                                      : r,
                                  ),
                                )
                              }
                            />
                          </td>
                          <td className="px-6 py-3 text-sm">
                            {row.fileName ? (
                              <span>{row.fileName}</span>
                            ) : (
                              <button className="px-3 py-1 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700">
                                Upload
                              </button>
                            )}
                          </td>
                          <td className="px-6 py-3 text-sm">
                            <button
                              disabled={!row.fileName}
                              className="flex gap-2 px-3 py-1 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 disabled:opacity-50"
                            >
                              <FaPaperPlane />
                              <span>Send&nbsp;Request</span>
                            </button>
                          </td>
                          <td className="px-6 py-3 text-sm">
                            {row.buddyStatus ? (
                              <span>{row.buddyStatus}</span>
                            ) : (
                              <div className="flex space-x-2">
                                <button
                                  disabled={!row.fileName}
                                  className="px-3 py-1 bg-green-500 text-white text-sm rounded-md hover:bg-green-600 mr-2 disabled:opacity-50"
                                >
                                  Approve
                                </button>
                                <button
                                  disabled={!row.fileName}
                                  className="px-3 py-1 bg-red-500 text-white text-sm rounded-md hover:bg-red-600 disabled:opacity-50"
                                >
                                  Deny
                                </button>
                              </div>
                            )}
                          </td>
                          <td className="px-6 py-3 text-center">
                            <button
                              onClick={() => handleRemoveRow(row.id)}
                              className="text-red-500 hover:text-red-700 bg-white text-lg"
                            >
                              <AiFillCloseCircle />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Footer Buttons */}
                <div className="mt-6 flex justify-between">
                  <button
                    onClick={handleAddRow}
                    className="px-4 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700"
                  >
                    Add Row
                  </button>
                  <div className="flex space-x-3">
                    <button
                      onClick={() => console.log("Submit")}
                      className="px-4 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700"
                    >
                      Submit Tool Table Update
                    </button>
                    <button
                      onClick={onClose}
                      className="px-4 py-2 bg-gray-300 text-sm rounded-md hover:bg-gray-400"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
