import React, { useEffect, useRef } from "react";
import { Bar, Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import * as d3 from "d3";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const ScatterGraph = () => {
  return (
    <div className="bg-white border border-gray-200 rounded-lg p-4 h-full flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-sm font-semibold text-gray-900">
          CD Sigma Distribution Analysis
        </h4>
      </div>
      {/* Scatter Plot Container */}
      <div className="flex-1 min-h-0">
        <div className="h-64">
          <Scatter
            data={{
              datasets: [
                {
                  label: "ECH001_PM1",
                  data: Array.from({ length: 100 }, () => ({
                    x: Math.random() * 2 + 0.5,
                    y: Math.random() * 0.4,
                  })),
                  backgroundColor: "rgba(239, 68, 68, 0.8)",
                  borderColor: "rgba(239, 68, 68, 1)",
                  pointRadius: 5,
                  pointHoverRadius: 7,
                },
                {
                  label: "ECH002_PM2",
                  data: Array.from({ length: 100 }, () => ({
                    x: Math.random() * 2 + 0.5,
                    y: Math.random() * 0.4,
                  })),
                  backgroundColor: "rgba(68, 239, 82, 0.8)",
                  borderColor: "rgba(68, 239, 82, 1)",
                  pointRadius: 5,
                  pointHoverRadius: 7,
                },
                {
                  label: "ECH003_PM3",
                  data: Array.from({ length: 100 }, () => ({
                    x: Math.random() * 1.5 + 0.8,
                    y: Math.random() * 0.3,
                  })),
                  backgroundColor: "rgba(59, 130, 246, 0.8)",
                  borderColor: "rgba(59, 130, 246, 1)",
                  pointRadius: 5,
                  pointHoverRadius: 7,
                },
                {
                  label: "ECH001",
                  data: Array.from({ length: 200 }, () => ({
                    x: Math.random() * 1.5 + 0.8,
                    y: Math.random() * 0.3,
                  })),
                  backgroundColor: "rgba(246, 243, 59, 0.8)",
                  borderColor: "rgba(246, 243, 59, 1)",
                  pointRadius: 5,
                  pointHoverRadius: 7,
                },
                {
                  label: "ECH002",
                  data: Array.from({ length: 100 }, () => ({
                    x: Math.random() * 1.5 + 0.8,
                    y: Math.random() * 0.3,
                  })),
                  backgroundColor: "rgba(59, 246, 243, 0.8)",
                  borderColor: "rgba(59, 246, 243, 1)",
                  pointRadius: 5,
                  pointHoverRadius: 7,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  title: {
                    display: true,
                    text: "CD Sigma (nm)",
                    color: "#6b7280",
                  },
                  beginAtZero: true,
                  grid: { color: "#f3f4f6" },
                  ticks: { color: "#6b7280" },
                },
                x: {
                  title: {
                    display: true,
                    text: "Measurement Index",
                    color: "#6b7280",
                  },
                  grid: { color: "#f3f4f6" },
                  ticks: { color: "#6b7280" },
                },
              },
              plugins: {
                legend: {
                  display: true,
                  position: "bottom",
                },
                tooltip: {
                  backgroundColor: "#1f2937",
                  titleColor: "#f9fafb",
                  bodyColor: "#f9fafb",
                  borderColor: "#374151",
                  borderWidth: 1,
                  cornerRadius: 4,
                  displayColors: false,
                },
              },
            }}
          />
        </div>

        {/* Plot Statistics */}
        <div className="mt-4 grid grid-cols-3 gap-4 text-xs">
          <div className="bg-gray-50 p-2 rounded">
            <p className="text-gray-500">Process Capability</p>
            <p className="text-gray-900 font-medium">Cpk: 0.82</p>
          </div>
          <div className="bg-gray-50 p-2 rounded">
            <p className="text-gray-500">±3σ Range</p>
            <p className="text-gray-900 font-medium">0.54 - 1.46nm</p>
          </div>
          <div className="bg-gray-50 p-2 rounded">
            <p className="text-gray-500">Outliers</p>
            <p className="text-gray-900 font-medium">6.7%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BarGraph = ({ xAxisLabel, yAxisLabel, barLabels, chartTitle }) => {
  // Data for FCCD Standard Deviation
  const data = {
    labels: barLabels,
    datasets: [
      {
        label: "Stdev",
        data: [0.9, 0.85, 0.8, 0.6],
        backgroundColor: "rgba(59, 130, 246, 0.8)",
        borderColor: "rgba(59, 130, 246, 1)",
        borderRadius: 4, // Rounded bars
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend since we only have one dataset
      },
      title: {
        display: true,
        text: chartTitle,
        font: {
          size: 16,
          weight: "bold",
          family: "sans-serif",
        },
        color: "#374151", // Tailwind Gray-700
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines for cleaner look
        },
        title: {
          display: true,
          text: xAxisLabel,
          font: {
            size: 12,
            weight: "bold",
          },
          color: "#374151",
        },
      },
      y: {
        beginAtZero: true,
        max: 1,
        ticks: {
          stepSize: 0.2,
        },
        title: {
          display: true,
          text: yAxisLabel,
          font: {
            size: 12,
            weight: "bold",
          },
          color: "#374151",
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export const DirectedGraph = () => {
  const svgRef = useRef();

  useEffect(() => {
    // Graph Data
    const nodes = [
      { id: "Mag_L4", group: "L4" },
      { id: "Skew_L4", group: "L4" },
      { id: "SCL_X/Y_L4", group: "L4" },
      { id: "SFT_X/Y_L4", group: "L4" },
      { id: "DCCD_L4", group: "L4" },
      { id: "ECH01_L4", group: "highlighted" },
      { id: "HM2_L4", group: "L4" },
      { id: "HCCD_L4", group: "L4" },
      { id: "HM1_L4", group: "L4" },

      { id: "Mag_L3", group: "L3" },
      { id: "Skew_L3", group: "L3" },
      { id: "SCL_X/Y_L3", group: "L3" },
      { id: "SFT_X/Y_L3", group: "L3" },
      { id: "DCCD_L3", group: "L3" },
      { id: "ECH01_L3", group: "highlighted" },
      { id: "HM2_L3", group: "L3" },
      { id: "HCCD_L3", group: "L3" },
      { id: "HM1_L3", group: "L3" },
    ];

    const links = [
      { source: "Mag_L4", target: "DCCD_L4" },
      { source: "Skew_L4", target: "DCCD_L4" },
      { source: "SCL_X/Y_L4", target: "DCCD_L4" },
      { source: "SFT_X/Y_L4", target: "DCCD_L4" },
      { source: "DCCD_L4", target: "HCCD_L4" },
      { source: "HM1_L4", target: "HCCD_L4" },
      { source: "HM2_L4", target: "HCCD_L4" },
      { source: "HM1_L4", target: "ECH01_L4" },
      { source: "HM2_L4", target: "ECH01_L4" },
      { source: "ECH01_L4", target: "HCCD_L4" },

      { source: "Mag_L3", target: "DCCD_L3" },
      { source: "Skew_L3", target: "DCCD_L3" },
      { source: "SCL_X/Y_L3", target: "DCCD_L3" },
      { source: "SFT_X/Y_L3", target: "DCCD_L3" },
      { source: "DCCD_L3", target: "HCCD_L3" },
      { source: "HM1_L3", target: "HCCD_L3" },
      { source: "HM2_L3", target: "HCCD_L3" },
      { source: "HM1_L3", target: "ECH01_L3" },
      { source: "HM2_L3", target: "ECH01_L3" },
      { source: "ECH01_L3", target: "HCCD_L3" },

      { source: "ECH01_L3", target: "HCCD_L4", highlight: true }, // Red link
    ];

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous content

    const width = 1000;
    const height = 800;

    svg
      .attr("viewBox", `0 0 ${width} ${height}`)
      .style("border", "1px solid #ddd");

    // Simulation for positioning
    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3
          .forceLink(links)
          .id((d) => d.id)
          .distance(100),
      )
      .force("charge", d3.forceManyBody().strength(-125))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collision", d3.forceCollide(30));

    // Draw links
    const link = svg
      .append("g")
      .selectAll("line")
      .data(links)
      .enter()
      .append("line")
      .attr("stroke", (d) => (d.highlight ? "red" : "#aaa"))
      .attr("stroke-width", (d) => (d.highlight ? 2.5 : 1.5))
      .attr("marker-end", "url(#arrow)");

    // Add nodes
    const node = svg
      .append("g")
      .selectAll("circle")
      .data(nodes)
      .enter()
      .append("circle")
      .attr("r", 20)
      .attr("fill", (d) => (d.group === "highlighted" ? "orange" : "white"))
      .attr("stroke", "#aaa")
      .attr("stroke-width", 2);

    // Add labels
    const label = svg
      .append("g")
      .selectAll("text")
      .data(nodes)
      .enter()
      .append("text")
      .text((d) => d.id)
      .attr("font-size", 10)
      .attr("text-anchor", "middle")
      .attr("dy", 4);

    // Simulation tick updates
    simulation.on("tick", () => {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

      label.attr("x", (d) => d.x).attr("y", (d) => d.y);
    });

    // Arrowhead definitions
    svg
      .append("defs")
      .append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", 25)
      .attr("refY", 5)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 Z")
      .attr("fill", "#aaa");
  }, []);

  return (
    <div className="flex justify-center items-center w-4/5">
      <svg ref={svgRef} />
    </div>
  );
};
