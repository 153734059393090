import { createRoot } from "react-dom/client";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReportPreview from "./report-preview";

export const exportReportToPDF = async ({ reportId, title, onProgress }) => {
  try {
    onProgress?.("start", "Preparing document...");
    console.log("Starting PDF export for report:", reportId);

    // Create and style container
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.left = "-9999px";
    container.style.top = "0";
    container.style.width = "3000px"; // Fixed width
    container.style.backgroundColor = "white";
    document.body.appendChild(container);

    return new Promise((resolve, reject) => {
      let attempts = 0;
      const maxAttempts = 10;

      const tryRender = async () => {
        attempts++;
        console.log(`Render attempt ${attempts}`);

        if (!container.querySelector(".w-full.max-w-4xl")) {
          if (attempts < maxAttempts) {
            setTimeout(tryRender, 1000);
            return;
          }
          reject(new Error("Could not find report content after max attempts"));
          return;
        }

        try {
          onProgress?.("processing", "Generating PDF...");

          const content = container.querySelector(".w-full.max-w-4xl");
          console.log("Content found:", content);

          // Force all images to load
          const images = Array.from(content.getElementsByTagName("img"));
          await Promise.all(
            images.map((img) => {
              if (img.complete) return Promise.resolve();
              return new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
              });
            })
          );

          const canvas = await html2canvas(content, {
            scale: 2,
            useCORS: true,
            allowTaint: true,
            backgroundColor: "#ffffff",
            windowWidth: 3000,
            logging: true, // Enable logging
            onclone: (clonedDoc) => {
              const clonedContent =
                clonedDoc.querySelector(".w-full.max-w-4xl");
              if (clonedContent) {
                clonedContent.style.transform = "none";
                clonedContent.style.width = "3000px";
                clonedContent.style.margin = "0";
                clonedContent.style.padding = "40px";
                clonedContent.style.backgroundColor = "white";
              }
            },
          });

          console.log("Canvas generated:", canvas.width, "x", canvas.height);

          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF({
            orientation: "p",
            unit: "px",
            format: [canvas.width / 2, canvas.height / 2],
          });

          pdf.addImage(
            imgData,
            "PNG",
            0,
            0,
            canvas.width / 2,
            canvas.height / 2
          );
          pdf.save(`${title || "report"}.pdf`);

          onProgress?.("complete", "PDF has been generated and downloaded");
          document.body.removeChild(container);
          resolve();
        } catch (error) {
          console.error("PDF generation error:", error);
          reject(error);
        }
      };

      // Render the preview component
      const root = createRoot(container);
      root.render(
        <ReportPreview
          reportId={reportId}
          isPdfExport={true}
          onReportLoaded={() => {
            console.log("Report loaded, starting render process");
            tryRender();
          }}
          onError={(error) => {
            console.error("Report loading error:", error);
            reject(error);
          }}
        />
      );
    });
  } catch (error) {
    console.error("PDF Export Error:", error);
    onProgress?.("error", "Failed to generate PDF");
    throw error;
  }
};
