export default function TermsOfUse() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="relative w-full min-h-screen bg-white rounded-lg shadow">
        <iframe
          src="https://app.termly.io/policy-viewer/policy.html?policyUUID=95ebcd0b-2392-49b2-a74f-f8cacbd6ee9e"
          title="Privacy Policy"
          className="w-full h-full absolute inset-0 border-0 rounded-lg"
          style={{ minHeight: "800px" }}
        />
      </div>
    </div>
  );
}
