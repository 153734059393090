export default function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="relative w-full min-h-screen bg-white rounded-lg shadow">
        <iframe
          src="https://app.termly.io/policy-viewer/policy.html?policyUUID=6f3d7b3f-2549-473f-82b1-9952602269f7"
          title="Privacy Policy"
          className="w-full h-full absolute inset-0 border-0 rounded-lg"
          style={{ minHeight: "800px" }}
        />
      </div>
    </div>
  );
}
