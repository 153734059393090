import React, { useEffect, useRef, useState } from "react";
import {
  HiPlus,
  HiOutlineUserCircle,
  HiOutlineMail,
  HiCheck,
  HiUpload,
  HiOutlineSearch,
  HiOutlineX,
} from "react-icons/hi";
import useWorkspaceStore from "../../stores/workspaceStore";
import WorkspaceService from "../../service/WorkspaceService";
import WorkspaceUser from "./components/workspace-user";
import { motion, AnimatePresence } from "framer-motion";
import Tooltip from "../tooltip";

const PERMISSION_GROUPS = [
  "WORKSPACE_OWNER",
  "WORKSPACE_ADMIN",
  "WORKSPACE_EDITOR",
  "WORKSPACE_VIEWER",
  "WORKSPACE_REPORTS_EDITOR",
].map((group) => ({
  label: group
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" "),
  value: group,
  description: getPermissionDescription(group),
}));

function getPermissionDescription(group) {
  const descriptions = {
    WORKSPACE_OWNER: "Full access to workspace settings and member management",
    WORKSPACE_ADMIN: "Can manage workspace members and content",
    WORKSPACE_EDITOR: "Can create and edit workspace content",
    WORKSPACE_VIEWER: "Can only view workspace content",
    WORKSPACE_REPORTS_EDITOR: "Can create and edit reports",
  };
  return descriptions[group];
}

const WorkspaceUsers = () => {
  const {
    workspaceUsers,
    selectedWorkspace,
    fetchWorkspaceUsers,
    inviteWorkspaceUser,
    isLoading,
  } = useWorkspaceStore();

  const [showInviteForm, setShowInviteForm] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    name: "",
    permissionGroups: [],
  });
  const [allUsers, setAllUsers] = useState([]);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedExistingUser, setSelectedExistingUser] = useState(null);
  const [showPermissionDropdown, setShowPermissionDropdown] = useState(false);
  const [showExistingUserDropdown, setShowExistingUserDropdown] = useState(false);
  const [csvError, setCsvError] = useState("");
  const [uploadProgress, setUploadProgress] = useState({
    total: 0,
    current: 0,
  });

  const fileInputRef = useRef(null);
  const permissionDropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const existingUserDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        permissionDropdownRef.current &&
        !permissionDropdownRef.current.contains(event.target)
      ) {
        setShowPermissionDropdown(false);
      }
      if (
        existingUserDropdownRef.current &&
        !existingUserDropdownRef.current.contains(event.target)
      ) {
        setShowExistingUserDropdown(false);
      }

    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      fetchWorkspaceUsers(selectedWorkspace.workspace_id);
      fetchAllUsers();
    }
  }, [selectedWorkspace, fetchWorkspaceUsers]);

  useEffect(() => {
    const filtered = allUsers.filter(
      (user) =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchTerm, allUsers]);

  const handleCSVUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setCsvError("");
    setUploadProgress({ total: 0, current: 0 });

    if (!file.type.includes("csv")) {
      setCsvError("Please upload a CSV file");
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const lines = text.split("\n").filter((line) => line.trim());
        const headers = lines[0].toLowerCase().trim().split(",");

        const requiredHeaders = ["email", "name", "permission_groups"];
        const missingHeaders = requiredHeaders.filter(
          (h) => !headers.includes(h)
        );

        if (missingHeaders.length > 0) {
          setCsvError(`Missing required headers: ${missingHeaders.join(", ")}`);
          return;
        }

        const users = [];
        const errors = [];

        for (let i = 1; i < lines.length; i++) {
          if (!lines[i].trim()) continue;

          const values = lines[i].trim().split(",");
          const user = {
            email: values[headers.indexOf("email")]?.trim(),
            name: values[headers.indexOf("name")]?.trim(),
            permission_groups: values[headers.indexOf("permission_groups")]
              ?.split(";")
              .map((g) => g.trim())
              .filter((g) => PERMISSION_GROUPS.some((pg) => pg.value === g)),
          };

          if (
            !user.email ||
            !user.name ||
            user.permission_groups.length === 0
          ) {
            errors.push(`Row ${i + 1}: Missing or invalid data`);
            continue;
          }

          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
            errors.push(`Row ${i + 1}: Invalid email format`);
            continue;
          }

          users.push(user);
        }

        if (errors.length > 0) {
          setCsvError(`Validation errors:\n${errors.join("\n")}`);
          return;
        }

        setUploadProgress({ total: users.length, current: 0 });

        for (let i = 0; i < users.length; i++) {
          try {
            await inviteWorkspaceUser(selectedWorkspace.workspace_id, users[i]);
            setUploadProgress((prev) => ({ ...prev, current: i + 1 }));
          } catch (error) {
            setCsvError(`Error inviting ${users[i].email}: ${error.message}`);
            break;
          }
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setUploadProgress({ total: 0, current: 0 });
      };
      reader.readAsText(file);
    } catch (error) {
      console.error("Error processing CSV:", error);
      setCsvError("Error processing CSV file");
    }
  };

  const fetchAllUsers = async () => {
    try {
      setIsFetchingUsers(true);
      const temp_result = await WorkspaceService.getAllUser();
      const result = temp_result.map((user) => ({
        ...user, name: user.name || user.email 
      }));
      setAllUsers(result);
      setFilteredUsers(result);
    } catch (error) {
      console.error("Failed to fetch users", error);
    } finally {
      setIsFetchingUsers(false);
    }
  };

  const handleInviteUser = async (e) => {
    e.preventDefault();
    try {
      if (selectedExistingUser) {
        await inviteWorkspaceUser(selectedWorkspace.workspace_id, {
          permission_groups: newUser.permissionGroups,
          invitee_id: selectedExistingUser.user_id,
        });
      } else {
        await inviteWorkspaceUser(selectedWorkspace.workspace_id, newUser);
      }
      setNewUser({ email: "", name: "", permissionGroups: [] });
      setSelectedExistingUser(null);
      setShowInviteForm(false);
      setSearchTerm("");
    } catch (error) {
      console.error("Failed to invite user:", error);
    }
  };

  const togglePermissionGroup = (permission) => {
    setNewUser((prev) => {
      const newGroups = prev.permissionGroups.includes(permission.value)
        ? prev.permissionGroups.filter((g) => g !== permission.value)
        : [...prev.permissionGroups, permission.value];
      return { ...prev, permissionGroups: newGroups };
    });
  };

  const handleSearchFocus = () => {
    setShowExistingUserDropdown(true);
  };

  return (
    <div className="flex-col w-full max-w-5xl mx-auto px-4 py-8 bg-white h-full">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-medium text-gray-900">
          Workspace Members
        </h1>
        <div className="flex gap-3">
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVUpload}
            ref={fileInputRef}
            className="hidden"
            id="csvUpload"
          />
          <Tooltip content="Import members via CSV">
            <label
              htmlFor="csvUpload"
              className="inline-flex text-sm items-center px-3 py-1.5 bg-white border border-gray-300 text-gray-600 rounded-md hover:bg-gray-50 transition-colors cursor-pointer"
            >
              <HiUpload className="mr-1.5 w-4 h-4" />
              Import
            </label>
          </Tooltip>
          <button
            onClick={() => setShowInviteForm(true)}
            className="inline-flex text-sm items-center px-3 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            <HiPlus className="mr-1.5 w-4 h-4" />
            Invite people
          </button>
        </div>
      </div>

      <AnimatePresence>
        {csvError && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="mb-4 p-4 bg-red-50 border border-red-100 text-red-600 rounded-lg flex items-start"
          >
            <span className="flex-grow">{csvError}</span>
            <button
              onClick={() => setCsvError("")}
              className="ml-2 text-red-500 hover:text-red-700"
            >
              <HiOutlineX className="w-5 h-5" />
            </button>
          </motion.div>
        )}

        {uploadProgress.total > 0 && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="mb-4 p-4 bg-blue-50 border border-blue-100 text-blue-600 rounded-lg"
          >
            <div className="flex justify-between mb-2">
              <span>Importing members...</span>
              <span>
                {Math.round(
                  (uploadProgress.current / uploadProgress.total) * 100
                )}
                %
              </span>
            </div>
            <div className="w-full bg-blue-100 rounded-full h-1">
              <div
                className="bg-blue-600 h-1 rounded-full transition-all duration-300"
                style={{
                  width: `${(uploadProgress.current / uploadProgress.total) * 100
                    }%`,
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showInviteForm && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="mb-8 bg-white p-6 rounded-lg shadow-sm border border-gray-200"
          >
            <form onSubmit={handleInviteUser} className="space-y-6">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    User
                </label>
                <div className="flex items-center border-solid border border-gray-200 rounded-lg px-3 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-transparent">
                  <HiOutlineSearch className="text-gray-400 w-5 h-5" />
                  <input
                    type="text"
                    ref={searchInputRef}
                    value={searchTerm}
                    autoFocus
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={handleSearchFocus}
                    placeholder="Search people..."
                    className="ml-2 flex-1 outline-0 text-sm border-none h-9"
                  />
                </div>
                {showExistingUserDropdown && <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto" ref={existingUserDropdownRef}>
                  {filteredUsers.length > 0 ?
                    filteredUsers.map((user) => (
                      <div
                        key={user.id}
                        onClick={() => {
                          setSelectedExistingUser(user);
                          setSearchTerm("");
                          setShowExistingUserDropdown(false);
                          setNewUser({
                            email: "",
                            name: "",
                            permissionGroups: ["WORKSPACE_EDITOR"], // Default permission
                          });
                        }}
                        className="flex items-center px-4 py-3 hover:bg-gray-50 cursor-pointer"
                      >
                        <div className="w-8 h-8 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center font-medium">
                          {user.name.charAt(0).toUpperCase()}
                        </div>
                        <div className="ml-3">
                          <div className="text-sm font-medium text-gray-900">
                            {user.name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {user.email}
                          </div>
                        </div>
                      </div>
                    ))
                    : <div className="flex justify-center items-center text-sm font-medium text-gray-400 py-6">
                      No results found
                    </div>
                  }
                </div>
                }
              </div>

              {selectedExistingUser ? (
                <div className="flex items-center bg-gray-50 p-4 rounded-lg">
                  <div className="w-10 h-10 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center font-medium">
                    {selectedExistingUser.name.charAt(0).toUpperCase()}
                  </div>
                  <div className="ml-3 flex-grow">
                    <div className="text-sm font-medium text-gray-900">
                      {selectedExistingUser.name}
                    </div>
                    <div className="text-xs text-gray-500">
                      {selectedExistingUser.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedExistingUser(null);
                      setNewUser({
                        email: "",
                        name: "",
                        permissionGroups: [],
                      });
                    }}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <HiOutlineX className="w-5 h-5" />
                  </button>
                </div>
              ) : (
                <div className="space-y-4 hidden"> {/* Hiding for 18th demo */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Name
                    </label>
                    <div className="relative flex items-center border-solid border border-gray-200 rounded-lg px-3 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-transparent">
                      <HiOutlineUserCircle className="text-gray-400 w-5 h-5" />
                      <input
                        type="text"
                        value={newUser.name}
                        onChange={(e) =>
                          setNewUser({ ...newUser, name: e.target.value })
                        }
                        className="ml-2 flex-1 outline-0 text-sm border-none h-9"
                        placeholder="Full name"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <div className="relative flex items-center border-solid border border-gray-200 rounded-lg px-3 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-transparent">
                      <HiOutlineMail className="text-gray-400 w-5 h-5" />
                      <input
                        type="email"
                        value={newUser.email}
                        onChange={(e) =>
                          setNewUser({ ...newUser, email: e.target.value })
                        }
                        className="ml-2 flex-1 outline-0 text-sm border-none h-9"
                        placeholder="name@company.com"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Role
                </label>
                <div className="relative flex items-center border-solid border border-gray-200 rounded-lg focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-transparent" ref={permissionDropdownRef}>
                  <button
                    type="button"
                    onClick={() =>
                      setShowPermissionDropdown(!showPermissionDropdown)
                    }
                    className="w-full px-4 h-9 text-left border rounded-lg bg-white focus:outline-none focus:border-transparent cursor-pointer"
                  >
                    {newUser.permissionGroups.length === 0 ? (
                      <span className="text-gray-500">Select a role...</span>
                    ) : (
                      <span>
                        {
                          PERMISSION_GROUPS.find(
                            (p) => p.value === newUser.permissionGroups[0]
                          )?.label
                        }
                      </span>
                    )}
                  </button>

                  {showPermissionDropdown && (
                    <div className="absolute z-20 w-full mt-1 bg-white border rounded-lg shadow-lg">
                      {PERMISSION_GROUPS.map((permission) => (
                        <Tooltip
                          key={permission.value}
                          content={permission.description}
                        >
                          <div
                            onClick={() => {
                              setNewUser({
                                ...newUser,
                                permissionGroups: [permission.value],
                              });
                              setShowPermissionDropdown(false);
                            }}
                            className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                          >
                            <div className="w-5 h-5 border rounded-full mr-3 flex items-center justify-center">
                              {newUser.permissionGroups.includes(
                                permission.value
                              ) && (
                                <div className="w-3 h-3 bg-blue-600 rounded-full" />
                              )}
                            </div>
                            <div>
                              <div className="text-sm font-medium">
                                {permission.label}
                              </div>
                              <div className="text-xs text-gray-500">
                                {permission.description}
                              </div>
                            </div>
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex justify-end space-x-3 pt-4 border-t">
                <button
                  type="button"
                  onClick={() => {
                    setShowInviteForm(false);
                    setSelectedExistingUser(null);
                    setNewUser({ email: "", name: "", permissionGroups: [] });
                  }}
                  className="px-4 py-2 text-sm text-gray-700 hover:text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={
                    (!selectedExistingUser &&
                      (!newUser.email || !newUser.name)) ||
                    !newUser.permissionGroups.length
                  }
                >
                  {selectedExistingUser ? "Add to workspace" : "Send invite"}
                </button>
              </div>
            </form>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        {isFetchingUsers ? (
          <div className="p-8 text-center text-gray-500">
            Loading members...
          </div>
        ) : workspaceUsers.length === 0 ? (
          !showInviteForm && <div className="p-8 text-center">
            <div className="text-gray-400 mb-2">No members yet</div>
            <div className="text-sm text-gray-500 py-8">
              Invite people to collaborate in your workspace
            </div>
          </div>
        ) : (
          <ul className="divide-y divide-gray-200">
            {workspaceUsers.map((user) => (
              <WorkspaceUser key={user.id} user={user} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default WorkspaceUsers;
