import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { AiOutlineDelete, AiOutlineFolder } from "react-icons/ai";
import { ImExit } from "react-icons/im";
import useWorkspaceStore from "../../stores/workspaceStore";
import { CreateWorkspaceModal } from "../menubar/components/create-workspace-modal";
import { DeleteWorkspaceModal } from "../menubar/components/delete-workspace-modal";
import { BiExit } from "react-icons/bi";
import { LeaveWorkspaceModal } from "../menubar/components/leave-workspace-modal";

const WorkspaceList = () => {
  const { workspaces } = useWorkspaceStore();
  const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] =
    useState(false);
  const [isDeleteWorkspaceModalOpen, setIsDeleteWorkspaceModalOpen] =
    useState(false);
  const [isLeaveWorkspaceModalOpen, setIsLeaveWorkspaceModalOpen] =
    useState(false);
  const [workspaceToLeave, setWorkspaceToLeave] = useState(null);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState(new Set());
  const [ownedWorkspaceIds, setOwnedWorkspaceIds] = useState([]);
  const [localWorkspaces, setLocalWorkspaces] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  useEffect(() => {
    setLocalWorkspaces([...workspaces]);
    setOwnedWorkspaceIds(
      workspaces.filter((w) => w.role === "owner").map((w) => w.workspace_id)
    );
  }, [workspaces]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedWorkspaces = [...localWorkspaces].sort((a, b) => {
      if (key === "created_at" || key === "updated_at") {
        return direction === "asc"
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      }

      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setLocalWorkspaces(sortedWorkspaces);
  };

  const handleSelectWorkspace = (workspaceId) => {
    setSelectedWorkspaces((prev) => {
      const newSelected = new Set([...prev]);
      if (newSelected.has(workspaceId)) {
        newSelected.delete(workspaceId);
      } else {
        newSelected.add(workspaceId);
      }
      return newSelected;
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedWorkspaces(new Set(ownedWorkspaceIds));
    } else {
      setSelectedWorkspaces(new Set());
    }
  };

  return (
    <div className="bg-white h-full rounded-lg shadow flex flex-col">
      <CreateWorkspaceModal
        isOpen={isCreateWorkspaceModalOpen}
        onClose={() => setIsCreateWorkspaceModalOpen(false)}
      />
      <DeleteWorkspaceModal
        isOpen={isDeleteWorkspaceModalOpen}
        onClose={() => setIsDeleteWorkspaceModalOpen(false)}
        workspaceIds={Array.from(selectedWorkspaces)}
        onSuccess={() => {
          setSelectedWorkspaces(new Set());
        }}
      />
      <LeaveWorkspaceModal
        isOpen={isLeaveWorkspaceModalOpen}
        onClose={() => {
          setWorkspaceToLeave(null);
          setIsLeaveWorkspaceModalOpen(false);
        }}
        workspace={workspaceToLeave}
        onSuccess={() => {
          setWorkspaceToLeave(null);
          setSelectedWorkspaces(new Set());
        }}
      />
      <div className="border-b px-6 py-4 flex items-center justify-between sticky top-0 bg-white z-20">
        <div className="flex items-center space-x-4">
          {selectedWorkspaces.size > 0 && (
            <button
              onClick={() => setIsDeleteWorkspaceModalOpen(true)}
              className="inline-flex items-center px-3 py-1.5 border border-red-300 rounded-md text-sm font-medium text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <AiOutlineDelete className="mr-2 h-4 w-4" />
              Delete
            </button>
          )}
        </div>
        <div>
          <button
            onClick={() => setIsCreateWorkspaceModalOpen(true)}
            // disabled={isUploading}
            data-tooltip-content="Create new workspace"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <AiOutlineFolder className="mr-2 h-4 w-4" />
            Create
          </button>
        </div>
      </div>
      <div className="overflow-x-auto flex-1">
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr>
              <th scope="col" className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={
                    ownedWorkspaceIds.length === selectedWorkspaces.size &&
                    ownedWorkspaceIds.length > 0
                  }
                  onChange={handleSelectAll}
                />
              </th>
              {[
                { key: "name", label: "Name" },
                { key: "description", label: "Description" },
                { key: "role", label: "Role" },
                { key: "created_at", label: "Created At" },
                { key: "updated_at", label: "Updated At" },
              ].map(({ key, label }) => (
                <th
                  key={key}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 select-none"
                  onClick={() => handleSort(key)}
                >
                  <div className="flex items-center space-x-1">
                    <span>{label}</span>
                    {sortConfig.key === key && (
                      <span className="text-gray-400">
                        {sortConfig.direction === "asc" ? "↑" : "↓"}
                      </span>
                    )}
                  </div>
                </th>
              ))}
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {localWorkspaces.map((workspace, index) => (
              <tr
                key={workspace.workspace_id}
                className="group hover:bg-gray-50 transition-colors duration-150"
              >
                <td className="px-6 py-4 w-10 shrink-0">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                    checked={selectedWorkspaces.has(workspace.workspace_id)}
                    onChange={() =>
                      handleSelectWorkspace(workspace.workspace_id)
                    }
                    disabled={
                      !ownedWorkspaceIds.includes(workspace.workspace_id)
                    }
                  />
                </td>
                <td className="px-6 py-4 w-80 shrink-0">
                  <div className="flex items-center min-w-0">
                    <span className="text-sm text-gray-900 truncate">
                      {workspace.name}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 min-w-[200px]">
                  <div className="whitespace-normal break-words">
                    {workspace.description}
                  </div>
                </td>
                <td className="px-6 py-4 w-60 shrink-0 whitespace-nowrap">
                  <div className="text-sm text-gray-500">{workspace.role}</div>
                </td>
                <td className="px-6 py-4 w-60 shrink-0 whitespace-nowrap">
                  <div className="text-sm text-gray-500">
                    {format(new Date(workspace.created_at), "MMM d, yyyy")}
                  </div>
                </td>
                <td className="px-6 py-4 w-60 shrink-0 whitespace-nowrap">
                  <div className="text-sm text-gray-500">
                    {format(new Date(workspace.updated_at), "MMM d, yyyy")}
                  </div>
                </td>
                <td className="px-6 py-4 w-5 shrink-0 whitespace-nowrap">
                  {workspace.role !== "owner" && (
                    <div
                      className="text-gray-500 cursor-pointer text-red-600 transition-colors duration-150 opacity-0 group-hover:opacity-100"
                      onClick={() => {
                        setWorkspaceToLeave(workspace);
                        setIsLeaveWorkspaceModalOpen(true);
                      }}
                    >
                      <ImExit className="h-5 w-5" />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkspaceList;
