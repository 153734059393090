import "./data.css";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { format } from "date-fns";
import {
  AiOutlineFile,
  AiOutlineStar,
  AiOutlineDownload,
  AiOutlineDelete,
  AiOutlineUpload,
} from "react-icons/ai";
import WorkspaceService from "../../../service/WorkspaceService";
import DocumentUploadModal from "../../document/document-upload-modal";
import useWorkspaceStore from "../../../stores/workspaceStore";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";

const productsTypesList = [
  { id: "8pgx12", name: "8PGX12" },
  { id: "1gdh13", name: "1GDH13" },
  { id: "761asf", name: "761ASF" },
];
const documentTypesList = [
  { id: "excel", name: "Excel" },
  { id: "doc", name: "Doc" },
  { id: "txt", name: "Text" },
];
const frozenFlowTypesList = [
  { id: "fecl", name: "FECL" },
  { id: "becl", name: "BECL" },
  { id: "recl", name: "RECL" },
  { id: "se", name: "SE" },
];
const segmentTypesList = [
  { id: "ndr", name: "NDR" },
  { id: "pdr", name: "PDR" },
  { id: "ply", name: "PLY" },
  { id: "nbm", name: "NBM" },
  { id: "tcn", name: "TCN" },
];
const flowTypesList = [
  { id: "por", name: "POR" },
  { id: "det", name: "DET" },
  { id: "pilot", name: "Pilot" },
  { id: "nonstd", name: "Non-Std" },
];
const mockFlows = [
  {
    id: 1,
    product: "8PGX12",
    document_type: "Excel",
    frozen_flow: "FECL",
    segment_start: "PDR",
    segment_end: "NBM",
    flow_type: "Pilot",
    comment: "Initial comment",
  },
  {
    id: 2,
    product: "1GDH13",
    document_type: "Doc",
    frozen_flow: "BECL",
    segment_start: "NDR",
    segment_end: "PLY",
    flow_type: "DET",
    comment: "Second comment",
  },
  {
    id: 3,
    product: "761ASF",
    document_type: "Text",
    frozen_flow: "RECL",
    segment_start: "PLY",
    segment_end: "TCN",
    flow_type: "Non-Std",
    comment: "Third comment",
  },
  {
    id: 4,
    product: "8PGX12",
    document_type: "Excel",
    frozen_flow: "SE",
    segment_start: "NBM",
    segment_end: "NDR",
    flow_type: "POR",
    comment: "Fourth comment",
  },
];

function DropdownWithAdd({
  title,
  options,
  setOptions,
  selected,
  onChange,
  allowAdd = true,
}) {
  const [inputValue, setInputValue] = useState(selected?.name || "");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const handleAddOption = () => {
    if (inputValue.trim()) {
      const newOption = { id: inputValue.toLowerCase(), name: inputValue };
      setOptions([...options, newOption]);
      onChange(newOption);
      setInputValue(newOption.name);
      setIsOpen(false);
    }
  };

  const handleOptionSelect = (option) => {
    onChange(option);
    setInputValue(option.name);
    setIsOpen(false);
  };

  // Only close if focus moves outside container
  const handleContainerBlur = (e) => {
    if (!containerRef.current.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  };

  return (
    <div ref={containerRef} className="relative" onBlur={handleContainerBlur}>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {title}
      </label>
      <input
        ref={inputRef}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={() => setIsOpen(true)}
        className="w-full border border-solid border-gray-300 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
        placeholder={`Search or add ${title.toLowerCase()}...`}
      />
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-solid border-gray-200 rounded-md shadow-lg max-h-60 overflow-y-auto">
          <div className="p-2">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <button
                  key={option.id}
                  onClick={() => handleOptionSelect(option)}
                  className="w-full text-left px-3 py-2 text-sm bg-white hover:bg-gray-100 rounded-md"
                >
                  {option.name}
                </button>
              ))
            ) : (
              <div className="px-3 py-2 text-sm text-gray-500">
                No options found
              </div>
            )}
            {allowAdd &&
              inputValue &&
              !filteredOptions.some((o) => o.name === inputValue) && (
                <div className="mt-2">
                  <button
                    onClick={handleAddOption}
                    className="w-full flex items-center px-3 py-2 text-sm text-left bg-blue-50 text-blue-500 rounded-md hover:bg-blue-100"
                  >
                    Add "{inputValue}"
                  </button>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
}

const Flows = () => {
  const { selectedWorkspace, getUserName } = useWorkspaceStore();

  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "modifiedAt",
    direction: "desc",
  });
  const PER_PAGE = 10;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [selectedDocs, setSelectedDocs] = useState(new Set());
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const fileInputRef = useRef(null);
  const [isTypesModalOpen, setIsTypesModalOpen] = useState(false);

  //   Types List
  const [products, setProducts] = useState(productsTypesList);
  const [documentTypes, setDocumentTypes] = useState(documentTypesList);
  const [frozenFlows, setFrozenFlows] = useState(frozenFlowTypesList);
  const [segments, setSegments] = useState(segmentTypesList);
  const [flowTypes, setFlowTypes] = useState(flowTypesList);

  // State for dropdown selections
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedFrozenFlow, setSelectedFrozenFlow] = useState(null);
  const [selectedBeginSegment, setSelectedBeginSegment] = useState(null);
  const [selectedEndSegment, setSelectedEndSegment] = useState(null);
  const [selectedFlowType, setSelectedFlowType] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      if (!selectedWorkspace?.workspace_id) return;

      try {
        setIsLoading(true);
        setError(null);
        const response = await WorkspaceService.documentsUsed(
          {
            page,
            per_page: PER_PAGE,
          },
          selectedWorkspace.workspace_id,
        );
        setDocuments(response.sources || []);
        setTotalPages(Math.ceil(response.pagination.total_pages));
        setTotalDocuments(response.pagination.total_count);
      } catch (error) {
        console.error("Error fetching documents:", error);
        setError("Failed to fetch documents. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocuments();
  }, [page, selectedWorkspace?.id]);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      setSelectedDocs(new Set()); // Clear selection on page change
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedDocs(new Set(sortedDocuments.map((doc) => doc.id)));
    } else {
      setSelectedDocs(new Set());
    }
  };

  const handleSelectDoc = (docId) => {
    const newSelected = new Set(selectedDocs);
    if (newSelected.has(docId)) {
      newSelected.delete(docId);
    } else {
      newSelected.add(docId);
    }
    setSelectedDocs(newSelected);
  };

  const handleDownloadSelected = async () => {
    try {
      const selectedDocuments = sortedDocuments.filter((doc) =>
        selectedDocs.has(doc.id),
      );

      // Download each selected document
      const downloads = selectedDocuments.map(async (doc) => {
        const response = await fetch(doc.location);
        const blob = await response.blob();

        // Create temporary link and trigger download
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = doc.name; // Use document name as filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      });

      // Wait for all downloads to complete
      await Promise.all(downloads);
    } catch (error) {
      console.error("Error downloading documents:", error);
      // You may want to show an error message to the user here
    }
  };

  const handleDeleteSelected = async () => {
    if (isDeleting) return;

    try {
      setIsDeleting(true);
      const selectedDocuments = sortedDocuments.filter((doc) =>
        selectedDocs.has(doc.id),
      );

      // Delete each selected document
      const deletions = selectedDocuments.map(async (source) => {
        await WorkspaceService.deleteWorkspaceSource(
          selectedWorkspace.workspace_id,
          source.id,
        );
      });

      await Promise.all(deletions);

      // Refresh documents list
      const response = await WorkspaceService.documentsUsed(
        {
          page,
          per_page: PER_PAGE,
        },
        selectedWorkspace.workspace_id,
      );
      setDocuments(response.sources || []);
      setTotalPages(Math.ceil(response.pagination.total_pages));
      setTotalDocuments(response.pagination.total_count);
      setSelectedDocs(new Set()); // Clear selection after delete
    } catch (error) {
      console.error("Error deleting documents:", error);
      setError("Failed to delete documents. Please try again later.");
    } finally {
      setIsDeleting(false);
    }
  };
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleUpload = () => {
    setIsUploadModalOpen(true);
  };

  const handleFileUpload = async (fileOrLinkPayload) => {
    try {
      setIsUploading(true);
      setError(null);

      // Track progress for multiple files
      let completedUploads = 0;
      const totalFiles = Array.isArray(fileOrLinkPayload)
        ? fileOrLinkPayload.length
        : 1;

      const uploadPromises = Array.isArray(fileOrLinkPayload)
        ? fileOrLinkPayload.map(async (file) => {
            try {
              await WorkspaceService.addWorkspaceSource(
                file,
                selectedWorkspace.workspace_id,
              );
              completedUploads++;
              // You could add a progress state here if desired
            } catch (error) {
              console.error(`Error uploading ${file.name}:`, error);
              throw error;
            }
          })
        : [
            WorkspaceService.addWorkspaceSource(
              fileOrLinkPayload,
              selectedWorkspace.workspace_id,
            ),
          ];

      await Promise.all(uploadPromises);

      // Refresh the documents list after all uploads complete
      const documentsResponse = await WorkspaceService.documentsUsed(
        {
          page,
          per_page: PER_PAGE,
        },
        selectedWorkspace.workspace_id,
      );

      setDocuments(documentsResponse.sources || []);
      setTotalPages(Math.ceil(documentsResponse.pagination.total_pages));
      setTotalDocuments(documentsResponse.pagination.total_count);
    } catch (error) {
      console.error("Error uploading documents:", error);
      setError(
        "Failed to upload one or more documents. Please try again later.",
      );
    } finally {
      setIsUploading(false);
    }
  };

  const sortedDocuments = [...documents].sort((a, b) => {
    if (sortConfig.key === "fetchInterval") {
      const intervalToMinutes = (interval) => {
        const [value, unit] = interval.split(" ");
        switch (unit) {
          case "minute":
          case "minutes":
            return parseInt(value);
          case "hour":
          case "hours":
            return parseInt(value) * 60;
          case "day":
          case "days":
            return parseInt(value) * 1440;
          case "month":
          case "months":
            return parseInt(value) * 43200; // Assuming 1 month = 30 days
          default:
            return 0;
        }
      };

      return sortConfig.direction === "asc"
        ? intervalToMinutes(a.fetchInterval) -
            intervalToMinutes(b.fetchInterval)
        : intervalToMinutes(b.fetchInterval) -
            intervalToMinutes(a.fetchInterval);
    }

    if (sortConfig.key === "size") {
      return sortConfig.direction === "asc" ? a.size - b.size : b.size - a.size;
    }

    return sortConfig.direction === "asc"
      ? a[sortConfig.key].localeCompare(b[sortConfig.key])
      : b[sortConfig.key].localeCompare(a[sortConfig.key]);
  });

  const renderSkeleton = () => (
    <div className="animate-pulse">
      <div className="space-y-3">
        {[...Array(PER_PAGE)].map((_, idx) => (
          <div key={idx} className="grid grid-cols-7 gap-4">
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
          </div>
        ))}
      </div>
    </div>
  );

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-red-500 text-center">
          <p>{error}</p>
          <button
            onClick={() => setPage(page)}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <DocumentUploadModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onUpload={handleFileUpload}
        isUploading={isUploading}
        showConnectToDb
      />
      <Transition appear show={isTypesModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={() => setIsTypesModalOpen(false)}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-3xl rounded-lg bg-white shadow-xl transform transition-all">
                  <div className="p-6 space-y-6">
                    <DialogTitle className="text-lg font-medium text-gray-900">
                      Configure Flow
                    </DialogTitle>

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                      <DropdownWithAdd
                        title="Product"
                        options={products}
                        setOptions={setProducts}
                        selected={selectedProduct}
                        onChange={setSelectedProduct}
                      />
                      <DropdownWithAdd
                        title="Document Type"
                        options={documentTypes}
                        setOptions={setDocumentTypes}
                        selected={selectedDocumentType}
                        onChange={setSelectedDocumentType}
                      />
                      <DropdownWithAdd
                        title="Frozen Flow"
                        options={frozenFlows}
                        setOptions={setFrozenFlows}
                        selected={selectedFrozenFlow}
                        onChange={setSelectedFrozenFlow}
                      />
                      <DropdownWithAdd
                        title="Begin Segment"
                        options={segments}
                        setOptions={setSegments}
                        selected={selectedBeginSegment}
                        onChange={setSelectedBeginSegment}
                      />
                      <DropdownWithAdd
                        title="End Segment"
                        options={segments}
                        setOptions={setSegments}
                        selected={selectedEndSegment}
                        onChange={setSelectedEndSegment}
                      />
                      <DropdownWithAdd
                        title="Flow Type"
                        options={flowTypes}
                        setOptions={setFlowTypes}
                        selected={selectedFlowType}
                        onChange={setSelectedFlowType}
                      />
                    </div>
                    <div className="flex justify-end space-x-4">
                      <button
                        onClick={() => setIsTypesModalOpen(false)}
                        className="px-3 py-1.5 border rounded text-sm font-medium hover:bg-gray-50 transition-colors"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => setIsUploadModalOpen(true)}
                        className="px-3 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className="bg-white h-full rounded-lg shadow flex flex-col overflow-auto">
        <div className="border-b px-6 py-4 flex items-center justify-between sticky top-0 bg-white z-20">
          <div className="flex items-center space-x-4">
            {selectedDocs.size > 0 && (
              <>
                <button
                  onClick={handleDownloadSelected}
                  className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <AiOutlineDownload className="mr-2 h-4 w-4" />
                  Download ({selectedDocs.size})
                </button>
                <button
                  onClick={handleDeleteSelected}
                  disabled={isDeleting}
                  className="inline-flex items-center px-3 py-1.5 border border-red-300 rounded-md text-sm font-medium text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <AiOutlineDelete className="mr-2 h-4 w-4" />
                  {isDeleting ? "Deleting..." : `Delete (${selectedDocs.size})`}
                </button>
              </>
            )}
          </div>
          <div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              className="hidden"
            />
            <button
              onClick={() => setIsTypesModalOpen(true)}
              disabled={isUploading}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <AiOutlineUpload className="mr-2 h-4 w-4" />
              {isUploading ? "Uploading..." : "Upload"}
            </button>
          </div>
        </div>

        <div className="overflow-x-auto flex-1">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 z-10">
              <tr>
                <th scope="col" className="px-6 py-3 text-left">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={
                      selectedDocs.size === sortedDocuments.length &&
                      sortedDocuments.length > 0
                    }
                    onChange={handleSelectAll}
                  />
                </th>
                {[
                  { key: "product", label: "Product" },
                  { key: "document_type", label: "Document Type" },
                  { key: "frozen_flow", label: "Frozen Flow" },
                  { key: "segment_start", label: "Segment Start" },
                  { key: "segment_end", label: "Segment End" },
                  { key: "flow_type", label: "Flow Type" },
                  { key: "comment", label: "Comment" },
                ].map(({ key, label }) => (
                  <th
                    key={key}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 select-none"
                    onClick={() => handleSort(key)}
                  >
                    <div className="flex items-center space-x-1">
                      <span>{label}</span>
                      {sortConfig.key === key && (
                        <span className="text-gray-400">
                          {sortConfig.direction === "asc" ? "↑" : "↓"}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {isLoading ? (
                <tr>
                  <td colSpan="7" className="px-6 py-4">
                    {renderSkeleton()}
                  </td>
                </tr>
              ) : (
                mockFlows.map((flow, index) => (
                  // sortedDocuments.map((doc, index) => (
                  <tr
                    key={index}
                    className={`group hover:bg-gray-50 transition-colors duration-150 ${
                      selectedDocs.has(flow.id) ? "bg-blue-50" : ""
                    }`}
                  >
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        checked={selectedDocs.has(flow.id)}
                        onChange={() => handleSelectDoc(flow.id)}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center min-w-0">
                          <span className="ml-2 text-sm text-gray-900 truncate">
                            {flow.product}
                          </span>
                        </div>
                        <a
                          href={flow.location}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ml-2 text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
                          title="Open in new tab"
                        >
                          <svg
                            className="h-4 w-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                          </svg>
                        </a>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {flow.document_type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {flow.frozen_flow}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {flow.segment_start}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {flow.segment_end}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {flow.flow_type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {flow.comment}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="px-6 py-3 border-t flex items-center justify-between bg-white sticky bottom-0">
          <div className="text-sm text-gray-700">
            Showing {(page - 1) * PER_PAGE + 1} to{" "}
            {Math.min(page * PER_PAGE, totalDocuments)} of {totalDocuments}{" "}
            documents
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1 || isLoading}
              className="px-3 py-1.5 border rounded text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages || isLoading}
              className="px-3 py-1.5 border rounded text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Flows;
