import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ClerkProviderWithCallbacks from "./utils/ClerkProviderWithCallbacks";

// Your Clerk publishable key
const CLERK_PUBLISHABLE_KEY = "pk_live_Y2xlcmsuM3JkYWlhdXRvbWF0aW9uLmNvbSQ";

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Clerk Publishable Key");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ClerkProviderWithCallbacks publishableKey={CLERK_PUBLISHABLE_KEY}>
    <App />
  </ClerkProviderWithCallbacks>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
