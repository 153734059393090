import React from "react";
import { format } from "date-fns";
import { FaCheck, FaTimes, FaClock } from "react-icons/fa";

const getInitials = (name) => {
  if (!name) return "??";
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  try {
    return format(new Date(dateString), "MMM d, yyyy h:mm a");
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString;
  }
};

const getStatusIcon = (status) => {
  switch (status) {
    case "approved":
      return <FaCheck className="text-green-500" />;
    case "rejected":
      return <FaTimes className="text-red-500" />;
    default:
      return <FaClock className="text-yellow-500" />;
  }
};

const getStatusClass = (status) => {
  switch (status) {
    case "approved":
      return "bg-green-100 text-green-800";
    case "rejected":
      return "bg-red-100 text-red-800";
    default:
      return "bg-yellow-100 text-yellow-800";
  }
};

const ApproversList = ({ approvers = [] }) => {
  if (!approvers || approvers.length === 0) {
    return (
      <div className="p-4 text-center text-gray-500">
        No approvers assigned to this report
      </div>
    );
  }

  return (
    <div className="w-full overflow-hidden bg-white rounded-md shadow-lg">
      <div className="px-4 py-3 bg-gray-50 border-b border-gray-200">
        <h3 className="text-sm font-medium text-gray-700">Report Approvers</h3>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Approver
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Comments
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {approvers.map((approver) => (
              <tr key={approver.user_id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    {approver.avatar ? (
                      <img
                        src={approver.avatar}
                        alt={approver.name || "User"}
                        className="h-8 w-8 rounded-full mr-3"
                      />
                    ) : (
                      <div className="h-8 w-8 rounded-full bg-gray-300 flex items-center justify-center text-xs font-bold mr-3">
                        {getInitials(approver?.user?.name)}
                      </div>
                    )}
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        {approver?.user?.name || `User ${approver?.user?.user_id}`}
                      </div>
                      <div className="text-[10px] text-gray-500">
                        Added: {formatDate(approver?.created_at)}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusClass(
                        approver.status
                      )}`}
                    >
                      {getStatusIcon(approver.status)}
                      <span className="ml-1 capitalize">{approver.status}</span>
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {approver.status === "approved" && approver.approved_at
                    ? formatDate(approver.approved_at)
                    : approver.status === "rejected" && approver.rejected_at
                    ? formatDate(approver.rejected_at)
                    : "Pending"}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 max-w-xs truncate">
                  {approver.status === "rejected" && approver.rejection_reason
                    ? approver.rejection_reason
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApproversList;
