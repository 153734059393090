import { Fragment, useState, useEffect } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { formatDate } from "../../util/dateUtils";
import { FaArchive } from "react-icons/fa";
import { DebugSummaryModal } from "./debug-summary-modal";

const ActivitySkeleton = () => (
  <tr className="animate-pulse">
    <td className="px-6 py-4 w-12">
      <div className="h-4 w-4 bg-gray-200 rounded"></div>
    </td>
    <td className="px-6 py-4">
      <div className="h-4 w-48 bg-gray-200 rounded"></div>
    </td>
    <td className="px-6 py-4">
      <div className="h-4 w-24 bg-gray-200 rounded"></div>
    </td>
    <td className="px-6 py-4">
      <div className="h-4 w-24 bg-gray-200 rounded"></div>
    </td>
    <td className="px-6 py-4">
      <div className="h-4 w-24 bg-gray-200 rounded"></div>
    </td>
  </tr>
);

export const DebugModal = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const rcaAlerts = [
    "FCCD distribution is possibily wider by 20% than optimal at M1 FCCD",
    "Etch failing, array is out of bound for lot impacting point of contact",
  ];

  const [isDebugSummaryModalOpen, setIsDebugSummaryModalOpen] = useState(false);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-6xl transform overflow-hidden rounded-xl bg-white p-6 shadow-xl transition-all">
                  <div className="flex justify-between items-center mb-6">
                    <DialogTitle
                      as="h3"
                      className="text-xl font-semibold text-gray-900"
                    >
                      Debugged Alerts
                    </DialogTitle>
                  </div>

                  <div className="overflow-x-auto flex-1">
                    <table className="w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50 sticky top-0 z-10">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left w-12">
                            <input
                              type="checkbox"
                              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                          </th>
                          {[
                            { key: "alert", label: "Alert" },
                            { key: "dispo_date", label: "Dispo Date" },
                            { key: "engineer", label: "Engineer" },
                            { key: "archive", label: "Archive" },
                          ].map(({ key, label }) => (
                            <th
                              key={key}
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 select-none"
                            >
                              <div className="flex items-center space-x-1">
                                <span>{label}</span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {isLoading ? (
                          <>
                            <ActivitySkeleton />
                            <ActivitySkeleton />
                            <ActivitySkeleton />
                          </>
                        ) : (
                          rcaAlerts.map((alert, index) => (
                            <tr
                              key={index}
                              className="group hover:bg-gray-50 transition-colors duration-150"
                            >
                              <td className="px-6 py-4 w-12 shrink-0">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                />
                              </td>
                              <td className="px-6 py-4 min-w-[300px]">
                                <div className="text-sm text-gray-900">
                                  {alert}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-500">
                                  {formatDate(
                                    new Date("Jan 16, 2025"),
                                    "MMM d, yyyy",
                                  )}
                                </div>
                              </td>
                              <td className="px-6 py-4">
                                <div className="text-sm text-gray-500">
                                  {"John Doe"}
                                </div>
                              </td>
                              <td className="px-6 py-4">
                                <button className="text-gray-500 hover:text-gray-500 transition-colors bg-white">
                                  <FaArchive className="h-5 w-5" />
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>

                    <div className="sticky bottom-0 bg-white border-t border-gray-200 px-6 py-4 flex justify-between items-center">
                      <div className="flex items-center space-x-4">
                        <span className="text-sm text-gray-600">
                          {/* {selectedAlerts.size} selected */}
                        </span>
                        <button
                          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm"
                          onClick={() => setIsDebugSummaryModalOpen(true)}
                        >
                          Open Summary
                        </button>
                      </div>

                      <div className="flex space-x-3">
                        <button
                          className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
                          onClick={onClose}
                        >
                          Close
                        </button>
                        <button className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm">
                          Save
                        </button>
                        <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm">
                          Share
                        </button>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Debug Summary Modal */}
      {isDebugSummaryModalOpen && (
        <DebugSummaryModal
          selectedAlert={isDebugSummaryModalOpen}
          onClose={() => setIsDebugSummaryModalOpen(false)}
        />
      )}
    </>
  );
};
