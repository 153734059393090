import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FaCheckCircle,
  FaEdit,
  FaPause,
  FaArchive,
  FaBell,
  FaSort,
  FaCircle,
} from "react-icons/fa";
import useProjectStore from "../../stores/projectStore";
import useReportStore from "../../stores/reportStore";
import { REPORT_STATUS_LABELS } from "../../utils/constants";

const Home = () => {
  const navigate = useNavigate();
  const [copySuccess, setCopySuccess] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });


  const location = useLocation();
  const [isHomeActive, setIsHomeActive] = useState(false);
  const { selectedProject } = useProjectStore();
  const {
    reports,
    isLoading,
    fetchReports,
    reportStatusCounts,
    fetchReportStatusCounts,
    isLoadingStatusCounts,
  } = useReportStore();

  const [showTokenButton, setShowTokenButton] = useState(false);

  useEffect(() => {
    setIsHomeActive(location.pathname === "/portal/home");
  }, [location.pathname]);

  useEffect(() => {
    if (isHomeActive && selectedProject?.project_id) {
      fetchReports(selectedProject.project_id);
      fetchReportStatusCounts(selectedProject.project_id);
    }
  }, [isHomeActive, selectedProject?.project_id]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setShowTokenButton(searchParams.get('showtoken') === 'true');
  }, [location.search]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedReports = [...reports].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "text-green-600";
      case "in_progress":
        return "text-blue-600";
      case "draft":
        return "text-gray-600";
      case "hold":
        return "text-yellow-600";
      default:
        return "text-gray-600";
    }
  };

  const getStatusText = (status) => {
    return status;
  };

  const StatsSkeleton = () => (
    <div className="animate-pulse bg-white rounded-lg shadow-sm border border-gray-200 border-solid p-4">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center space-x-2">
          <div className="w-8 h-8 bg-gray-200 rounded-lg"></div>
          <div className="h-4 w-20 bg-gray-200 rounded"></div>
        </div>
        <div className="h-8 w-12 bg-gray-200 rounded"></div>
      </div>
      <div className="h-3 w-32 bg-gray-200 rounded"></div>
    </div>
  );

  const TableRowSkeleton = () => (
    <tr className="animate-pulse">
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="h-4 w-32 bg-gray-200 rounded"></div>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="flex space-x-1">
          <div className="h-4 w-16 bg-gray-200 rounded-full"></div>
          <div className="h-4 w-16 bg-gray-200 rounded-full"></div>
        </div>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="h-4 w-24 bg-gray-200 rounded"></div>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="h-2 w-full bg-gray-200 rounded-full"></div>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="h-4 w-24 bg-gray-200 rounded"></div>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="h-4 w-24 bg-gray-200 rounded"></div>
      </td>
    </tr>
  );
  const handleCopyToken = async () => {
    try {
      const token = await window?.Clerk?.session?.getToken();
      if (!token) {
        setCopySuccess('Please sign in to generate token');
        return;
      }
      
      await navigator.clipboard.writeText(token);
      setCopySuccess('Token copied to clipboard!');
      
      setTimeout(() => {
        setCopySuccess('');
      }, 3000);
    } catch (error) {
      console.error('Failed to copy token:', error);
      setCopySuccess('Failed to copy token');
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        {isLoadingStatusCounts ? (
          <>
            <StatsSkeleton />
            <StatsSkeleton />
            <StatsSkeleton />
            <StatsSkeleton />
            {/* <StatsSkeleton /> */}
          </>
        ) : (
          [
            {
              label: "Action",
              value: reportStatusCounts?.action || 0,
              icon: <FaBell className="w-5 h-5 text-orange-600" />,
              description: "Action required",
              colors: "bg-gray-50 group-hover:bg-orange-100",
            },
            {
              label: "Completed",
              value: reportStatusCounts?.completed || 0,
              icon: <FaCheckCircle className="w-5 h-5 text-green-600" />,
              description: "Reports completed this month",
              colors: "bg-green-50 group-hover:bg-green-100",
            },
            {
              label: "Draft",
              value: reportStatusCounts?.draft || 0,
              icon: <FaEdit className="w-5 h-5 text-blue-600" />,
              description: "Reports in draft state",
              colors: "bg-blue-50 group-hover:bg-blue-100",
            },
            {
              label: "On Hold",
              value: reportStatusCounts?.hold || 0,
              icon: <FaPause className="w-5 h-5 text-yellow-600" />,
              description: "Reports currently on hold",
              colors: "bg-yellow-50 group-hover:bg-yellow-100",
            },
            // {
            //   label: "Archived",
            //   value: reportStatusCounts?.archived || 0,
            //   icon: <FaArchive className="w-5 h-5 text-gray-600" />,
            //   description: "Archived reports",
            //   colors: "bg-gray-50 group-hover:bg-gray-100",
            // },
          ].map((stat) => (
            <div
              key={stat.label}
              className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid p-4 hover:shadow-md transition-all duration-200 group cursor-default"
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  <div
                    className={`p-1.5 rounded-lg transition-colors duration-200 ${stat.colors}`}
                  >
                    {stat.icon}
                  </div>
                  <h3 className="text-base font-medium text-gray-900">
                    {stat.label}
                  </h3>
                </div>
                <span className="text-2xl font-semibold text-gray-900 tabular-nums">
                  {stat.value}
                </span>
              </div>
              <p className="text-xs text-gray-500">{stat.description}</p>
            </div>
          ))
        )}
      </div>

      {/* Reports Table */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
        <div className="px-4 py-3 border-b border-gray-200 flex justify-between items-center">
          <h3 className="text-base font-medium text-gray-900">Reports</h3>
          {showTokenButton && (
            <div>
              {copySuccess && (
                <span className="text-sm text-gray-600">{copySuccess}</span>
              )}
              <button
                onClick={handleCopyToken}
                className="ml-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
              >
                Copy Clerk Token
              </button>
            </div>
          )}
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {[
                "Report Name",
                "Tags",
                "Status",
                "Owner",
                "Created At",
              ].map((header, i) => (
                <th
                  key={header}
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() =>
                    handleSort(header.toLowerCase().replace(" ", ""))
                  }
                >
                  <div className="flex items-center space-x-1">
                    <span>{header}</span>
                    {/* {header !== "Tags" && <FaSort className="w-3 h-3" />} */}
                  </div>
                </th>
              ))}
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {isLoading ? (
                <>
                  <TableRowSkeleton />
                  <TableRowSkeleton />
                  <TableRowSkeleton />
                  <TableRowSkeleton />
                </>
              ) : reports.length === 0 ? (
                <tr>
                  <td
                    colSpan="6"
                    className="px-4 py-3 text-center text-gray-500"
                  >
                    No reports found
                  </td>
                </tr>
              ) : (
                reports.map((report) => (
                  <tr
                    key={report.report_id}
                    className="hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                    onClick={() =>
                      navigate(`/portal/reports/${report.report_id}`)
                    }
                  >
                    <td className="px-4 py-3 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 hover:underline">
                        {report.name}
                      </div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      <div className="flex space-x-1">
                        {report.tags?.map((tag, i) => (
                          <span
                            key={i}
                            className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium"
                            style={{
                              backgroundColor: `${tag.color}20`,
                              color: tag.color
                            }}
                          >
                            <div
                              className="w-2 h-2 rounded-full mr-1"
                              style={{ backgroundColor: tag.color }}
                            />
                            {tag.name || tag}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      <div
                        className={`inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium ${REPORT_STATUS_LABELS[report.status || "draft"].color
                          } transition-colors duration-150 hover:bg-opacity-80`}
                      >
                        <FaCircle className="w-2 h-2 mr-1.5" />
                        <span>
                          {REPORT_STATUS_LABELS[report.status || "draft"].text}
                        </span>
                      </div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      <div className="text-sm text-gray-900 hover:text-blue-600 transition-colors duration-150">
                        {report.email}
                      </div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(report.createdAt)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Home;
