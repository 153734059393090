import React from "react";
import { ClerkProvider } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import ActivityService from "../service/ActivityService";

/**
 * Custom ClerkProvider component with callbacks for authentication events
 */
const ClerkProviderWithCallbacks = ({ children, publishableKey }) => {
  // We can't use useNavigate here because it's outside of Router context
  // We'll use window.location for navigation instead

  const logActivity = async (action, userId, email) => {
    try {
      const activityLog = {
        email_id: email,
        action: action,
      };
      await ActivityService.addActivityLog(activityLog, userId);
    } catch (error) {
      console.error("Failed to log activity:", error);
    }
  };

  return (
    <ClerkProvider
      publishableKey={publishableKey}
      navigate={(to) => (window.location.href = to)}
      appearance={{
        elements: {
          rootBox: {
            boxShadow: "none",
          },
          card: {
            boxShadow: "none",
            border: "1px solid #e5e7eb",
          },
        },
      }}
      signInUrl="/sign-in"
      signUpUrl="/sign-up"
      afterSignInUrl="/portal/home"
      afterSignUpUrl="/portal/home"
      organizationSettings={{
        organizationProfileUrl: "/portal/organization",
        createOrganizationUrl: "/portal/create-organization",
        afterCreateOrganizationUrl: "/portal/home",
        afterSelectOrganizationUrl: "/portal/home",
      }}
      onSignIn={(session) => {
        // Log the sign-in activity
        const user = session.user;
        if (user) {
          logActivity(
            "User signed in with Clerk",
            user.id,
            user.primaryEmailAddress?.emailAddress
          );
        }
      }}
      onSignUp={(session) => {
        // Log the sign-up activity
        const user = session.user;
        if (user) {
          logActivity(
            "User signed up with Clerk",
            user.id,
            user.primaryEmailAddress?.emailAddress
          );
        }
      }}
    >
      {children}
    </ClerkProvider>
  );
};

export default ClerkProviderWithCallbacks;
