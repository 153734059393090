import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Toolbar from "../../components/toolbar/toolbar";
import Menubar from "../../components/menubar/menubar";
import Home from "../../components/home/home";
import Model from "../../components/model/model";
import Logout from "../../components/logout/logout";
import FileDetail from "../../components/file/fileDetail";
import Result from "../../components/result/result";
import Footer from "../../components/footer/footer";
import UserService from "../../service/UserService";
import { useUser } from "../../context/userContext";
import Users from "../../components/users/users";
import Report from "../../components/report/report";
import Chat from "../../components/chat/chat";
import "./portal.css";
import Run from "../../components/run/run";
import JobResult from "../../components/run/jobResult/job-result";
import Reports from "../../components/reports/reports";
import WorkspaceUsers from "../../components/users/workspace-users";
import Navbar from "../../components/navbar";
import WorkspaceList from "../../components/workspaces/list";
import Actions from "../../components/actions/Actions";
import { Knowledge } from "../../components/knowledge/knowledge";
import RCA from "../../components/rca/rca";
import RcaAdvanced from "../../components/rca/rca-advanced";
import RcaDeveloper from "../../components/rca/rca-developer";
import TermsOfUse from "../../components/terms-of-use";
import PrivacyPolicy from "../../components/privacy-policy";
import { GlobalTooltip } from "../../components/tooltip";
import Data from "../../components/data/data";
import GetPremiumFeatures from "../../components/PremiumFeatures";
const Portal = () => {
  const { user } = useUser();
  const renderComponentWithRoleCheck = (component, requiredRoles, checkForPremium = false) => {
    const userRole = user?.organization?.role?.toUpperCase() || "";
    const normalizedRequiredRoles = requiredRoles.map((role) =>
      role.toUpperCase()
    );
    if (checkForPremium && !user.isRCAEnabled) {
      return <GetPremiumFeatures />;
    }
    if (userRole && normalizedRequiredRoles.some((role) => userRole === role)) {
      return component;
    }
  };

  return (
    <div className="flex flex-col bg-accent h-screen">
      <GlobalTooltip />
      {/* <Toolbar /> */}
      <div className="flex h-full flex-1">
        <Menubar />
        <div className="flex-1 m-3 bg-white border shadow-md ml-0 rounded-xl z-1 overflow-y-auto flex flex-col">
          <Navbar />
          <Routes>
            <Route path="/" element={<Navigate to="/portal/home" replace />} />
            <Route
              path="/home"
              element={renderComponentWithRoleCheck(<Home />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/premium-features"
              element={renderComponentWithRoleCheck(<GetPremiumFeatures />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/data"
              element={renderComponentWithRoleCheck(<Data />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/knowledge"
              element={renderComponentWithRoleCheck(<Knowledge />, [
                       "org:member",
                "org:admin",
              ],
              true)}
            />
            <Route
              path="/documents"
              element={renderComponentWithRoleCheck(<Knowledge />, [
                       "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/flows"
              element={renderComponentWithRoleCheck(<Knowledge />, [
                       "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/integration"
              element={renderComponentWithRoleCheck(<Knowledge />, [
                       "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/model"
              element={renderComponentWithRoleCheck(<Model />, [
                "org:admin",
                "org:member",
              ])}
            />
            <Route
              path="/result"
              element={renderComponentWithRoleCheck(<Result />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/run"
              element={renderComponentWithRoleCheck(<Run />, [
                "org:admin",
                "org:member",
              ])}
            />
            <Route
              path="/run/result"
              element={renderComponentWithRoleCheck(<JobResult />, [
                "org:admin",
                "org:member",
              ])}
            />
            <Route
              path="/members"
              element={renderComponentWithRoleCheck(<WorkspaceUsers />, [
                "ADMIN",
                "org:admin",
              ])}
            />
            <Route
              path="/users"
              element={renderComponentWithRoleCheck(<Users />, [
                "ADMIN",
                "org:admin",
              ])}
            />
            <Route
              path="/terms-of-use"
              element={renderComponentWithRoleCheck(<TermsOfUse />, [
                "ADMIN",
                "org:admin",
              ])}
            />
            <Route
              path="/privacy-policy"
              element={renderComponentWithRoleCheck(<PrivacyPolicy />, [
                "ADMIN",
                "org:admin",
              ])}
            />
            <Route
              path="/file/:fileName"
              element={renderComponentWithRoleCheck(<FileDetail />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/report"
              element={renderComponentWithRoleCheck(<Report />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/chat"
              element={renderComponentWithRoleCheck(<Chat />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/chat/:chatId"
              element={renderComponentWithRoleCheck(<Chat />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/reports"
              element={renderComponentWithRoleCheck(<Reports />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/workspaces"
              element={renderComponentWithRoleCheck(<WorkspaceList />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/actions"
              element={renderComponentWithRoleCheck(<Actions />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/knowledge-base"
              element={renderComponentWithRoleCheck(<Reports />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/documents-used"
              element={renderComponentWithRoleCheck(<Reports />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/reports/:reportId"
              element={renderComponentWithRoleCheck(<Reports />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/reports/:reportId/settings"
              element={renderComponentWithRoleCheck(<Reports />, [
                "org:member",
                "org:admin",
              ])}
            />
            <Route
              path="/rca"
              element={renderComponentWithRoleCheck(<RCA />, [
                "org:member",
                "org:admin",
              ],
              true)}
            />
            <Route
              path="/rca-advanced"
              element={renderComponentWithRoleCheck(<RcaAdvanced />, [
                "org:member",
                "org:admin",
              ],
              true)}
            />
            <Route
              path="/rca-developer"
              element={renderComponentWithRoleCheck(<RcaDeveloper />, [
                "org:member",
                "org:admin",
              ],
              true)}
            />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Portal;
