import { CHATBOT_API_BASE_URL } from "../utils/config";
import { createAuthenticatedApiClient } from "../utils/clerk-auth";

const CHAT_API_BASE_URL = `${CHATBOT_API_BASE_URL}/chat`;
const apiClient = createAuthenticatedApiClient();

const ChatService = {
  getAnswer: async (payload) => {
    try {
      const response = await apiClient.post(CHAT_API_BASE_URL, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ChatService;
