import { create } from "zustand";
import ReportService from "../service/ReportService";
import useProjectStore from "./projectStore";
import { ERROR_MESSAGES } from "../utils/constants/errorConstants";

const useReportStore = create((set, get) => ({
  // State
  reports: [],
  selectedReport: null,
  isLoading: false,
  error: null,
  isCreatingReport: false,
  reportStatusCounts: null,
  isLoadingStatusCounts: false,
  isStartingQuestionnaire: false,
  isLoadingApprovers: false,
  isUpdatingApprover: false,

  // Actions
  fetchReports: async (projectId) => {
    set({ isLoading: true, error: null });
    try {
      const fetchedReports = await ReportService.getProjectReports(projectId);
      const parsedReports = fetchedReports.map((report) => ({
        report_id: report.report_id,
        name: report.name,
        email: report.email,
        title: report.title,
        isPinned: report.is_pinned,
        archived: report.archived,
        status: report.status,
        logo: report.logo,
        tags: report.tags || [],
        template_id: report.template_id,
        completed: report.completed,
        createdAt: report.created_at,
        updatedAt: report.updated_at,
        questions: report.questions || [],
        metadata: report.metadata || {},
      }));
      set({ reports: parsedReports });
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to fetch reports:", error);
    } finally {
      set({ isLoading: false });
    }
  },

  addQuestionLabel: async ({
    report_id,
    question_id,
    label_id,
    created_by,
  }) => {
    set({ isLoading: true, error: null });
    try {
      const response = await ReportService.addQuestionLabel({
        report_id,
        question_id,
        label_id,
        created_by,
      });
      return response;
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to add question label:", error);
    } finally {
      set({ isLoading: false });
    }
  },

  deleteQuestionLabel: async ({ report_id, question_id, label_id }) => {
    set({ isLoading: true, error: null });
    try {
      const response = await ReportService.deleteQuestionLabel({
        report_id,
        question_id,
        label_id,
      });
      return response;
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to delete question label:", error);
    } finally {
      set({ isLoading: false });
    }
  },

  fetchReportStatusCounts: async (projectId) => {
    set({ isLoadingStatusCounts: true });
    try {
      const statusCounts = await ReportService.getProjectReportsCountByStatus(
        projectId
      );
      set({ reportStatusCounts: statusCounts });
    } catch (error) {
      console.error("Failed to fetch report status counts:", error);
      set({ error: error.message });
    } finally {
      set({ isLoadingStatusCounts: false });
    }
  },

  setSelectedReport: (report) => {
    set({ selectedReport: report });
  },

  createReport: async () => {
    set({ isCreatingReport: true, error: null });
    try {
      const reports = get().reports;
      const projectStore = useProjectStore.getState();
      const projectId = projectStore.selectedProject?.project_id;

      if (!projectId) {
        throw new Error("No project selected");
      }
      // Check if report limit is reached for free version
      if (reports.length >= 5) {
        throw new Error(ERROR_MESSAGES.REPORT_LIMIT_REACHED.message);
      }

      // Generate unique report name
      let counter = reports.length + 1;
      let reportName = `Report${counter}`;
      let retryCount = 0;
      const maxRetries = 100;
      while (reports.some(report => report?.name === reportName)) {
        counter++;
        reportName = `Report${counter}`;
        retryCount++;
        if (retryCount >= maxRetries) {
          throw new Error("Failed to generate unique report name");
        }
      }
      
      const newReportData = {
        name: reportName,
        title: `Report title`,
        is_pinned: false,
        project_id: projectId,
        status: "draft",
        report_id: Math.floor(Math.random() * 1000000),
      };
      const createdReport = await ReportService.createReport(newReportData);
      const newReport = {
        report_id: createdReport.report_id,
        name: createdReport.name,
        title: createdReport.title,
        createdAt: createdReport.created_at,
        updatedAt: createdReport.timestamp,
        status: createdReport.status,
        questions: [],
      };
      set((state) => ({
        reports: [...state.reports, newReport],
        selectedReport: newReport,
      }));
      return newReport;
    } catch (error) {
      set({ error: error.message });
      if (error.message === ERROR_MESSAGES.REPORT_LIMIT_REACHED.message) {
        alert(ERROR_MESSAGES.REPORT_LIMIT_REACHED.message);
      } else {
        alert("Failed to create report");
      }
      console.error("Failed to create report:", error);
    } finally {
      set({ isCreatingReport: false });
    }
  },

  startReportWithQuestionnaire: async (reportId, file) => {
    set({ isStartingQuestionnaire: true, error: null });
    try {
      const response = await ReportService.startReportWithQuestionnaire(
        reportId,
        file
      );

      if (response.acknowledgement !== "success") {
        throw new Error("Failed to start questionnaire");
      }

      const fileDetails = {
        fileName: response.file_details.name,
        fileUrl: response.file_details.url,
        uploadedAt: response.file_details.uploaded_at,
      };

      set((state) => ({
        reports: state.reports.map((report) =>
          report.report_id === reportId ? { ...report, fileDetails } : report
        ),
        selectedReport:
          state.selectedReport?.report_id === reportId
            ? { ...state.selectedReport, fileDetails }
            : state.selectedReport,
      }));

      return fileDetails;
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to start questionnaire:", error);
      throw error;
    } finally {
      set({ isStartingQuestionnaire: false });
    }
  },

  updateReports: (updatedReports) => {
    set({ reports: updatedReports });
  },

  /**
   * Fetch approvers for a specific report
   * @param {string} reportId - The ID of the report
   */
  fetchReportApprovers: async (reportId) => {
    set({ isLoadingApprovers: true, error: null });
    try {
      const response = await ReportService.getReportApprovers(reportId);

      // Update the report with approvers
      const { reports } = get();
      const updatedReports = reports.map((report) => {
        if (report.report_id == reportId) {
          return {
            ...report,
            approvers: response.approvers || [],
          };
        }
        return report;
      });

      set({
        reports: updatedReports,
        isLoadingApprovers: false,
      });

      return response.approvers || [];
    } catch (error) {
      console.error("Error fetching report approvers:", error);
      set({
        error: error.response?.data?.message || ERROR_MESSAGES.GENERIC_ERROR,
        isLoadingApprovers: false,
      });
      throw error;
    }
  },

  /**
   * Add a new approver to a report
   * @param {string} reportId - The ID of the report
   * @param {string} userId - The ID of the user to add as approver
   * @param {string} status - Initial status of the approval (default: 'pending')
   */
  addReportApprover: async (reportId, userId, status = "pending") => {
    set({ isUpdatingApprover: true, error: null });
    try {
      const response = await ReportService.addReportApprover(
        reportId,
        userId,
        status
      );

      // Refresh approvers after adding
      await get().fetchReportApprovers(reportId);

      set({ isUpdatingApprover: false });
      return response;
    } catch (error) {
      console.error("Error adding report approver:", error);
      set({
        error: error.response?.data?.message || ERROR_MESSAGES.GENERIC_ERROR,
        isUpdatingApprover: false,
      });
      throw error;
    }
  },

  /**
   * Remove approver from a report
   * @param {string} reportId - The ID of the report
   * @param {string} userId - The ID of the user to add as approver
   */
  removeReportApprover: async (reportId, userId) => {
    set({ isUpdatingApprover: true, error: null });
    try {
      const response = await ReportService.removeReportApprover(
        reportId,
        userId,
      );

      await get().fetchReportApprovers(reportId);

      set({ isUpdatingApprover: false });
      return response;
    } catch (error) {
      console.error("Error adding report approver:", error);
      set({
        error: error.response?.data?.message || ERROR_MESSAGES.GENERIC_ERROR,
        isUpdatingApprover: false,
      });
      throw error;
    }
  },

  /**
   * Remove all approver from a report
   * @param {string} reportId - The ID of the report
   */
  removeAllReportApprover: async (reportId) => {
    set({ isUpdatingApprover: true, error: null });
    try {
      const response = await ReportService.removeAllReportApprover(
        reportId,
      );

      await get().fetchReportApprovers(reportId);

      set({ isUpdatingApprover: false });
      return response;
    } catch (error) {
      console.error("Error adding report approver:", error);
      set({
        error: error.response?.data?.message || ERROR_MESSAGES.GENERIC_ERROR,
        isUpdatingApprover: false,
      });
      throw error;
    }
  },

  /**
   * Update an existing report approver's status
   * @param {string} reportId - The ID of the report
   * @param {string} userId - The ID of the user/approver
   * @param {string} status - New status of the approval ('approved', 'rejected', 'pending')
   * @param {string} rejectionReason - Reason for rejection if status is 'rejected'
   */
  updateReportApprover: async (
    reportId,
    userId,
    status,
    rejectionReason = null
  ) => {
    set({ isUpdatingApprover: true, error: null });
    try {
      const response = await ReportService.updateReportApprover(
        reportId,
        userId,
        status,
        rejectionReason
      );

      // Refresh approvers after updating
      await get().fetchReportApprovers(reportId);

      set({ isUpdatingApprover: false });
      return response;
    } catch (error) {
      console.error("Error updating report approver:", error);
      set({
        error: error.response?.data?.message || ERROR_MESSAGES.GENERIC_ERROR,
        isUpdatingApprover: false,
      });
      throw error;
    }
  },

  // Reset store
  reset: () => {
    set({
      reports: [],
      selectedReport: null,
      isLoading: false,
      error: null,
      reportStatusCounts: null,
      isLoadingStatusCounts: false,
      isStartingQuestionnaire: false,
      isLoadingApprovers: false,
      isUpdatingApprover: false,
    });
  },
}));

export default useReportStore;
