import axios from "axios";
import { WORKSPACE_API_BASE_URL } from "../utils/config";
import { createAuthenticatedApiClient } from "../utils/clerk-auth";

// Create an authenticated API client using Clerk
const apiClient = createAuthenticatedApiClient();

const NotificationService = {
  createNotification: async (notificationData) => {
    try {
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/notification`,
        notificationData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getNotifications: async (workspaceId) => {
    try {
      const response = await apiClient.get(
        `${WORKSPACE_API_BASE_URL}/notifications`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  markNotificationRead: async (notificationId) => {
    try {
      const response = await apiClient.put(
        `${WORKSPACE_API_BASE_URL}/notification/${notificationId}/read`,
        {}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  markNotificationArchived: async (notificationId) => {
    try {
      const response = await apiClient.put(
        `${WORKSPACE_API_BASE_URL}/notification/${notificationId}/archive`,
        {}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  markAllNotificationsRead: async (workspaceId) => {
    try {
      const response = await apiClient.put(
        `${WORKSPACE_API_BASE_URL}/notifications/read`,
        {}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default NotificationService;
