import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useNavigate } from "react-router-dom";
import "./tabLayout.css";

const KnowledgeTabLayout = ({ children, activeIndex, setActiveIndex }) => {
  const navigate = useNavigate();

  const handleTabChange = (e) => {
    setActiveIndex(e.index);
    if (e.index === 0) {
      navigate("/portal/knowledge");
    } else if (e.index === 1) {
      navigate("/portal/documents");
    } else if (e.index === 2) {
      navigate("/portal/flows");
    } else if (e.index === 3) {
      navigate("/portal/integration");
    }
  };

  return (
    <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
      <TabPanel header="Data">{children}</TabPanel>
      <TabPanel header="Documents">{children}</TabPanel>
      <TabPanel header="Flows">{children}</TabPanel>
      <TabPanel header="Integration">{children}</TabPanel>
    </TabView>
  );
};

export default KnowledgeTabLayout;
