import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { formatDate, formatLastUpdated } from "../util/dateUtils";
import ReportService from "../../service/ReportService";
import VisualizationComponent from "./visualization-component";
const ReportPreview = ({
  reportId: propReportId,
  isPdfExport = false,
  onReportLoaded,
  onError,
}) => {
  const params = useParams();
  const reportId = propReportId || params?.reportId;
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReport = async () => {
      setIsLoading(true);
      try {
        const reportData = await ReportService.getReportById(reportId);
        setReport(reportData);
        if (isPdfExport) {
          console.log("Report data loaded for PDF export:", reportData);
        }
        onReportLoaded?.();
      } catch (error) {
        console.error("Error fetching report:", error);
        onError?.(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (reportId) {
      fetchReport();
    }
  }, [reportId, onReportLoaded, onError, isPdfExport]);

  // For PDF export, wait until content is loaded
  if (isPdfExport && (isLoading || !report)) {
    return null;
  }

  // Skip loading state for PDF export

  if (isLoading && !isPdfExport) {
    return (
      <div className="w-full max-w-4xl mx-auto p-6 bg-white h-full">
        <div className="mb-8 animate-pulse">
          <div className="h-12 w-3/4 bg-gray-200 rounded mb-2"></div>
          <div className="flex items-center text-sm text-gray-500">
            <div className="h-4 w-32 bg-gray-200 rounded mr-2"></div>
            <span>•</span>
            <div className="h-4 w-40 bg-gray-200 rounded ml-2"></div>
          </div>
        </div>
        {[1, 2, 3].map((i) => (
          <div key={i} className="mb-6 animate-pulse">
            <div className="h-24 bg-gray-200 rounded mb-4"></div>
            <div className="h-16 rounded"></div>
          </div>
        ))}
      </div>
    );
  }

  if (!report) {
    return (
      <div className="w-full max-w-4xl mx-auto p-6 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">Report not found or failed to load.</p>
      </div>
    );
  }

  const containerClassName = `w-full max-w-4xl mx-auto p-6 bg-white ${
    isPdfExport ? "pdf-export" : "h-full overflow-y-auto"
  }`;

  return (
    <div className={containerClassName}>
      {report.template_id === "template1" ? (
        // Template One - Basic Report
        <div className="mb-8">
          {report.logo && (
            <img
              src={report.logo}
              alt="Report Logo"
              className="h-16 w-16 object-contain mb-4"
            />
          )}
          <h1 className="font-bold text-4xl mb-2">{report.title}</h1>
          <div className="flex items-center text-sm text-gray-500">
            <span className="mr-2">
              Created: {formatDate(report.created_at)}
            </span>
            <span>•</span>
            <span className="ml-2">
              Last updated: {formatLastUpdated(report.updated_at)}
            </span>
          </div>
        </div>
      ) : report.template_id === "template2" ? (
        // Template Two - Legal Document
        <div className="text-center p-8 mb-8">
          <div className="mb-16 space-y-6">
            <div className="uppercase font-bold text-lg">
              BEFORE THE{" "}
              {report.metadata?.commission || "PUBLIC UTILITIES COMMISSION"} OF
              THE
            </div>
            <div className="uppercase font-bold text-lg">
              {report.metadata?.jurisdiction || "STATE OF CALIFORNIA"}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-8 mb-16">
            <div className="text-left border-l-2 border-black pl-4">
              {report.metadata?.order_title}
            </div>
            <div className="text-right">{report.metadata?.rulemaking_id}</div>
          </div>

          <div className="mb-20">
            <div className="uppercase font-bold text-center whitespace-pre-line">
              {report.metadata?.motion_title}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-16 text-left mb-16">
            {report.metadata?.attorneys?.map((attorney, index) => (
              <div key={index} className="space-y-2">
                <div className="font-bold">{attorney.name}</div>
                <div>{attorney.firm}</div>
                <div className="whitespace-pre-line">{attorney.address}</div>
                <div>Telephone: {attorney.phone}</div>
                <div>Email: {attorney.email}</div>
                <div className="whitespace-pre-line">{attorney.title}</div>
              </div>
            ))}
          </div>

          <div className="text-left">
            <div>Dated: {formatDate(report.created_at, "MMMM D, YYYY")}</div>
          </div>
        </div>
      ) : report.template_id === "template3" ? (
        <div className="w-full">
          {/* Logo Section */}
          {report.logo && (
            <div className="mb-8 flex justify-start">
              <img
                src={report.logo}
                alt="Report Logo"
                className="h-16 object-contain"
              />
            </div>
          )}

          {/* Report Table */}
          <table className="w-full border-collapse">
            <tbody className="[&>tr>td]:border [&>tr>td]:border-solid [&>tr>td]:border-gray-300 [&>tr>td]:p-2">
              <tr>
                <td className="font-bold w-[200px] align-top">Section</td>
                <td className="font-bold">Details</td>
              </tr>
              <tr>
                <td className="w-[200px] align-top">Report Title</td>
                <td>{report.title || "Specific Project/Topic Title"}</td>
              </tr>
              <tr>
                <td className="w-[200px] align-top">Date</td>
                <td>{formatDate(report.created_at, "DD/MM/YYYY")}</td>
              </tr>
              <tr>
                <td className="w-[200px] align-top">Prepared by</td>
                <td>{report.metadata?.prepared_by}</td>
              </tr>
              <tr>
                <td className="w-[200px] align-top">Overview</td>
                <td>
                  <strong>Purpose of Report: </strong>
                  {report.metadata?.overview?.purpose}
                </td>
              </tr>
              <tr>
                <td className="w-[200px] align-top">
                  Project Scope and Objectives
                </td>
                <td>
                  <div>
                    <strong>Scope: </strong>
                    {report.metadata?.project_scope?.scope}
                  </div>
                  <div className="mt-1">
                    <strong>Objectives: </strong>
                    {report.metadata?.project_scope?.objectives}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="w-[200px] align-top">Q&A Compliance Section</td>
                <td>
                  <div className="space-y-2">
                    {report.metadata?.complianceQuestions?.map((q) => {
                      const mappedQuestion = report.questions.find(
                        (question) =>
                          question.question_id ===
                          report.metadata?.cellMappings?.[`compliance-${q.id}`]
                      );

                      if (mappedQuestion) {
                        return (
                          <div key={q.id} className="p-2">
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: mappedQuestion.question,
                              }}
                            />
                            <div
                              className="mt-2"
                              dangerouslySetInnerHTML={{
                                __html: mappedQuestion.answer?.text || "",
                              }}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="w-[200px] align-top">
                  Analysis and Recommendations
                </td>
                <td>
                  <div>
                    <strong>Findings: </strong>
                    {report.metadata?.analysis?.findings}
                  </div>
                  <div className="mt-1">
                    <strong>Recommendations for Compliance: </strong>
                    {report.metadata?.analysis?.recommendations}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="w-[200px] align-top">Appendices</td>
                <td>
                  <div>
                    <strong>Supporting Documentation: </strong>
                    {report.metadata?.appendices?.documentation}
                  </div>
                  <div className="mt-1">
                    <strong>Contact Information: </strong>
                    {report.metadata?.appendices?.contact}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="text-center text-white p-8 mb-8">
            <div className="grid grid-cols-2 gap-8">
              <div className="text-left border-l-2 border-black pl-4">
                {report.metadata?.order_title}
              </div>
              <div className="text-right">{report.metadata?.rulemaking_id}</div>
            </div>

            <div className="mb-20">
              <div className="uppercase font-bold text-center whitespace-pre-line">
                {report.metadata?.motion_title}
              </div>
            </div>

            <div className="text-left">
              <div>Dated: {formatDate(report.created_at, "MMMM D, YYYY")}</div>
            </div>
          </div>
        </div>
      ) : (
        // Default template
        <div className="space-y-4 mb-8">
          {report.logo && (
            <img
              src={report.logo}
              alt="Report Logo"
              className="h-16 w-16 object-contain"
            />
          )}
          <h1 className="text-4xl font-bold">{report.title}</h1>
          <div className="text-sm text-gray-500">
            Created: {formatDate(report.created_at)} • Last updated:{" "}
            {formatLastUpdated(report.updated_at)}
          </div>
        </div>
      )}

      {!report.template_id?.includes("template3") &&
        [...report.questions]
          .sort((a, b) => a.position - b.position)
          .map((question) => (
            <div key={question.id} className="mb-6">
              {question.question?.includes("<") &&
              question.question?.includes(">") ? (
                <h3
                  className="text-lg text-gray-900 mb-2"
                  dangerouslySetInnerHTML={{ __html: question.question }}
                />
              ) : (
                <h3 className="text-lg text-gray-900 mb-2">
                  {question.question}
                </h3>
              )}

              {question.answer && (
                <div className="mt-2">
                  {question.answer.text?.includes("<") &&
                  question.answer.text?.includes(">") ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: question.answer.text }}
                      className="prose max-w-none"
                    />
                  ) : (
                    <div className="prose max-w-none">
                      <ReactMarkdown>{question.answer.text}</ReactMarkdown>
                    </div>
                  )}
                </div>
              )}

              {question.visualization && (
                <div className="mt-4">
                  <VisualizationComponent
                    visualization={question.visualization}
                    isLoading={false}
                  />
                </div>
              )}
            </div>
          ))}

      {report.questions.length === 0 && (
        <div className="text-center py-8">
          <p className="text-gray-500">This report has no questions yet.</p>
        </div>
      )}
    </div>
  );
};

export default ReportPreview;
