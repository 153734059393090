import React from "react";
import { AiFillPlusSquare } from "react-icons/ai";

const TemplateOne = ({
  report,
  showLogoUpload,
  setShowLogoUpload,
  isUploadingLogo,
  handleLogoUpload,
  handleTitleChange,
}) => {
  return (
    <>
      <div
        className="relative mb-4 group"
        onMouseEnter={() => setShowLogoUpload(true)}
        onMouseLeave={() => setShowLogoUpload(false)}
      >
        {report.logo ? (
          <img
            src={report.logo}
            alt="Report Logo"
            className="h-16 w-16 object-contain cursor-pointer hover:opacity-75 transition-opacity"
            onClick={() => document.getElementById("logo-upload").click()}
          />
        ) : (
          <button
            className="p-2 object-contain bg-gray-100 rounded-lg flex items-center justify-center cursor-pointer transition-colors"
            onClick={() => document.getElementById("logo-upload").click()}
          >
            <AiFillPlusSquare className="text-gray-400 text-xl mr-2" />
            <span className="text-gray-400">Add icon</span>
          </button>
        )}
        <input
          type="file"
          id="logo-upload"
          className="hidden"
          accept="image/*"
          onChange={(e) => handleLogoUpload(e.target.files[0])}
          disabled={isUploadingLogo}
        />
        {showLogoUpload && (
          <div className="absolute left-0 -bottom-6 text-xs text-gray-500">
            {isUploadingLogo ? "Uploading..." : "Click to add logo"}
          </div>
        )}
      </div>
      <div className="relative mb-4">
        <input
          type="text"
          value={report.title}
          onChange={(e) => handleTitleChange(e.target.value)}
          className="font-bold text-4xl bg-transparent border-none focus:outline-none w-full mb-2"
          placeholder="Untitled"
        />
        <span className="bg-blue-200 rounded-full py-0.5 px-4 text-sm text-gray-600">
          Template: Basic
        </span>
      </div>
    </>
  );
};

export default React.memo(TemplateOne);
