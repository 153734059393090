import React, { Fragment, useEffect, useState } from "react";
import { BiSolidError } from "react-icons/bi";
import {
  FaExclamationTriangle,
  FaTools,
  FaChartLine,
  FaClipboardList,
  FaRegListAlt,
} from "react-icons/fa";
import { ScatterGraph, BarGraph, DirectedGraph } from "./graph";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";

const RcaFlowSkeleton = () => {
  return (
    <div className="animate-pulse max-w-7xl mx-auto px-4 py-6 space-y-4">
      {/* Stats Cards Skeleton */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {Array(3)
          .fill(null)
          .map((_, i) => (
            <div
              key={i}
              className="bg-white rounded-lg shadow-sm border border-gray-200 p-4"
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 bg-gray-200 rounded-lg"></div>
                  <div className="w-16 h-4 bg-gray-200 rounded"></div>
                </div>
                <div className="w-8 h-4 bg-gray-200 rounded"></div>
              </div>
              <div className="w-24 h-3 bg-gray-200 rounded"></div>
            </div>
          ))}
      </div>

      {/* Recommendations Skeleton */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 mb-6">
        <div className="px-4 py-3 border-b border-gray-200">
          <div className="w-32 h-4 bg-gray-200 rounded"></div>
        </div>
        <div className="divide-y divide-gray-200">
          {Array(3)
            .fill(null)
            .map((_, i) => (
              <div key={i} className="p-4 hover:bg-gray-50">
                <div className="flex items-center space-x-4">
                  <div className="h-10 w-10 rounded-full bg-gray-100"></div>
                  <div className="flex-1">
                    <div className="w-40 h-3 bg-gray-200 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Checks Skeleton */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        <div className="px-4 py-3 border-b border-gray-200">
          <div className="w-32 h-4 bg-gray-200 rounded"></div>
        </div>
        <div className="p-4 space-y-4">
          {Array(3)
            .fill(null)
            .map((_, i) => (
              <div key={i} className="bg-gray-200 rounded-xl p-4" />
            ))}
        </div>
      </div>
    </div>
  );
};
const ModalChecksSkeleton = () => {
  return (
    <div className="w-full max-w-4xl">
      <div className="bg-white p-6 rounded-lg shadow-lg space-y-6">
        {/* Modal Header */}
        <div className="flex items-center space-x-4 mb-6 animate-pulse">
          <div className="p-3 rounded-lg bg-red-100">
            <div className="h-8 w-8 bg-gray-200 rounded"></div>
          </div>
          <div className="w-48 h-6 bg-gray-200 rounded"></div>
        </div>

        <div className="space-y-8">
          {/* First section skeleton */}
          <div className="bg-gray-100 rounded-md p-6 space-y-4 animate-pulse">
            <div className="h-5 w-24 bg-gray-200 rounded"></div>
            <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
            <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
            <div className="h-4 w-1/3 bg-gray-200 rounded"></div>
          </div>

          {/* Second section skeleton */}
          <div className="bg-gray-100 rounded-md p-6 space-y-4 animate-pulse">
            <div className="h-5 w-28 bg-gray-200 rounded"></div>
            {Array(3)
              .fill(null)
              .map((_, i) => (
                <div key={i} className="h-4 w-4/5 bg-gray-200 rounded" />
              ))}
          </div>

          {/* Footer button skeleton */}
          <div className="flex justify-end">
            <div className="h-10 w-24 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RcaRecommendationsFlow = () => {
  const [selectedChecks, setSelectedChecks] = useState({
    "ECH001 check": true,
    "ECH002 check": true,
    "CD check": false,
    "Recipe Check": false,
    "Dose check": true,
  });
  const [showGraphModal, setShowGraphModal] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const handleSystemCheckClick = (checkName) => {
    setSelectedCheck(checkName);
    setShowGraphModal(true);
    // Show modal skeleton briefly
    setIsModalLoading(true);
    setTimeout(() => {
      setIsModalLoading(false);
    }, 1200);
  };

  const handleCheckToggle = (checkName) => {
    setSelectedChecks((prev) => ({
      ...prev,
      [checkName]: !prev[checkName],
    }));
  };

  // Example: simulate data load
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const statsCards = [
    {
      label: "Threat Score",
      value: 8.7,
      icon: <BiSolidError className="h-5 w-5 text-red-500" />,
      description: "Current system threat level",
      colors: "bg-red-50 group-hover:bg-red-100",
    },
    {
      label: "Tool Failure",
      value: "Medium",
      icon: <FaTools className="w-5 h-5 text-yellow-600" />,
      description: "Predicted failure probability",
      colors: "bg-yellow-50 group-hover:bg-yellow-100",
    },
    {
      label: "Process Delta",
      value: "+2.3%",
      icon: <FaChartLine className="w-5 h-5 text-blue-600" />,
      description: "Incoming process variation",
      colors: "bg-blue-50 group-hover:bg-blue-100",
    },
  ];

  const recommendations = [
    "Check end point distribution between all etch chambers",
    "Check tool process parameters baseline",
    "Check timeline of recipes being run on each tools",
  ];

  const checks = [
    {
      name: "ECH001 check",
      severity: "Low",
      gradient: "bg-emerald-500",
      description: "Primary etch chamber verification",
    },
    {
      name: "ECH002 check",
      severity: "Low",
      gradient: "bg-emerald-500",
      description: "Secondary etch chamber verification",
    },
    {
      name: "CD check",
      severity: "Medium",
      gradient: "bg-amber-500",
      description: "Critical dimension measurement",
    },
    {
      name: "Recipe Check",
      severity: "High",
      gradient: "bg-red-500",
      description: "Process recipe validation",
    },
    {
      name: "Dose check",
      severity: "Low",
      gradient: "bg-emerald-500",
      description: "Exposure dose verification",
    },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      {isLoading ? (
        <RcaFlowSkeleton />
      ) : (
        <>
          {/* Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            {statsCards.map((stat) => (
              <div
                key={stat.label}
                className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid p-4 hover:shadow-md transition-all duration-200 group cursor-pointer"
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center space-x-2">
                    <div
                      className={`p-1.5 rounded-lg transition-colors duration-200 ${stat.colors}`}
                    >
                      {stat.icon}
                    </div>
                    <h3 className="text-base font-medium text-gray-900">
                      {stat.label}
                    </h3>
                  </div>
                  <span className="text-2xl font-semibold text-gray-900 tabular-nums">
                    {stat.value}
                  </span>
                </div>
                <p className="text-xs text-gray-500">{stat.description}</p>
              </div>
            ))}
          </div>
          {/* Recommendations Container */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
            <div className="px-4 py-3 border-b border-gray-200">
              <h3 className="text-base font-medium text-gray-900">
                Recommendations
              </h3>
            </div>
            <div className="divide-y divide-gray-200">
              {recommendations.map((rec, index) => (
                <div
                  key={index}
                  className="p-4 hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-center space-x-4">
                    <div className="h-10 w-10 rounded-full bg-orange-100 flex items-center justify-center">
                      <FaExclamationTriangle className="h-5 w-5 text-orange-600" />
                    </div>
                    <div className="flex-1">
                      <p className="text-sm text-gray-900">{rec}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Modernized Checks Container */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
            <div className="px-4 py-3 border-b border-gray-200">
              <h3 className="text-base font-medium text-gray-900">
                System Checks
              </h3>
            </div>
            <div className="p-4 space-y-4">
              {checks.map((check, index) => (
                <div
                  key={index}
                  className={`relative overflow-hidden rounded-xl ${check.gradient}`}
                  onClick={() => handleSystemCheckClick(check.name)}
                >
                  <div className="p-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <div className="flex flex-col">
                          <div className="flex items-center space-x-2">
                            <span className="font-medium text-white">
                              {check.name}
                            </span>
                            <FaExclamationTriangle className="w-4 h-4 text-gray-100" />
                          </div>
                          <span className="text-sm text-white/80">
                            {check.description}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center space-x-3">
                        <span className="text-sm font-medium text-white">
                          {check.severity}
                        </span>
                        <input
                          type="checkbox"
                          checked={selectedChecks[check.name]}
                          onChange={() => handleCheckToggle(check.name)}
                          className="w-4 h-4 rounded border-white/30 bg-white/20 checked:bg-white checked:border-white focus:ring-white text-emerald-600"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Graph Modal */}
          <Transition appear show={showGraphModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={() => setShowGraphModal(false)}
            >
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-50" />
              </TransitionChild>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4">
                  <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <DialogPanel className="bg-white p-4 rounded-lg shadow-lg max-h-[90vh]">
                      {isModalLoading ? (
                        <ModalChecksSkeleton />
                      ) : (
                        <>
                          <div className="flex items-start justify-between mb-4">
                            <div className="flex items-center space-x-3">
                              <div className="p-2 rounded-lg bg-red-100">
                                <BiSolidError className="h-6 w-6 text-red-600" />
                              </div>
                              <DialogTitle
                                as="h3"
                                className="text-xl font-semibold leading-6 text-gray-900"
                              >
                                {selectedCheck}
                              </DialogTitle>
                            </div>
                          </div>
                          <div className="overflow-y-scroll max-h-[70vh] mb-3">
                            {selectedCheck === "Recipe Check" ? (
                              <div className="grid grid-cols-1 gap-4 mb-4">
                                <div className="rounded p-2 flex items-center justify-center">
                                  <ScatterGraph />
                                </div>
                              </div>
                            ) : (
                              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
                                {/* Row 1: three graphs */}
                                <div className="rounded p-2 flex items-center justify-center">
                                  <ScatterGraph />
                                </div>
                                <div className="rounded p-2 flex items-center justify-center">
                                  <ScatterGraph />
                                </div>
                                <div className="rounded p-2 flex items-center justify-center">
                                  <ScatterGraph />
                                </div>
                              </div>
                            )}

                            {/* Row 2: tool logs */}
                            {/* Conditional Content */}
                            {selectedCheck === "Recipe Check" ? (
                              // Recipe Check Table
                              <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                <div className="px-4 py-3 border-b border-gray-200">
                                  <h3 className="text-base font-medium text-gray-900">
                                    Recipe Parameters
                                  </h3>
                                </div>
                                <table className="w-full">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                                        Parameter
                                      </th>
                                      <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                                        Value
                                      </th>
                                      <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                                        Tolerance
                                      </th>
                                      <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                                        Status
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {[
                                      {
                                        parameter: "Etch Rate",
                                        value: "2.3 nm/s",
                                        tolerance: "±0.15 nm/s",
                                        status: "Within Range",
                                      },
                                      {
                                        parameter: "Pressure",
                                        value: "4.5 mTorr",
                                        tolerance: "±0.2 mTorr",
                                        status: "Exceeded",
                                      },
                                      {
                                        parameter: "Temperature",
                                        value: "65°C",
                                        tolerance: "±2°C",
                                        status: "Within Range",
                                      },
                                    ].map((row, index) => (
                                      <tr
                                        key={index}
                                        className="hover:bg-gray-50"
                                      >
                                        <td className="px-4 py-3 text-sm text-gray-600">
                                          {row.parameter}
                                        </td>
                                        <td className="px-4 py-3 text-sm text-gray-600">
                                          {row.value}
                                        </td>
                                        <td className="px-4 py-3 text-sm text-gray-600">
                                          {row.tolerance}
                                        </td>
                                        <td className="px-4 py-3">
                                          <span
                                            className={`px-2.5 py-1 rounded-full text-xs font-medium ${
                                              row.status === "Within Range"
                                                ? "bg-green-100 text-green-800"
                                                : "bg-red-100 text-red-800"
                                            }`}
                                          >
                                            {row.status}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              // Original Tool Logs
                              <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                <div className="px-4 py-3 border-b border-gray-200">
                                  <h3 className="text-base font-medium text-gray-900">
                                    Logs
                                  </h3>
                                </div>
                                <div className="divide-y divide-gray-200">
                                  {recommendations.map((rec, index) => (
                                    <div
                                      key={index}
                                      className="p-3 hover:bg-gray-50 transition-colors"
                                    >
                                      <div className="flex items-center space-x-4">
                                        <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center">
                                          <FaClipboardList className="h-5 w-5 text-gray-600" />
                                        </div>
                                        <div className="flex-1">
                                          <p className="text-sm text-gray-900">
                                            {rec}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}

                            {/* Row 3: summary */}
                            <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                              <div className="px-4 py-3 border-b border-gray-200">
                                <h3 className="text-base font-medium text-gray-900">
                                  Summary
                                </h3>
                              </div>
                              <div className="divide-y divide-gray-200">
                                {recommendations.map((rec, index) => (
                                  <div
                                    key={index}
                                    className="p-3 hover:bg-gray-50 transition-colors"
                                  >
                                    <div className="flex items-center space-x-4">
                                      <div className="h-10 w-10 rounded-full bg-emerald-100 flex items-center justify-center">
                                        <FaRegListAlt className="h-5 w-5 text-emerald-600" />
                                      </div>
                                      <div className="flex-1">
                                        <p className="text-sm text-gray-900">
                                          {rec}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            {/* Row 4: actions */}
                            {selectedCheck === "Recipe Check" && (
                              <div className="space-y-8">
                                {/* Steps Section */}
                                <section>
                                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                    <div className="px-4 py-3 border-b border-gray-200">
                                      <h3 className="text-base font-medium text-gray-900">
                                        Steps
                                      </h3>
                                    </div>
                                    <div className="divide-y divide-gray-200">
                                      <ul className="text-sm mt-2 list-decimal list-inside text-gray-600 space-y-1">
                                        <li className="p-3 hover:bg-gray-50 transition-colors">
                                          Recipe running check on tools ECHO001,
                                          ECHO002, and ECHO003.
                                        </li>
                                        <li className="p-3 hover:bg-gray-50 transition-colors">
                                          Recipe parameter M1_TLE for all etch
                                          chambers are matched.
                                        </li>
                                        <li className="p-3 hover:bg-gray-50 transition-colors">
                                          FCCD trend check: High variance for
                                          Lot 4.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </section>

                                {/* FCCD Trend Chart */}
                                <section>
                                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                    <div className="px-4 py-3 border-b border-gray-200">
                                      <h3 className="text-lg font-medium text-gray-700">
                                        FCC Trends
                                      </h3>
                                    </div>
                                    <div className="flex justify-center">
                                      <ScatterGraph />
                                    </div>
                                  </div>
                                </section>

                                {/* Correlation Section */}
                                <section>
                                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                    <div className="px-4 py-3 border-b border-gray-200">
                                      <h3 className="text-lg font-medium text-gray-700">
                                        Correlations
                                      </h3>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-3">
                                      {/* First Chart */}
                                      <div className="bg-white rounded-lg border-solid border-gray-200 p-4 border">
                                        <div className="mt-2 flex justify-center">
                                          <BarGraph
                                            xAxisLabel="Tool"
                                            yAxisLabel="Stdev"
                                            chartTitle="FCCD Standard Deviation"
                                            barLabels={[
                                              "ECH001",
                                              "ECH002",
                                              "ECH003",
                                              "ECH004",
                                            ]}
                                          />
                                        </div>
                                      </div>
                                      {/* Second Chart */}
                                      <div className="bg-white rounded-lg border-solid border-gray-200 p-4 border">
                                        <div className="mt-2 flex justify-center">
                                          <BarGraph
                                            xAxisLabel="Parameter"
                                            yAxisLabel="Stdev"
                                            chartTitle="Root Cause Correlation"
                                            barLabels={[
                                              "Entity",
                                              "DCCD",
                                              "Etch Time",
                                              "Prior IoT Recipe",
                                            ]}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <p className="mt-4 text-sm text-gray-600 p-3">
                                      FCCD of lots that ran on ECHO004 has a
                                      significantly tighter standard deviation
                                      compared to lots on other tools. Looking
                                      into the root cause: FCCD is highly
                                      correlated to DCCD, etch time, and the
                                      recipe used for the prior lot.
                                    </p>
                                  </div>
                                </section>

                                {/* Causal Analysis Diagram */}
                                <section>
                                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                    <div className="px-4 py-3 border-b border-gray-200">
                                      <h3 className="text-base font-medium text-gray-900">
                                        Causal Analysis
                                      </h3>
                                    </div>
                                    <div className="flex justify-center">
                                      <DirectedGraph />
                                    </div>
                                  </div>
                                </section>

                                {/* Issue Detected */}
                                <section>
                                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                    <div className="px-4 py-3 border-b border-gray-200">
                                      <h3 className="text-base font-medium text-gray-900">
                                        Issue Detected
                                      </h3>
                                    </div>
                                    <div className="px-4 py-3 text-sm mt-2 text-gray-600">
                                      Lot 3 processed in ETCHO1 altered chamber
                                      conditions, impacting subsequent runs.
                                    </div>
                                  </div>
                                </section>

                                {/* Root Cause */}
                                <section>
                                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                    <div className="px-4 py-3 border-b border-gray-200">
                                      <h3 className="text-base font-medium text-gray-900">
                                        Root Cause
                                      </h3>
                                    </div>
                                    <div className="px-4 py-3 text-sm mt-2 text-gray-600">
                                      When Lot 4 entered the same chamber, the
                                      changed etch conditions led to a wider
                                      FCCD distribution.
                                    </div>
                                  </div>
                                </section>

                                {/* Current Status */}
                                <section>
                                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                    <div className="px-4 py-3 border-b border-gray-200">
                                      <h3 className="text-base font-medium text-gray-900">
                                        Current Status
                                      </h3>
                                    </div>
                                    <div className="px-4 py-3 text-sm mt-2 text-gray-600">
                                      Subsequent lots have FCCD distribution
                                      aligned with baseline, indicating
                                      stabilization.
                                    </div>
                                  </div>
                                </section>

                                {/* Recommendations */}
                                <section>
                                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid mb-6">
                                    <div className="px-4 py-3 border-b border-gray-200">
                                      <h3 className="text-base font-medium text-gray-900">
                                        Recommendations
                                      </h3>
                                    </div>
                                    <ul className="mt-2 list-disc list-inside text-gray-600 space-y-1">
                                      <li className="p-3 hover:bg-gray-50 transition-colors">
                                        Implement chamber conditioning
                                        monitoring to track process drift.
                                      </li>
                                      <li className="p-3 hover:bg-gray-50 transition-colors">
                                        Deploy adaptive process control to
                                        maintain consistency.
                                      </li>
                                      <li className="p-3 hover:bg-gray-50 transition-colors">
                                        Enable automated alerts for significant
                                        chamber condition changes.
                                      </li>
                                    </ul>
                                  </div>
                                </section>
                              </div>
                            )}
                          </div>

                          <div className="flex justify-end">
                            <button
                              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
                              onClick={() => setShowGraphModal(false)}
                            >
                              Close
                            </button>
                          </div>
                        </>
                      )}
                    </DialogPanel>
                  </TransitionChild>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </div>
  );
};

export default RcaRecommendationsFlow;
