import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { classNames } from "primereact/utils";
import TabLayout from "../layout/dataModelTabLayout";
import S3Service from "../../service/S3service";
import CsvDataTable from "../table/csvTable/csvDataTable";
import { useNavigate } from "react-router-dom";
import ModelService from "../../service/ModelService";
import ActivityService from "../../service/ActivityService";
import CsvUtils from "../util/CsvUtil";
import "./run.css";
import RunTabLayout from "../layout/runTabLayout";

const Run = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fileData, setFileData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staticTableData, setStaticTableData] = useState([]);
  const [models, setModels] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFiles();
    fetchModels();
  }, []);

  const fetchFiles = async () => {
    try {
      const filesData = await S3Service.getAllObjects();

      // Filter to include only CSV files and remove folder paths
      const csvFiles = filesData
        .filter((file) => file.key.endsWith(".csv")) // Filter to keep only .csv files
        .map((file) => {
          const fileName = file.key.split("/").pop(); // Extract the file name from the path
          return { label: fileName, value: file.key }; // Use fileName for label and full key for value
        });

      setFiles(csvFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const fetchModels = async () => {
    try {
      const allModels = await ModelService.getAllModelName();
      const modelOptions = allModels?.data.map((model) => ({
        label: model,
        value: model,
      }));
      setModels(modelOptions);
    } catch (error) {
      console.error("Error fetching models:", error);
    }
  };

  const logActivity = async (action, name) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const activityLog = {
        email_id: user?.email,
        action: `${action} - ${name}`,
      };
      await ActivityService.addActivityLog(activityLog, user.id);
    } catch (error) {
      console.error("Failed to log activity:", error);
    }
  };

  const handleSubmit = async (data) => {
    console.log("data", data);
    try {
      // Find all existing models by the selected model name
      const selectedModels = await ModelService.getAllJsonData();
      const modelsToUpdate = selectedModels.data.filter(
        (model) => model.model === data.modelName
      );

      console.log("selectedModels", modelsToUpdate);

      // Iterate over each selected model and update the required fields
      const updatePromises = modelsToUpdate.map(async (model) => {
        const updatedModel = {
          ...model,
          file: data.selectedFiles, // Update with the selected file name
          deployment_status: "executed", // Set deployment_status to "executed"
        };

        // Make the API call to update the model
        await ModelService.updateJsonData(model.id, updatedModel);
      });

      // Wait for all update operations to complete
      await Promise.all(updatePromises);

      // Log the activity
      await logActivity("Updated Model", data.modelName);

      // Navigate to the result page
      navigate("/portal/run/result");
    } catch (error) {
      console.error("Error updating model:", error);
    }
  };

  const handleFileSelection = async (selectedFile) => {
    try {
      setLoading(true);

      // Download the file as a Blob
      const blob = await S3Service.downloadFile(selectedFile);

      // Convert the Blob to a string
      const csvString = await blob.text();

      // Parse the CSV string
      const { headers, data } = CsvUtils.parseCsv(csvString);

      // Update the state with the parsed data
      setHeaders(headers);
      setFileData(data);
      setSelectedFileName(selectedFile);
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      modelName: "",
      selectedFiles: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.modelName) {
        errors.modelName = "Select Model To Run";
      }
      if (!data.selectedFiles) {
        errors.selectedFiles = "Select Input File";
      }
      return errors;
    },
    onSubmit: handleSubmit,
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <RunTabLayout activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
      <div className="run-container">
        <div className="upper-half">
          <Card className="run-card">
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="grid-container">
                <div className="formgrid grid">
                  <div className="field col">
                    <div className="dropdown-section">
                      <label htmlFor="selectedFiles">
                        Select File <span style={{ color: "red" }}>*</span>
                      </label>
                      {getFormErrorMessage("selectedFiles")}
                      <Dropdown
                        id="selectedFiles"
                        name="selectedFiles"
                        value={formik.values.selectedFiles}
                        options={files}
                        onChange={(e) => {
                          formik.setFieldValue("selectedFiles", e.value);
                          handleFileSelection(e.value);
                        }}
                        placeholder="Select Files"
                        optionLabel="label"
                        optionValue="value"
                        className={`p-dropdown1 ${classNames({
                          "p-invalid": isFormFieldValid("selectedFiles"),
                        })}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="field col">
                  <div className="dropdown-section">
                    <label htmlFor="modelName">
                      Select Model <span style={{ color: "red" }}>*</span>
                    </label>
                    {getFormErrorMessage("modelName")}
                    <Dropdown
                      id="modelName"
                      name="modelName"
                      value={formik.values.modelName}
                      options={models}
                      onChange={(e) => {
                        formik.setFieldValue("modelName", e.value);
                      }}
                      placeholder="Select a Model"
                      className={`p-dropdown1 ${classNames({
                        "p-invalid": isFormFieldValid("modelName"),
                      })}`}
                    />
                  </div>
                </div>
              </div>
              <div className="button-section">
                <Button
                  type="button"
                  label="Cancel"
                  className="p-button-secondary button-class"
                  onClick={formik.handleReset}
                />
                <Button
                  type="submit"
                  label="Execution"
                  className="p-button-primary button-class"
                  disabled={formik.isSubmitting}
                />
              </div>
            </form>
          </Card>
        </div>

        {selectedFileName && fileData.length > 0 && (
          <div className="lower-half">
            <CsvDataTable headers={headers} data={fileData} />
          </div>
        )}

        {loading && (
          <div className="lower-half">
            <p>Loading...</p>
          </div>
        )}
      </div>
    </RunTabLayout>
  );
};

export default Run;
