import React, { useState, useRef } from "react";
import { FaUsers, FaEye } from "react-icons/fa";
import { Transition } from "@headlessui/react";
import ApproversList from "./approvers-list";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

const ApproversHoverView = ({ approvers = [], isLoading = false }) => {
  // const [isHovering, setIsHovering] = useState(false);
  // const timeoutRef = useRef(null);

  // const handleMouseEnter = () => {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  //   setIsHovering(true);
  // };

  // const handleMouseLeave = () => {
  //   timeoutRef.current = setTimeout(() => {
  //     setIsHovering(false);
  //   }, 300); // Small delay to prevent flickering when moving between button and popup
  // };

  return (
    <Popover className="relative inline-block">
      <PopoverButton
        className="border-solid border border-gray-300 flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white rounded-md hover:bg-gray-200 focus:outline-none"
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        aria-label="View approvers"
      >
        <FaUsers className="mr-2" />
        <span>View</span>
      </PopoverButton>

      <PopoverPanel
        // show={isHovering}
        // enter="transition ease-out duration-200"
        // enterFrom="opacity-0 translate-y-1"
        // enterTo="opacity-100 translate-y-0"
        // leave="transition ease-in duration-150"
        // leaveFrom="opacity-100 translate-y-0"
        // leaveTo="opacity-0 translate-y-1"
        className="absolute z-50 mt-2 w-[700px] max-w-[70vw]"
        anchor="bottom start"
      >
        <div
          className="border-solid border border-gray-200 rounded-lg shadow-lg"
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
        >
          {isLoading ? (
            <div className="p-4 bg-white rounded-md shadow-lg">
              <div className="flex justify-center">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
                <span className="ml-2">Loading approvers...</span>
              </div>
            </div>
          ) : (
            <ApproversList approvers={approvers} />
          )}
        </div>
      </PopoverPanel>
    </Popover>
  );
};

export default ApproversHoverView;
