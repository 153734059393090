import { Fragment, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { BiSolidError } from "react-icons/bi";
import {
  FaExclamationTriangle,
  FaCheckCircle,
  FaChevronDown,
} from "react-icons/fa";
import { ScatterGraph, ScatterGraphGraph } from "./graph";
import { DebugSummaryActionModal } from "./debug-summary-action-modal";

const actions = [
  {
    id: 1,
    recommendation: "Lockout tool A for containment",
    status: "In Progress",
    summary: "PM Spec Review",
  },
  {
    id: 2,
    recommendation: "Perform PM to get etch endpoint to match",
    status: "In Progress",
    summary: "Other tools",
  },
];

const actionOptions = [
  "Recipe update",
  "Limit update",
  "Tool setting",
  "Flow compare",
  "Update Tool Table",
  "PM Spec Review",
];

export const DebugSummaryModal = ({ selectedAlert, onClose }) => {
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([
    {
      author: "John Doe",
      timestamp: "1/16/2025 7:03 am",
      text: "Lot to lot sigma from ECH003 seems to tighter time to time, Further investigation needed to evaluate to figure out what is unique about this tool",
      status: null,
    },
  ]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showDebugSummaryActionModal, setShowDebugSummaryActionModal] =
    useState(false);

  const handleActionSelect = (action) => {
    setSelectedAction(action);
    setShowDebugSummaryActionModal(true);
  };

  const handleCommentSubmit = (status) => {
    if (newComment.trim()) {
      setComments([
        {
          author: "Current User",
          timestamp: new Date().toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
          }),
          text: newComment,
          status: status,
        },
        ...comments,
      ]);
      setNewComment("");
      setShowCommentInput(false);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedTabIndex(0);
  };

  return (
    <>
      <Transition appear show={!!selectedAlert} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="relative w-full max-w-5xl transform rounded-xl bg-white p-6 shadow-xl transition-all flex flex-col max-h-[90vh] h-full">
                  <div className="flex items-start justify-between mb-4">
                    <div className="flex items-center space-x-3">
                      <div className="p-2 rounded-lg bg-red-100">
                        <BiSolidError className="h-6 w-6 text-red-600" />
                      </div>
                      <DialogTitle
                        as="h3"
                        className="text-xl font-semibold sticky top-0 leading-6 text-gray-900"
                      >
                        CD Sigma
                      </DialogTitle>
                    </div>
                  </div>

                  {/* Main Content */}
                  <TabGroup
                    selectedIndex={selectedTabIndex}
                    onChange={setSelectedTabIndex}
                    className="space-y-6 overflow-y-scroll"
                  >
                    <TabList className="bg-white z-10 sticky top-0 flex space-x-4 border-solid border-0 border-b-2 border-gray-200">
                      <Tab className="px-4 py-2 text-base text-gray-500 focus:outline-none bg-white data-[selected]:font-bold data-[selected]:text-blue-600 data-[selected]:border-solid data-[selected]:border-0 data-[selected]:border-b-2 data-[selected]:border-blue-600">
                        Report
                      </Tab>
                      <Tab className="px-4 py-2 text-base text-gray-500 focus:outline-none bg-white data-[selected]:font-bold data-[selected]:text-blue-600 data-[selected]:border-solid data-[selected]:border-0 data-[selected]:border-b-2 data-[selected]:border-blue-600">
                        Action
                      </Tab>
                    </TabList>

                    <TabPanels>
                      {/* Report Panel */}
                      <TabPanel>
                        <div className="flex-1 px-6 overflow-y-scroll">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Left Column */}
                            <div className="space-y-6">
                              {/* Alert Summary */}
                              <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                                <p className="text-gray-700 font-medium">
                                  Key Observations:
                                </p>
                                <ul className="list-disc ml-5 space-y-2 text-sm text-gray-600">
                                  <li>ECH001 CD sigma is 1.2nm</li>
                                  <li>ECH002 CD sigma is 1.1</li>
                                  <li>
                                    ECH003_PM1 CD sigma is fluctuating 0.6 to
                                    1.3nm
                                  </li>
                                  <li>
                                    Suspicion: CD distribution could be tighter
                                    by 50%
                                  </li>
                                  <li>
                                    Lot to lot sigma from ECH003 CD distribution
                                    can be tighter
                                  </li>
                                </ul>
                              </div>

                              {/* Comment textarea */}
                              {showCommentInput && (
                                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 space-y-3">
                                  <textarea
                                    value={newComment}
                                    onChange={(e) =>
                                      setNewComment(e.target.value)
                                    }
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter your comment..."
                                    rows="3"
                                  />
                                  <div className="flex flex-col justify-between items-center">
                                    <div className="flex-row space-x-2 pb-2">
                                      <button
                                        onClick={() =>
                                          handleCommentSubmit("intervention")
                                        }
                                        className="px-3 py-1.5 bg-blue-100 text-gray-700 rounded-md hover:bg-blue-200 text-sm"
                                      >
                                        Intervention Needed
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleCommentSubmit("debugged")
                                        }
                                        className="px-3 py-1.5 bg-blue-100 text-gray-700 rounded-md hover:bg-blue-200 text-sm"
                                      >
                                        Debugged
                                      </button>
                                    </div>
                                    <div className="flex-row space-x-2">
                                      <button
                                        onClick={() =>
                                          setShowCommentInput(false)
                                        }
                                        className="px-3 py-1.5 bg-blue-100 text-gray-700 rounded-md hover:bg-blue-200 text-sm"
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleCommentSubmit(null)
                                        }
                                        className="px-3 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Comment Section */}
                              {comments.map((comment, index) => (
                                <div
                                  key={index}
                                  className="bg-blue-50 border border-blue-200 rounded-lg p-4"
                                >
                                  <div className="flex items-start space-x-3">
                                    <div className="h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center">
                                      <span className="text-sm text-blue-600">
                                        {comment.author.charAt(0)}
                                      </span>
                                    </div>
                                    <div className="flex-1">
                                      <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-gray-900">
                                          {comment.author}
                                        </p>
                                        {comment.status === "intervention" && (
                                          <span className="px-2 py-1 bg-yellow-100 text-yellow-700 rounded-full text-xs">
                                            <FaExclamationTriangle className="inline mr-1" />
                                            Intervention Needed
                                          </span>
                                        )}
                                        {comment.status === "debugged" && (
                                          <span className="px-2 py-1 bg-green-100 text-green-700 rounded-full text-xs">
                                            <FaCheckCircle className="inline mr-1" />
                                            Debugged
                                          </span>
                                        )}
                                      </div>
                                      <p className="text-xs text-gray-500">
                                        {comment.timestamp}
                                      </p>
                                      <p className="mt-1 text-sm text-gray-600 whitespace-pre-wrap">
                                        {comment.text}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              {/* Affected Tools */}
                              <div>
                                <h4 className="text-sm font-semibold text-gray-900 mb-3">
                                  Affected Tools (88 HCCD)
                                </h4>
                                <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                                  {[
                                    "ECH001_PM1",
                                    "ECH001_PM2",
                                    "ECH002_PM1",
                                    "ECH002_PM2",
                                    "ECH003_PM1",
                                    "ECH003_PM2",
                                  ].map((tool) => (
                                    <span
                                      key={tool}
                                      className="px-3 py-1.5 bg-gray-100 text-gray-700 rounded-full text-sm text-center"
                                    >
                                      {tool}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>

                            {/* Right Column - Enhanced Scatter Plot */}
                            <div className="h-full flex flex-col">
                              <ScatterGraph />
                            </div>
                          </div>
                        </div>

                        {/* Footer Actions */}
                        <div className="sticky bottom-0 bg-white pt-6 flex justify-end items-center">
                          <div className="flex space-x-3">
                            <button
                              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
                              onClick={handleClose}
                            >
                              Close
                            </button>
                            <button className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm">
                              Edit
                            </button>
                            <button
                              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
                              onClick={() => {
                                setSelectedTabIndex(0);
                                setShowCommentInput(!showCommentInput);
                              }}
                            >
                              {showCommentInput ? "Discard" : "Comment"}
                            </button>
                            <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm">
                              Export
                            </button>
                          </div>
                        </div>
                      </TabPanel>

                      {/* Action Panel */}
                      <TabPanel>
                        <div className="flex-1 px-6 overflow-y-scroll">
                          <div className="overflow-x-auto">
                            <table className="w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50 sticky top-0 z-10">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left w-12"
                                  >
                                    <input
                                      type="checkbox"
                                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                      // checked={/* Add your select-all logic */}
                                      // onChange={/* Add select-all handler */}
                                    />
                                  </th>
                                  {[
                                    { label: "Recommendation" },
                                    { label: "Action" },
                                    { label: "Status" },
                                    { label: "Summary" },
                                  ].map(({ label }) => (
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 select-none"
                                    >
                                      <div className="flex items-center space-x-1">
                                        <span>{label}</span>
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {actions.map((action) => (
                                  <tr
                                    key={action.id}
                                    className="group hover:bg-gray-50 transition-colors duration-150"
                                  >
                                    <td className="px-6 py-4 w-12 shrink-0">
                                      <input
                                        type="checkbox"
                                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                      />
                                    </td>
                                    <td className="px-6 py-4 min-w-[300px]">
                                      <div className="text-sm text-gray-900">
                                        {action.recommendation}
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm text-gray-500">
                                        {/* Custom Action Section */}
                                        <div className="flex items-center gap-4">
                                          <Menu as="div" className="relative">
                                            <MenuButton className="bg-gray-100 text-gray-800 flex items-center gap-1.5 px-4 py-2 border border-gray-300 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50">
                                              {selectedAction ||
                                                "Choose Action"}
                                              <FaChevronDown className="h-4 w-4 text-gray-800" />
                                            </MenuButton>

                                            <MenuItems className="absolute left-0 mt-2 w-56 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                              <div className="py-1">
                                                {actionOptions.map((option) => (
                                                  <MenuItem key={option}>
                                                    <button
                                                      onClick={() =>
                                                        handleActionSelect(
                                                          option,
                                                        )
                                                      }
                                                      className="data-[hover]:bg-gray-100 data-[hover]:text-gray-900 text-gray-700 bg-white flex w-full items-center px-4 py-2 text-sm transition-colors duration-200 ease-in-out hover:bg-gray-100"
                                                    >
                                                      {option}
                                                    </button>
                                                  </MenuItem>
                                                ))}
                                              </div>
                                            </MenuItems>
                                          </Menu>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-6 py-4">
                                      <span
                                        className={`px-2 py-1.5 rounded-full ${action.status === "Completed" ? "bg-green-100 text-green-700" : "bg-yellow-100 text-yellow-700"}`}
                                      >
                                        {action.status}
                                      </span>
                                    </td>
                                    <td className="px-6 py-4">
                                      <div className="text-sm text-gray-500">
                                        {action.summary}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* Footer Actions */}
                        <div className="sticky bottom-0 bg-white pt-6 flex justify-end items-center">
                          <div className="flex space-x-3">
                            <button
                              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
                              onClick={handleClose}
                            >
                              Cancel
                            </button>
                            <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm">
                              Execute
                            </button>
                          </div>
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <DebugSummaryActionModal
        isOpen={showDebugSummaryActionModal}
        onClose={() => setShowDebugSummaryActionModal(false)}
        action={selectedAction}
      />
    </>
  );
};
