import axios from "axios";
import { REPORT_API_BASE_URL } from "../utils/config";
import { createAuthenticatedApiClient } from "../utils/clerk-auth";

// Create an authenticated API client using Clerk
const apiClient = createAuthenticatedApiClient();

const ReportService = {
  createReport: async (reportData) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/create_report`,
        reportData,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProjectReports: async (projectId) => {
    try {
      const response = await apiClient.get(
        `${REPORT_API_BASE_URL}/project/${projectId}/reports`,
      );
      return response.data.reports;
    } catch (error) {
      throw error;
    }
  },

  getReportById: async (reportId) => {
    try {
      const response = await apiClient.get(
        `${REPORT_API_BASE_URL}/${reportId}`,
      );
      return response.data.report;
    } catch (error) {
      throw error;
    }
  },
  visualizeReport: async ({ report_id, question_id, ...reportData }) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/visualize`,
        reportData,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  knowledgeGraphForQuestion: async ({
    report_id,
    question_id,
    ...reportData
  }) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/knowledge-graph`,
        reportData,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  knowledgeGraphForReport: async (data) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/knowledge-graph`,
        data,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  documentsUsed: async (paginationParams) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/documents-used`,
        { ...paginationParams },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  uploadDocumentsUsed: async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/documents-used/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  startReportWithQuestionnaire: async (reportId, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${reportId}/start-questionnaire`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteDocumentsUsed: async (s3Key) => {
    try {
      const response = await apiClient.delete(
        `${REPORT_API_BASE_URL}/documents-used/${s3Key}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  tablesUsedForQuestion: async ({ report_id, question_id, ...reportData }) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/tables-used`,
        reportData,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  regenrateAnswerForQuestion: async ({
    report_id,
    question_id,
    regenerateFeedback,
  }) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/regenerate-answer`,
        { regenerateFeedback },
      );
      return response.data.question;
    } catch (error) {
      throw error;
    }
  },
  updateQuestionAssignee: async ({ report_id, question_id, assignee }) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/assign`,
        { assignee },
      );
      return response.data.question;
    } catch (error) {
      throw error;
    }
  },

  updateReport: async (reportId, reportData) => {
    try {
      const response = await apiClient.put(
        `${REPORT_API_BASE_URL}/${reportId}`,
        reportData,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteReport: async (reportId) => {
    try {
      const response = await apiClient.delete(
        `${REPORT_API_BASE_URL}/${reportId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addQuestion: async (reportId, questionData) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${reportId}/add_question`,
        questionData,
      );
      return response.data.question;
    } catch (error) {
      throw error;
    }
  },
  updateQuestion: async (reportId, questionId, questionData) => {
    try {
      const response = await apiClient.put(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}`,
        questionData,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteQuestion: async (reportId, questionId) => {
    try {
      const response = await apiClient.delete(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCommentsForQuestion: async (reportId, questionId) => {
    try {
      const response = await apiClient.get(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}/comments`,
      );
      return response.data.comments;
    } catch (error) {
      throw error;
    }
  },
  addComment: async (reportId, questionId, commentData) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}/comments`,
        commentData,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createFolder: async (folderData) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/project/${folderData.project_id}/folder`,
        folderData,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProjectFolders: async (projectId) => {
    try {
      const response = await apiClient.get(
        `${REPORT_API_BASE_URL}/project/${projectId}/folders`,
      );
      return response.data.folders;
    } catch (error) {
      throw error;
    }
  },
  getProjectReportsCountByStatus: async (projectId) => {
    try {
      const response = await apiClient.get(
        `${REPORT_API_BASE_URL}/project/${projectId}/reports/count-by-status`,
      );
      return response.data.statusCounts;
    } catch (error) {
      throw error;
    }
  },
  addQuestionLabel: async ({
    report_id,
    question_id,
    label_id,
    created_by,
  }) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/label`,
        { label_id, created_by },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteQuestionLabel: async ({ report_id, question_id, label_id }) => {
    try {
      const response = await apiClient.delete(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/label`,
        {
          data: { label_id },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getFolderReports: async (folderId) => {
    try {
      const response = await apiClient.get(
        `${REPORT_API_BASE_URL}/folder/${folderId}/reports`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createReportFolder: async (reportId, folderId) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${reportId}/folder`,
        { folder_id: folderId },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Get all approvers for a specific report
   * @param {string} reportId - The ID of the report
   * @returns {Promise<Object>} - The response data containing approvers
   */
  getReportApprovers: async (reportId) => {
    try {
      const response = await apiClient.get(
        `${REPORT_API_BASE_URL}/${reportId}/approvers`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Add a new approver to a report
   * @param {string} reportId - The ID of the report
   * @param {string} userId - The ID of the user to add as approver
   * @param {string} status - Initial status of the approval (default: 'pending')
   * @returns {Promise<Object>} - The response data
   */
  addReportApprover: async (reportId, userId, status = "pending") => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${reportId}/approvers`,
        {
          user_id: userId,
          status,
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * remove a approver to a report
   * @param {string} reportId - The ID of the report
   * @param {string} userId - The ID of the user to add as approver
   * @returns {Promise<Object>} - The response data
   */
  removeReportApprover: async (reportId, userId) => {
    try {
      const response = await apiClient.delete(
        `${REPORT_API_BASE_URL}/${reportId}/approvers/${userId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * remove all approver to a report
   * @param {string} reportId - The ID of the report
   * @returns {Promise<Object>} - The response data
   */
  removeAllReportApprover: async (reportId) => {
    try {
      const response = await apiClient.delete(
        `${REPORT_API_BASE_URL}/${reportId}/approvers`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Update an existing report approver's status
   * @param {string} reportId - The ID of the report
   * @param {string} userId - The ID of the user/approver
   * @param {string} status - New status of the approval ('approved', 'rejected', 'pending')
   * @param {string} rejectionReason - Reason for rejection if status is 'rejected'
   * @returns {Promise<Object>} - The response data
   */
  updateReportApprover: async (
    reportId,
    userId,
    status,
    rejectionReason = null,
  ) => {
    const payload = { status };

    // Add rejection reason if status is rejected and reason is provided
    if (status === "rejected" && rejectionReason) {
      payload.rejection_reason = rejectionReason;
    }

    try {
      const response = await apiClient.put(
        `${REPORT_API_BASE_URL}/${reportId}/approvers/${userId}`,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getReportSummary: async (reportId) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${reportId}/qna-summary`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getReportTableOfContents: async (reportId) => {
    try {
      const response = await apiClient.post(
        `${REPORT_API_BASE_URL}/${reportId}/toc`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * get all pending approval reports
   * @param {string} reportId - The ID of the report
   * @param {string} userId - The ID of the user/approver
   * @returns {Promise<Object>} - The response data
   */
  getPedningApprovalReport: async (projectId, userId) => {
    try {
      const response = await apiClient.get(
        `${REPORT_API_BASE_URL}/project/${projectId}/pending-approval-reports?user_id=${userId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * get all submitted reports
   * @param {string} reportId - The ID of the report
   * @returns {Promise<Object>} - The response data
   */
  getSubmittedReport: async (projectId) => {
    try {
      const response = await apiClient.get(
        `${REPORT_API_BASE_URL}/project/${projectId}/submitted-reports`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ReportService;
