import "./data.css";
import React, { useState, useEffect } from "react";
import { AiOutlineDownload, AiOutlineDelete } from "react-icons/ai";
import WorkspaceService from "../../../service/WorkspaceService";
import useWorkspaceStore from "../../../stores/workspaceStore";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

const datasetOptions = [
  { id: -1, name: "Select Dataset", disabled: true },
  { id: 1, name: "Dataset 1" },
  { id: 2, name: "Dataset 2" },
  { id: 3, name: "Dataset 3" },
];
const contextFilesOptions = [
  { id: -1, name: "Select Context Files", disabled: true },
  { id: 1, name: "Context File 1" },
  { id: 2, name: "Context File 2" },
  { id: 3, name: "Context File 3" },
];
const flowFilesOptions = [
  { id: -1, name: "Select Flow File", disabled: true },
  { id: 1, name: "Flow File 1" },
  { id: 2, name: "Flow File 2" },
  { id: 3, name: "Flow File 3" },
];
const operationOptions = [
  { id: -1, name: "Select Operation", disabled: true },
  { id: 1, name: "Operation 1" },
  { id: 2, name: "Operation 2" },
  { id: 3, name: "Operation 3" },
];

const Dropdown = ({ options, selected, onChange, allowMultiple }) => {
  return (
    <Listbox
      as="div"
      className="relative text-left w-full"
      value={selected}
      onChange={onChange}
      multiple={allowMultiple}
    >
      <ListboxButton className="text-gray-500 bg-white flex hover:bg-gray-50 rounded-lg w-full px-4 py-2 border border-solid border-gray-200">
        {allowMultiple &&
          selected.length !== 0 &&
          selected.map((option) => option.name).join(", ")}
        {allowMultiple && selected.length === 0 && "Select"}
        {!allowMultiple && selected.name}
      </ListboxButton>
      <ListboxOptions className="z-10 absolute border border-solid border-gray-200 left-0 mt-2 w-full origin-top-right divide-y divide-gray-200 rounded-lg overflow-y-scroll max-h-32 bg-transparent backdrop-blur-lg shadow-lg focus:outline-none">
        {options.map((option) => (
          <ListboxOption
            key={option.id}
            value={option}
            disabled={option.disabled}
            className="group flex w-full items-center px-4 py-2 text-sm bg-white text-gray-900 data-[focus]:bg-gray-50 data-[selected]:bg-blue-50 data-[disabled]:opacity-50"
          >
            {option.name}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};

const Integration = () => {
  const { selectedWorkspace } = useWorkspaceStore();

  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "modifiedAt",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [selectedDocs, setSelectedDocs] = useState(new Set());
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState(datasetOptions[0]);
  const [selectedContextFile, setSelectedContextFile] = useState([]);
  const [selectedFlowFile, setSelectedFlowFile] = useState(flowFilesOptions[0]);
  const [selectedOperation, setSelectedOperation] = useState(
    operationOptions[0],
  );

  const PER_PAGE = 10;

  useEffect(() => {
    const fetchDocuments = async () => {
      if (!selectedWorkspace?.workspace_id) return;

      try {
        setIsLoading(true);
        setError(null);
        const response = await WorkspaceService.documentsUsed(
          {
            page,
            per_page: PER_PAGE,
          },
          selectedWorkspace.workspace_id,
        );
        setDocuments(response.sources || []);
        setTotalPages(Math.ceil(response.pagination.total_pages));
        setTotalDocuments(response.pagination.total_count);
      } catch (error) {
        console.error("Error fetching documents:", error);
        setError("Failed to fetch documents. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocuments();
  }, [page, selectedWorkspace?.id]);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      setSelectedDocs(new Set()); // Clear selection on page change
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedDocs(new Set(sortedDocuments.map((doc) => doc.id)));
    } else {
      setSelectedDocs(new Set());
    }
  };

  const handleSelectDoc = (docId) => {
    const newSelected = new Set(selectedDocs);
    if (newSelected.has(docId)) {
      newSelected.delete(docId);
    } else {
      newSelected.add(docId);
    }
    setSelectedDocs(newSelected);
  };

  const handleDownloadSelected = async () => {
    try {
      const selectedDocuments = sortedDocuments.filter((doc) =>
        selectedDocs.has(doc.id),
      );

      // Download each selected document
      const downloads = selectedDocuments.map(async (doc) => {
        const response = await fetch(doc.location);
        const blob = await response.blob();

        // Create temporary link and trigger download
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = doc.name; // Use document name as filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      });

      // Wait for all downloads to complete
      await Promise.all(downloads);
    } catch (error) {
      console.error("Error downloading documents:", error);
      // You may want to show an error message to the user here
    }
  };

  const handleDeleteSelected = async () => {
    if (isDeleting) return;

    try {
      setIsDeleting(true);
      const selectedDocuments = sortedDocuments.filter((doc) =>
        selectedDocs.has(doc.id),
      );

      // Delete each selected document
      const deletions = selectedDocuments.map(async (source) => {
        await WorkspaceService.deleteWorkspaceSource(
          selectedWorkspace.workspace_id,
          source.id,
        );
      });

      await Promise.all(deletions);

      // Refresh documents list
      const response = await WorkspaceService.documentsUsed(
        {
          page,
          per_page: PER_PAGE,
        },
        selectedWorkspace.workspace_id,
      );
      setDocuments(response.sources || []);
      setTotalPages(Math.ceil(response.pagination.total_pages));
      setTotalDocuments(response.pagination.total_count);
      setSelectedDocs(new Set()); // Clear selection after delete
    } catch (error) {
      console.error("Error deleting documents:", error);
      setError("Failed to delete documents. Please try again later.");
    } finally {
      setIsDeleting(false);
    }
  };

  const sortedDocuments = [...documents].sort((a, b) => {
    if (sortConfig.key === "fetchInterval") {
      const intervalToMinutes = (interval) => {
        const [value, unit] = interval.split(" ");
        switch (unit) {
          case "minute":
          case "minutes":
            return parseInt(value);
          case "hour":
          case "hours":
            return parseInt(value) * 60;
          case "day":
          case "days":
            return parseInt(value) * 1440;
          case "month":
          case "months":
            return parseInt(value) * 43200; // Assuming 1 month = 30 days
          default:
            return 0;
        }
      };

      return sortConfig.direction === "asc"
        ? intervalToMinutes(a.fetchInterval) -
            intervalToMinutes(b.fetchInterval)
        : intervalToMinutes(b.fetchInterval) -
            intervalToMinutes(a.fetchInterval);
    }

    if (sortConfig.key === "size") {
      return sortConfig.direction === "asc" ? a.size - b.size : b.size - a.size;
    }

    return sortConfig.direction === "asc"
      ? a[sortConfig.key].localeCompare(b[sortConfig.key])
      : b[sortConfig.key].localeCompare(a[sortConfig.key]);
  });

  const renderSkeleton = () => (
    <div className="animate-pulse">
      <div className="space-y-3">
        {[...Array(PER_PAGE)].map((_, idx) => (
          <div key={idx} className="grid grid-cols-7 gap-4">
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
          </div>
        ))}
      </div>
    </div>
  );

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-red-500 text-center">
          <p>{error}</p>
          <button
            onClick={() => setPage(page)}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white h-full rounded-lg shadow flex flex-col overflow-x-auto">
        <div className="border-b px-6 py-4 flex items-center justify-between sticky top-0 bg-white z-20">
          <div className="flex items-center space-x-4">
            {selectedDocs.size > 0 && (
              <>
                <button
                  onClick={handleDownloadSelected}
                  className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <AiOutlineDownload className="mr-2 h-4 w-4" />
                  Download ({selectedDocs.size})
                </button>
                <button
                  onClick={handleDeleteSelected}
                  disabled={isDeleting}
                  className="inline-flex items-center px-3 py-1.5 border border-red-300 rounded-md text-sm font-medium text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <AiOutlineDelete className="mr-2 h-4 w-4" />
                  {isDeleting ? "Deleting..." : `Delete (${selectedDocs.size})`}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 m-6 p-6 z-30 bg-gray-100 rounded-xl justify-items-center">
          <Dropdown
            title="Dataset"
            options={datasetOptions}
            selected={selectedDataset}
            onChange={setSelectedDataset}
          />
          <Dropdown
            title="Context Files"
            options={contextFilesOptions}
            selected={selectedContextFile}
            onChange={setSelectedContextFile}
            allowMultiple
          />
          <Dropdown
            title="Flow File"
            options={flowFilesOptions}
            selected={selectedFlowFile}
            onChange={setSelectedFlowFile}
          />
          <Dropdown
            title="Operation"
            options={operationOptions}
            selected={selectedOperation}
            onChange={setSelectedOperation}
          />
        </div>

        <div className="overflow-x-scroll flex-1">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 z-10">
              <tr>
                {/* <th scope="col" className="px-6 py-3 text-left">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={
                      selectedDocs.size === sortedDocuments.length &&
                      sortedDocuments.length > 0
                    }
                    onChange={handleSelectAll}
                  />
                </th> */}
                {[
                  { key: "data_file", label: "Data File" },
                  { key: "context_docs", label: "Context Documents" },
                  { key: "flow_file", label: "Flow File" },
                  { key: "operation", label: "Operation" },
                ].map(({ key, label }) => (
                  <th
                    key={key}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 select-none"
                    onClick={() => handleSort(key)}
                  >
                    <div className="flex items-center space-x-1">
                      <span>{label}</span>
                      {sortConfig.key === key && (
                        <span className="text-gray-400">
                          {sortConfig.direction === "asc" ? "↑" : "↓"}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {isLoading ? (
                <tr>
                  <td colSpan="7" className="px-6 py-4">
                    {renderSkeleton()}
                  </td>
                </tr>
              ) : (
                // mockDocuments.map((doc, index) => (
                // sortedDocuments.map((doc, index) => (
                <tr
                  className={`group hover:bg-gray-50 transition-colors duration-150 ${
                    selectedDocs.has(selectedDataset.id) ? "bg-blue-50" : ""
                  }`}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center min-w-0">
                        <span className="ml-2 text-sm text-gray-900 truncate">
                          {!selectedDataset.disabled ? (
                            selectedDataset.name
                          ) : (
                            <i>Select Dataset</i>
                          )}
                        </span>
                      </div>
                      <a
                        href={selectedDataset.location}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
                        title="Open in new tab"
                      >
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                      </a>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {selectedContextFile.length > 0 ? (
                      selectedContextFile.map((file) => file.name).join(", ")
                    ) : (
                      <i>Select Context Files</i>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {!selectedFlowFile.disabled ? (
                      selectedFlowFile.name
                    ) : (
                      <i>Select Flow File</i>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {!selectedOperation.disabled ? (
                      selectedOperation.name
                    ) : (
                      <i>Select Operation</i>
                    )}
                  </td>
                </tr>
                // ))
              )}
            </tbody>
          </table>
        </div>
        <div className="px-6 py-3 border-t flex items-end justify-end bg-white sticky bottom-0">
          <button
            // onClick={handleUpload}
            // disabled={isUploading}
            disabled={
              selectedDataset.id === -1
              // selectedContextFile.length === 0 ||
              // selectedFlowFile.id === -1 ||
              // selectedOperation.id === -1
            }
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {/* {isUploading ? "Uploading..." : "Upload"} */}
            Data Talk
          </button>
        </div>

        {/* <div className="px-6 py-3 border-t flex items-center justify-between bg-white sticky bottom-0">
          <div className="text-sm text-gray-700">
            Showing {(page - 1) * PER_PAGE + 1} to{" "}
            {Math.min(page * PER_PAGE, totalDocuments)} of {totalDocuments}{" "}
            documents
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1 || isLoading}
              className="px-3 py-1.5 border rounded text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages || isLoading}
              className="px-3 py-1.5 border rounded text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Next
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Integration;
