import { useState, Fragment } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { BiPlus } from "react-icons/bi";
import { RxCrossCircled } from "react-icons/rx";
import { RiCloseLine } from "react-icons/ri";

const CustomRulesModal = ({ isOpen, onClose }) => {
  const [conditions, setConditions] = useState([
    { field: "", operator: "", value: "" },
  ]);

  const addCondition = () => {
    setConditions([...conditions, { field: "", operator: "", value: "" }]);
  };

  const removeCondition = (index) => {
    const newConditions = conditions.filter((_, i) => i !== index);
    setConditions(newConditions);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-2xl transform overflow-hidden rounded-xl bg-white p-6 shadow-xl transition-all">
                <div className="flex justify-between items-center mb-6">
                  <DialogTitle
                    as="h3"
                    className="text-xl font-semibold text-gray-900"
                  >
                    Create Custom Rule
                  </DialogTitle>
                </div>

                <div className="space-y-6">
                  {/* Rule Name */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Rule Name
                    </label>
                    <input
                      type="text"
                      className="w-full px-3 py-2 border border-solid border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter rule name"
                    />
                  </div>

                  {/* Description */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Description
                    </label>
                    <textarea
                      className="w-full px-3 py-2 border border-solid border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      rows="3"
                      placeholder="Rule description..."
                    />
                  </div>

                  {/* Conditions */}
                  <div>
                    <div className="flex justify-between items-center mb-3">
                      <span className="text-sm font-medium text-gray-700">
                        Conditions
                      </span>
                      <button
                        onClick={addCondition}
                        className="rounded-md text-blue-600 hover:text-blue-700 text-sm flex items-center gap-1"
                      >
                        <BiPlus className="h-4 w-4" />
                        Add Condition
                      </button>
                    </div>

                    <div className="space-y-3">
                      {conditions.map((_, index) => (
                        <div key={index} className="flex gap-3 items-center">
                          <select className="flex-1 px-3 py-2 border border-gray-300 rounded-md">
                            <option>Select Field</option>
                            <option>CD Sigma</option>
                            <option>FCCD</option>
                            <option>Throughput</option>
                          </select>

                          <select className="flex-1 px-3 py-2 border border-gray-300 rounded-md">
                            <option>Operator</option>
                            <option>&gt;</option>
                            <option>&lt;</option>
                            <option>=</option>
                            <option>≠</option>
                          </select>

                          <input
                            type="text"
                            className="flex-1 px-3 py-2 border border-solid border-gray-300 rounded-md"
                            placeholder="Value"
                          />

                          {conditions.length > 1 && (
                            <button
                              onClick={() => removeCondition(index)}
                              className="text-red-500 hover:text-red-600 bg-white rounded-md p-1"
                            >
                              <RxCrossCircled className="h-5 w-5" />
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Footer Actions */}
                <div className="mt-8 flex justify-end gap-3">
                  <button
                    onClick={onClose}
                    className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
                    Save
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CustomRulesModal;
