import { WORKSPACE_API_BASE_URL } from "../utils/config";
import { createAuthenticatedApiClient } from "../utils/clerk-auth";

// Create an authenticated API client using Clerk
const apiClient = createAuthenticatedApiClient();

const WorkspaceService = {
  createWorkspace: async (workspaceData) => {
    try {
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/create`,
        workspaceData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteWorkspaces: async (workspaceIds) => {
    try {
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/delete`,
        {
          workspace_id: workspaceIds,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getUserWorkspaces: async (userId) => {
    try {
      const response = await apiClient.get(`${WORKSPACE_API_BASE_URL}/user`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  getAllUser: async () => {
    try {
      const response = await apiClient.get(`${WORKSPACE_API_BASE_URL}/org-user`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createProject: async (workspaceId, projectData) => {
    try {
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/project`,
        projectData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceProjects: async (workspaceId) => {
    try {
      const response = await apiClient.get(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/projects`
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },
  documentsUsed: async (paginationParams, workspaceId) => {
    try {
      const { page = 1, per_page = 10 } = paginationParams;
      const response = await apiClient.get(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/sources`,
        {
          params: {
            page,
            per_page,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  projectDocumentsUsed: async (paginationParams, projectId) => {
    try {
      const { page = 1, per_page = 10 } = paginationParams;
      const response = await apiClient.get(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/sources`,
        {
          params: {
            page,
            per_page,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addWorkspaceSource: async (fileOrPayload, workspaceId) => {
    try {
      // If fileOrPayload is an object, it's a link upload
      if (
        typeof fileOrPayload === "object" &&
        !(fileOrPayload instanceof File)
      ) {
        const response = await apiClient.post(
          `${WORKSPACE_API_BASE_URL}/${workspaceId}/source`,
          fileOrPayload
        );
        return response.data;
      }

      // Handle file upload
      const formData = new FormData();
      formData.append("file", fileOrPayload);
      formData.append("size", fileOrPayload.size);

      // For file uploads, we need to set the Content-Type header
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/source`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addProjectSource: async (fileOrPayload, projectId) => {
    try {
      // If fileOrPayload is an object, it's a link upload
      if (
        typeof fileOrPayload === "object" &&
        !(fileOrPayload instanceof File)
      ) {
        const response = await apiClient.post(
          `${WORKSPACE_API_BASE_URL}/project/${projectId}/source`,
          fileOrPayload
        );
        return response.data;
      }

      // Handle file upload
      const formData = new FormData();
      formData.append("file", fileOrPayload);
      formData.append("size", fileOrPayload.size);

      // For file uploads, we need to set the Content-Type header
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/source`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteWorkspaceSource: async (workspaceId, sourceId) => {
    try {
      const response = await apiClient.delete(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/source/${sourceId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteProjectSource: async (projectId, sourceId) => {
    try {
      const response = await apiClient.delete(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/source/${sourceId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceUsers: async (workspaceId) => {
    try {
      const response = await apiClient.get(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/users`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceUser: async (workspaceId, userId) => {
    try {
      const response = await apiClient.get(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/user/${userId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createWorkspaceUser: async (workspaceId, userData) => {
    try {
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/user`,
        userData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  inviteWorkspaceUser: async (workspaceId, inviteData) => {
    try {
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/invite`,
        inviteData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteWorkspaceUser: async (workspaceId, userId) => {
    try {
      const response = await apiClient.delete(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/${userId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  respondToWorkspaceInvite: async (workspaceId, responseData) => {
    try {
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/invite/respond`,
        responseData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getProjectChats: async (projectId) => {
    try {
      const response = await apiClient.get(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/chats`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getChat: async (chatId) => {
    try {
      const response = await apiClient.get(
        `${WORKSPACE_API_BASE_URL}/chat/${chatId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteChat: async (chatId) => {
    try {
      const response = await apiClient.delete(
        `${WORKSPACE_API_BASE_URL}/chat/${chatId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createChat: async (projectId, chatData) => {
    try {
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/chat`,
        chatData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateChat: async (chatId, chatData) => {
    try {
      const response = await apiClient.put(
        `${WORKSPACE_API_BASE_URL}/chat/${chatId}`,
        chatData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createLabel: async (workspaceId, labelData) => {
    try {
      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/label`,
        labelData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateLabel: async (labelId, labelData) => {
    try {
      const response = await apiClient.put(
        `${WORKSPACE_API_BASE_URL}/label/${labelId}`,
        labelData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceLabels: async (workspaceId) => {
    try {
      const response = await apiClient.get(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/labels`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteLabel: async (labelId) => {
    try {
      const response = await apiClient.delete(
        `${WORKSPACE_API_BASE_URL}/label/${labelId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  uploadFile: async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await apiClient.post(
        `${WORKSPACE_API_BASE_URL}/upload-file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

};

export default WorkspaceService;
