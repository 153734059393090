const PremiumFeatures = () => {
   const copyEmailToClipboard = () => {
        navigator.clipboard.writeText('sales@thirdaiautomation.com')
            .then(() => {
                alert('Email copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy email: ', err);
            });
    };

    return (
        <div className="flex flex-col items-center justify-center h-[500px] space-y-4">
            <img src="/asset/data/logo.png" alt="logo" className="w-[100px]" />
            <h1 className="text-2xl font-bold">This feature is available for only premium plan</h1>
            <p className="text-gray-500">Please upgrade to premium plan to use this feature</p>
            <p className="text-gray-500">
                Please contact to{' '}
                <b 
                    onClick={copyEmailToClipboard}
                    className="cursor-pointer hover:text-blue-600"
                    title="Click to copy email"
                >
                    sales@thirdaiautomation.com
                </b>
                {' '}to get premium plan
            </p>
        </div>
    );
};

export default PremiumFeatures;
