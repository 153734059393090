import { HiOutlineUserCircle, HiTrash, HiDotsHorizontal } from "react-icons/hi";
import useWorkspaceStore from "../../../stores/workspaceStore";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Tooltip from "../../tooltip";

const WorkspaceUser = ({ user }) => {
  const { selectedWorkspace, deleteWorkspaceUser } = useWorkspaceStore();
  const [showActions, setShowActions] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleClickOutside = () => {
    setShowActions(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = async () => {
    try {
      await deleteWorkspaceUser(selectedWorkspace.workspace_id, user.user_id);
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  const formatPermissionGroup = (group) => {
    return group
      .replace(/_/g, " ")
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <motion.li
      className="px-6 py-4 hover:bg-gray-50 transition-all relative group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      initial={false}
      animate={{ backgroundColor: isHovered ? "#F9FAFB" : "#FFFFFF" }}
    >
      <div className="flex items-center space-x-4">
        <div className="h-10 w-10 rounded-full bg-gradient-to-br from-blue-50 to-blue-100 flex items-center justify-center border border-blue-200">
          {user.avatar_url ? (
            <img
              src={user.avatar_url}
              alt={user.name}
              className="h-full w-full rounded-full object-cover"
            />
          ) : (
            <HiOutlineUserCircle className="h-6 w-6 text-blue-600" />
          )}
        </div>

        <div className="flex-1 min-w-0">
          <div className="flex justify-between items-center">
            <div className="truncate">
              <h3 className="text-sm font-medium text-gray-900 truncate">
                {user.name}
              </h3>
              <p className="text-sm text-gray-500 truncate">{user.email}</p>
            </div>

            <div className="relative">
              {/* <Tooltip content="More actions"> // removed as its rendering multiple tooltips */}
                <button
                  onClick={() => setShowActions(!showActions)}
                  className={`p-1.5 rounded-md transition-colors cursor-pointer ${
                    isHovered ? "opacity-100" : "opacity-0"
                  } group-hover:opacity-100 hover:bg-gray-100`}
                >
                  <HiDotsHorizontal className="h-4 w-4 text-gray-500" />
                </button>
              {/* </Tooltip> */}

              <AnimatePresence>
                {showActions && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    className="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                  >
                    <div className="py-1">
                      <button
                        onClick={handleDelete}
                        className="w-full bg-transparent text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 flex items-center space-x-2 cursor-pointer"
                      >
                        <HiTrash className="h-4 w-4" />
                        <span>Remove from workspace</span>
                      </button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          <div className="mt-2 flex flex-wrap gap-2">
            {user.permission_groups?.map((group, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium bg-blue-50 text-blue-700 border border-blue-100"
              >
                {formatPermissionGroup(group)}
              </span>
            ))}
            <span
              className={`inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium ${
                user.status === "ACTIVE"
                  ? "bg-green-50 text-green-700 border border-green-100"
                  : "bg-yellow-50 text-yellow-700 border border-yellow-100"
              }`}
            >
              {user.status}
            </span>
          </div>

          {user.joined_at && (
            <p className="text-xs text-gray-400 mt-2">
              Joined{" "}
              {new Date(user.joined_at).toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          )}
        </div>
      </div>
    </motion.li>
  );
};

export default WorkspaceUser;
