import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import { Switch } from "@headlessui/react";

const ReportSettings = ({ reports, onUpdateReports, onShowToast }) => {
  const { reportId } = useParams();
  const defaultSettings = {
    tone: "neutral",
    systemInstructions: "",
    verbosity: "standard", // Report Verbosity
    dataRepresentation: "balanced", // Data Representation
    includeExecutiveSummary: false, // New toggle: Include Executive Summary
    includeSummaryTOC: false, // New toggle: Include Summary and table of contents
    includeReferencesCitations: false, // New toggle: Include References & Citations
  };
  const [report, setReport] = useState(null);
  const [settings, setSettings] = useState(defaultSettings);

  useEffect(() => {
    const selectedReport = reports.find(
      (r) => String(r.report_id) === reportId,
    );
    if (selectedReport) {
      setReport(selectedReport);
      // Merge selected report's settings with our defaults
      setSettings({
        ...defaultSettings,
        ...selectedReport.settings,
      });
    }
  }, [reportId, reports]);

  const handleSaveSettings = () => {
    if (!report) return;
    const updatedReport = {
      ...report,
      settings,
      updatedAt: new Date().toISOString(),
    };
    const updatedReports = reports.map((r) =>
      r.id === report.id ? updatedReport : r,
    );
    onUpdateReports(updatedReports);
    onShowToast(
      "success",
      "Settings saved",
      "Your report settings have been updated.",
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  if (!report) {
    return (
      <div className="col-span-3 p-4 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">
          Select a report to configure its settings.
        </p>
      </div>
    );
  }

  return (
    <div className="col-span-3 w-full p-6 bg-white overflow-y-scroll h-full mx-auto">
      <h1 className="text-2xl font-bold mb-8">Report Settings</h1>
      <div className="space-y-8">
        {/* Tone setting */}
        <div>
          <label
            htmlFor="tone"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Tone
          </label>
          <div className="relative">
            <select
              name="tone"
              value={settings.tone}
              onChange={handleChange}
              className="appearance-none w-full bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="neutral">Neutral</option>
              <option value="formal">Formal</option>
              <option value="informal">Informal</option>
              <option value="friendly">Friendly</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <FiChevronDown className="h-4 w-4" />
            </div>
          </div>
        </div>

        {/* Report Verbosity */}
        <div>
          <label
            htmlFor="verbosity"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Report Verbosity
          </label>
          <div className="relative">
            <select
              name="verbosity"
              value={settings.verbosity}
              onChange={handleChange}
              className="appearance-none w-full bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="concise">
                Concise (Minimal details, highlights only)
              </option>
              <option value="standard">
                Standard (Balanced level of detail)
              </option>
              <option value="verbose">
                Verbose (Detailed explanations, analysis, and insights)
              </option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <FiChevronDown className="h-4 w-4" />
            </div>
          </div>
        </div>

        {/* Data Representation */}
        <div>
          <label
            htmlFor="dataRepresentation"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Data Representation
          </label>
          <div className="relative">
            <select
              name="dataRepresentation"
              value={settings.dataRepresentation}
              onChange={handleChange}
              className="appearance-none w-full bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="text-heavy">
                Text-heavy (Emphasizes written explanations)
              </option>
              <option value="visual-heavy">
                Visual-heavy (More graphs, charts, and infographics)
              </option>
              <option value="balanced">
                Balanced (Combination of text and visuals)
              </option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <FiChevronDown className="h-4 w-4" />
            </div>
          </div>
        </div>

        {/* Toggles (Using Headless UI Switch) */}
        <div className="space-y-4">
          <Switch.Group>
            <div className="flex items-center">
              <Switch
                checked={settings.includeExecutiveSummary}
                onChange={(value) =>
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    includeExecutiveSummary: value,
                  }))
                }
                className={`${
                  settings.includeExecutiveSummary
                    ? "bg-blue-600"
                    : "bg-gray-200"
                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
              >
                <span
                  className={`${
                    settings.includeExecutiveSummary && "translate-x-4"
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                />
              </Switch>
              <Switch.Label className="ml-4 block text-sm text-gray-700">
                Executive Summary
              </Switch.Label>
            </div>
          </Switch.Group>

          <Switch.Group>
            <div className="flex items-center">
              <Switch
                checked={settings.includeSummaryTOC}
                onChange={(value) =>
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    includeSummaryTOC: value,
                  }))
                }
                className={`${
                  settings.includeSummaryTOC ? "bg-blue-600" : "bg-gray-200"
                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
              >
                <span
                  className={`${
                    settings.includeSummaryTOC && "translate-x-4"
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                />
              </Switch>
              <Switch.Label className="ml-4 block text-sm text-gray-700">
                Summary and table of contents
              </Switch.Label>
            </div>
          </Switch.Group>

          <Switch.Group>
            <div className="flex items-center">
              <Switch
                checked={settings.includeReferencesCitations}
                onChange={(value) =>
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    includeReferencesCitations: value,
                  }))
                }
                className={`${
                  settings.includeReferencesCitations
                    ? "bg-blue-600"
                    : "bg-gray-200"
                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
              >
                <span
                  className={`${
                    settings.includeReferencesCitations && "translate-x-4"
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                />
              </Switch>
              <Switch.Label className="ml-4 block text-sm text-gray-700">
                References & Citations
              </Switch.Label>
            </div>
          </Switch.Group>
        </div>

        {/* System Instructions */}
        {/* <div>
          <label
            htmlFor="systemInstructions"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            System Instructions
          </label>
          <textarea
            name="systemInstructions"
            value={settings.systemInstructions}
            onChange={handleChange}
            className="w-full border border-gray-300 border-solid rounded-md py-2 px-3 text-sm leading-5 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            rows="6"
            placeholder="Enter system instructions here..."
          ></textarea>
        </div> */}
      </div>
      <div className="mt-8">
        <button
          onClick={handleSaveSettings}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save changes
        </button>
      </div>
    </div>
  );
};

export default ReportSettings;
