import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment, useState, useEffect, useRef } from "react";
import { LuArrowUp } from "react-icons/lu";
import { PiGraph, PiTable, PiVisor } from "react-icons/pi";

const QueryModalSkeleton = () => (
  <div className="animate-pulse space-y-6 p-6">
    {/* Header Skeleton */}
    <div className="flex justify-between items-center border-b border-gray-200 pb-4">
      <div className="w-48 h-6 bg-gray-200 rounded"></div>
    </div>
    {/* Content Skeleton */}
    <div className="space-y-4">
      <div className="h-8 w-full bg-gray-200 rounded"></div>
      <div className="h-6 w-3/4 bg-gray-200 rounded"></div>
      <div className="h-6 w-5/6 bg-gray-200 rounded"></div>
      <div className="h-8 w-full bg-gray-200 rounded"></div>
      <div className="h-10 w-full bg-gray-200 rounded"></div>
    </div>
  </div>
);

export const QueryModal = ({ isOpen, onClose, message }) => {
  const [query, setQuery] = useState("");
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const [pastQueries] = useState([
    "HP Envy 7640 screen replacement cost",
    "HP 7640 disassembly guide",
    "Touch screen calibration process",
  ]);

  // New state for modal-level loading
  const [isLoadingModal, setIsLoadingModal] = useState(true);

  useEffect(() => {
    if (isOpen) {
      // When modal opens, show skeleton for 1.5 seconds
      setIsLoadingModal(true);
      const timer = setTimeout(() => {
        setIsLoadingModal(false);
      }, 1500);

      if (message) {
        setQuery(message);
        submitQuery(message);
      }
      return () => clearTimeout(timer);
    }
  }, [isOpen, message]);

  const submitQuery = (queryText) => {
    if (!queryText.trim()) return;

    // Add user query message
    setMessages((prev) => [
      ...prev,
      {
        id: Date.now(),
        content: queryText,
        isAI: false,
      },
    ]);
    // Simulate AI response
    setMessages((prev) => [
      ...prev,
      {
        id: Date.now() + 1,
        content: {
          steps: [
            "Determine balancing necessity through bush resonance analysis",
            "Follow torque specs from Figure 7a1 for Q3 wheel assembly",
          ],
          measures: [
            ["A. Max +", "B. Available +", "C. Optimal"],
            ["D. Tolerance", "E. Variance", "F. Adjusted"],
          ],
          queries: ["Query #1", "Query #2", "Query #3"],
        },
        isAI: true,
      },
    ]);
    setQuery("");
    scrollToBottom();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitQuery(query);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25 backdrop-blur-sm" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white shadow-2xl transition-all flex flex-col h-[90vh]">
                <div className="flex justify-between items-center p-6 border-b border-gray-200">
                  <DialogTitle
                    as="h1"
                    className="text-xl font-semibold text-gray-900"
                  >
                    RCA Agent
                  </DialogTitle>
                </div>

                {isLoadingModal ? (
                  <QueryModalSkeleton />
                ) : (
                  <div className="max-w-4xl mx-auto p-6 pt-0 space-y-8 overflow-y-auto">
                    {/* Header Section */}
                    <div className="space-y-4">
                      <h1 className="text-2xl font-bold text-gray-900">
                        Why my air filter FX910N keeps getting noisy after some
                        time, what can I do to fix?
                      </h1>
                      <div className="grid grid-cols-1 gap-4">
                        <div className="space-y-1">
                          <span className="text-sm text-gray-500 mr-5">
                            STATUS
                          </span>
                          <span className="px-2.5 py-1 rounded-full bg-green-100 text-green-800 text-sm">
                            Completed
                          </span>
                        </div>
                        <div className="space-y-1">
                          <span className="text-sm text-gray-500 mr-5">
                            ASSIGNEE
                          </span>
                          <span className="text-gray-900">Mark Scoutt</span>
                        </div>
                        <div className="space-y-1">
                          <span className="text-sm text-gray-500 mr-5">
                            LABEL
                          </span>
                          <span className="px-2.5 py-1 rounded-full bg-blue-100 text-blue-800 text-sm">
                            Feature
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Content Section */}
                    <div className="prose border-solid border-gray-200 p-6 bg-gray-50 rounded-md">
                      <p className="text-gray-700">
                        Your air filter keeps getting noisy due to:
                      </p>
                      <ol className="list-decimal list-inside text-gray-700">
                        <li>
                          <strong>Clogged Filter</strong>: Restricts airflow,
                          making the system work harder.
                        </li>
                        <li>
                          <strong>Improper Installation</strong>: Loose filters
                          can vibrate or whistle.
                        </li>
                        <li>
                          <strong>High MERV Rating</strong>: Too dense a filter
                          creates resistance and noise.
                        </li>
                        <li>
                          <strong>Duct Leaks</strong>: Gaps cause whistling
                          sounds as air escapes.
                        </li>
                        <li>
                          <strong>Blower Issues</strong>: A struggling fan
                          increases turbulence and noise.
                        </li>
                      </ol>
                      <p className="text-gray-700">
                        Check and replace the filter, ensure proper
                        installation, and inspect ducts/blower for issues.
                      </p>
                    </div>
                    {/* Confidence Section */}
                    <div className="flex items-center gap-2">
                      <span className="text-gray-500">CONFIDENCE</span>
                      <span className="px-2.5 py-1 rounded-full bg-yellow-100 text-yellow-800 text-sm">
                        Medium
                      </span>
                    </div>
                    {/* Visualization Tools */}
                    <div className="flex gap-4">
                      <button className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md text-gray-700 text-sm flex items-center gap-2">
                        <PiVisor className="w-4 h-4" />
                        <span>Visualize Data</span>
                      </button>
                      <button className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md text-gray-700 text-sm flex items-center gap-2">
                        <PiGraph className="w-4 h-4" />
                        <span>Knowledge Graph</span>
                      </button>
                      <button className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md text-gray-700 text-sm flex items-center gap-2">
                        <PiTable className="w-4 h-4" />
                        <span>Tables Used</span>
                      </button>
                    </div>
                    {/* Query Input */}
                    <div className="flex gap-3">
                      <input
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Type here..."
                        className="flex-1 rounded-xl border border-solid border-gray-200 p-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="p-4 rounded-xl bg-blue-600 hover:bg-blue-700 transition-colors text-white"
                      >
                        <LuArrowUp className="w-6 h-6" />
                      </button>
                    </div>
                    {/* Planning Section */}
                    <div className="border-t border-gray-200 pt-6">
                      <h2 className="text-lg font-semibold text-gray-900 mb-4">
                        PLANNING
                      </h2>
                      <div className="space-y-2">
                        <h3 className="text-sm font-medium text-gray-500">
                          PAST QUERIES
                        </h3>
                        <div className="space-y-2">
                          {pastQueries.map((q, index) => (
                            <div
                              key={index}
                              className="p-3 bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer transition-colors"
                            >
                              <span className="text-gray-700">{q}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div ref={messagesEndRef} />
                  </div>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
