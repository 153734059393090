export const MENU_ITEMS = [
  {
    path: "/portal/home",
    icon: "pi pi-home",
    label: "Dashboard",
    tooltip: "View analytics and key metrics for your workspace",
    accessIdentifier: "viewDashboard",
    roles: ["BUSINESS_USER", "USER", "SUPER_ADMIN"],
  },
  {
    path: "/portal/data",
    icon: "pi pi-database",
    label: "Data",
    tooltip: "Manage and explore your workspace data",
    accessIdentifier: "viewWorkspaceData",
    roles: ["USER", "SUPER_ADMIN"],
  },
  {
    path: "/portal/model",
    icon: "pi pi-sitemap",
    label: "Train Model",
    tooltip: "Train and fine-tune AI models with your data",
    accessIdentifier: "viewTrainModel",
    roles: ["SUPER_ADMIN", "USER"],
  },
  {
    path: "/portal/run",
    icon: "pi pi-play",
    label: "Run Model",
    tooltip: "Execute and test trained models",
    accessIdentifier: "viewRunModel",
    roles: ["SUPER_ADMIN", "USER"],
  },
  {
    path: "/portal/members",
    icon: "pi pi-users",
    label: "Members",
    tooltip: "Manage workspace members and their permissions",
    accessIdentifier: "viewMembers",
    roles: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/portal/chat",
    icon: "pi pi-comments",
    label: "Data Talk",
    tooltip: "Chat with AI about your data and get insights",
    accessIdentifier: "viewDataTalk",
    roles: ["SUPER_ADMIN", "USER"],
  },
  {
    path: "/portal/reports",
    icon: "pi pi-file",
    label: "Reports",
    tooltip: "View and manage generated reports and analyses",
    accessIdentifier: "viewReports",
    roles: ["SUPER_ADMIN", "USER"],
  },
];

export const COMMON_MENU_ITEMS = [
  {
    path: "/portal/workspaces",
    icon: "pi pi-folder",
    label: "Workspaces",
    tooltip: "Switch between different workspaces and manage settings",
    accessIdentifier: "viewWorkspaceData",
    roles: ["SUPER_ADMIN", "USER"],
  },
  {
    path: "/portal/actions",
    icon: "pi pi-folder",
    label: "Actions",
    tooltip: "Reports where actions required",
    accessIdentifier: "viewActions",
    roles: ["SUPER_ADMIN", "USER"],
  },
];
