import React, { useState, useRef, useEffect, useMemo } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { FaUser, FaChevronDown, FaTimes } from "react-icons/fa";
import useWorkspaceStore from "../../stores/workspaceStore";
import useReportStore from "../../stores/reportStore";
import { useParams } from "react-router-dom";

const getInitials = (name) => {
  return name
    ?.split(" ")
    ?.map((n) => n[0])
    ?.join("")
    ?.toUpperCase()
    ?.slice(0, 2);
};

const MultiApproverSelector = ({
  approvers = [],
  onShowToast,
  fetchAndsetLatestReport,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const [isFetchingApprovers, setIsFetchingApprovers] = useState(false);
  const { reportId } = useParams();

  const { selectedWorkspace, workspaceUsers, fetchWorkspaceUsers } =
    useWorkspaceStore();

  const {
    addReportApprover,
    isUpdatingApprover,
    removeReportApprover,
    removeAllReportApprover,
  } = useReportStore();

  useEffect(() => {
    if (selectedWorkspace?.workspace_id) {
      fetchWorkspaceUsers(selectedWorkspace.workspace_id);
    }
  }, [selectedWorkspace, fetchWorkspaceUsers]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  // Get approver IDs for easy checking
  const approverIds = approvers.map((approver) => approver.user_id);

  // Filter users that are not already approvers and match search term
  const filteredUsers = workspaceUsers.filter(
    (user) =>
      !approverIds.includes(user.user_id) &&
      user?.name?.toLowerCase()?.includes(searchTerm.toLowerCase()),
  );

  const handleAddApprover = async (user) => {
    if (!user || !reportId) return;

    try {
      // Call the API to add the approver
      setIsFetchingApprovers(true);
      await addReportApprover(reportId, user.user_id);
      onShowToast("success", "Added Approver", "Approver added successfully");
      await fetchAndsetLatestReport();
      // Add the new approver to the list (optimistic update)
      // const newApprovers = [...approvers, { ...newApprover, user  }]; // Fixes: as its expect user object
      // onApproversChange(newApprovers);

      setIsOpen(false);
      setSearchTerm("");
    } catch (error) {
      console.error("Error adding approver:", error);
      onShowToast("error", "Failed", "Error While adding approver");
    } finally {
      setIsFetchingApprovers(false);
    }
  };

  const handleRemoveApprover = async (userId) => {
    try {
      setIsFetchingApprovers(true);
      await removeReportApprover(reportId, userId);
      onShowToast(
        "success",
        "Removed Approver",
        "Approver removed successfully",
      );
      await fetchAndsetLatestReport();
    } catch (error) {
      console.error("Error removing approver:", error);
      onShowToast("error", "Failed", "Error While removing approver");
    } finally {
      setIsFetchingApprovers(false);
    }
  };

  const clearAllApprovers = async () => {
    setIsFetchingApprovers(true);
    try {
      await removeAllReportApprover(reportId);
      onShowToast(
        "success",
        "Removed All Approvers",
        "All approvers removed successfully",
      );
      await fetchAndsetLatestReport();
    } catch (error) {
      console.error("Error removing approver:", error);
      onShowToast("error", "Failed", "Error While removing approvers");
    } finally {
      setIsFetchingApprovers(false);
    }
    setIsOpen(false);
  };

  return (
    <div className="">
      {/* Display selected approvers */}
      <div className="flex flex-wrap gap-2">
        {approvers.length > 0 ? (
          approvers.map((approver) => (
            <div
              key={approver.user_id}
              className="border-solid border border-gray-300 flex items-center bg-white rounded-full px-2 py-1 text-sm"
            >
              {approver?.user?.avatar ? (
                <img
                  src={approver.avatar}
                  alt={approver?.user?.name}
                  className="h-5 w-5 rounded-full mr-2"
                />
              ) : (
                <div className="h-5 w-5 rounded-full bg-gray-300 flex items-center justify-center text-xs font-bold mr-2">
                  {getInitials(approver?.user?.name)}
                </div>
              )}
              <span className="mr-2">{approver?.user?.name}</span>
              <button
                onClick={() => handleRemoveApprover(approver.user_id)}
                className="text-gray-500 hover:text-gray-700 bg-white"
                aria-label={`Remove ${approver?.user?.name}`}
                disabled={isUpdatingApprover || isFetchingApprovers}
              >
                <FaTimes size={12} />
              </button>
            </div>
          ))
        ) : (
          <div className="flex items-center text-gray-500 text-sm">No approvers assigned</div>
        )}

        {/* Approver selector dropdown */}
        <Popover className="relative inline-block text-left">
          {({ open, close }) => (
            <>
              <PopoverButton
                className="border-solid border border-gray-300 flex items-center text-gray-700 bg-white hover:bg-gray-200 rounded-md p-2 text-sm w-full"
                onClick={() => setIsOpen(!isOpen)}
                disabled={isUpdatingApprover || isFetchingApprovers}
              >
                <FaUser className="h-4 w-4 mr-2" />
                <span className="flex-grow text-left">
                  {isUpdatingApprover || isFetchingApprovers
                    ? "Processing..."
                    : "Add"}
                  {/* //aproval flow */}
                </span>
                <FaChevronDown className="h-3 w-3 ml-2" />
              </PopoverButton>

              {open && (
                <PopoverPanel
                  static
                  className="absolute z-10 mt-1 w-64 max-h-80 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none left-0"
                >
                  <div className="p-2">
                    <input
                      ref={inputRef}
                      type="text"
                      placeholder="Search people..."
                      className="w-full px-3 py-2 text-sm leading-5 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className="py-1">
                    {approvers.length > 0 && (
                      <button
                        className="flex items-center w-full bg-transparent px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                        onClick={() => {
                          clearAllApprovers();
                          close();
                        }}
                        disabled={isUpdatingApprover}
                      >
                        <FaTimes className="h-4 w-4 mr-3" />
                        Clear all approvers
                      </button>
                    )}

                    {filteredUsers.length > 0 ? (
                      filteredUsers.map((user) => (
                        <button
                          key={user.user_id}
                          className="flex items-center w-full bg-transparent px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => {
                            handleAddApprover(user);
                            close();
                          }}
                          disabled={isUpdatingApprover || isFetchingApprovers}
                        >
                          {user.avatar ? (
                            <img
                              src={user.avatar}
                              alt={user.name}
                              className="h-6 w-6 rounded-full mr-3"
                            />
                          ) : (
                            <div className="h-6 w-6 rounded-full bg-gray-300 flex items-center justify-center text-xs font-bold mr-3">
                              {getInitials(user.name)}
                            </div>
                          )}
                          {user.name}
                        </button>
                      ))
                    ) : (
                      <div className="px-4 py-2 text-sm text-gray-500">
                        {searchTerm
                          ? "No matching users found"
                          : "All users are already approvers"}
                      </div>
                    )}
                  </div>
                </PopoverPanel>
              )}
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};

export default MultiApproverSelector;
