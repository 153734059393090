import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRobot, FaCogs, FaHome } from 'react-icons/fa';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 to-blue-900">
      <div className="text-center p-8 relative">
        {/* Animated Robot */}
        <div className="absolute -top-20 left-1/2 transform -translate-x-1/2">
            <div className="animate-bounce">
                <FaRobot className="text-8xl text-blue-400" />
            </div>
        </div>

        {/* Rotating Cogs */}
        <div className="absolute top-0 right-0 animate-spin-slow">
          <FaCogs className="text-4xl text-gray-600 opacity-50" />
        </div>
        <div className="absolute bottom-0 left-0 animate-spin-reverse-slow">
          <FaCogs className="text-3xl text-gray-600 opacity-50" />
        </div>

        {/* Main Content */}
        <div className="bg-white/10 backdrop-blur-lg rounded-xl p-8 shadow-2xl border border-white/20">
          <h1 className="text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">
            404
          </h1>
          <div className="mt-4 space-y-2">
            <h2 className="text-2xl font-semibold text-white">AI Lost in Space</h2>
            <p className="text-blue-200">
              Our AI is searching for your page in the digital universe...
            </p>
            <p className="text-sm text-blue-300">
              But it seems to have encountered an automation anomaly!
            </p>
          </div>

          {/* Binary Rain Effect */}
          <div className="mt-6 text-xs font-mono text-blue-400/30 overflow-hidden h-8">
            <div className="animate-matrix">
               00000001 10010100 
            </div>
          </div>

          {/* Action Button */}
          <button
            onClick={() => navigate('/')}
            className="mt-8 px-6 py-3 bg-blue-500 text-white rounded-full 
                     hover:bg-blue-600 transition-all duration-300 
                     transform hover:scale-105 flex items-center justify-center 
                     space-x-2 mx-auto group"
          >
            <FaHome className="group-hover:animate-pulse" />
            <span>Return to Homepage</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;