import React, { createContext, useContext, useState, useEffect } from "react";
import {
  useClerk,
  useUser as useClerkUser,
  useOrganization,
} from "@clerk/clerk-react";

const UserContext = createContext();
const retrictedSubDomain = ["spicesage.co", "sce.com"];
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const { user: clerkUser, isSignedIn, isLoaded } = useClerkUser();
  const {
    organization,
    isLoaded: isOrgLoaded,
    memberships,
  } = useOrganization();
  const { signOut } = useClerk();

  // Update user state when Clerk user changes
  useEffect(() => {
    if (isLoaded && isSignedIn && clerkUser) {
      // Map Clerk user data to your application's user format
      const userData = {
        id: clerkUser.id,
        email: clerkUser.primaryEmailAddress?.emailAddress,
        firstName: clerkUser.firstName,
        lastName: clerkUser.lastName,
        imageUrl: clerkUser.imageUrl,
        isRCAEnabled: !retrictedSubDomain.some((subdomain) =>
          user?.email?.includes(subdomain)
        ),
        // Add any other user properties you need
      };

      // Debug organization state
      console.log("Organization state:", {
        clerkUser,
        organization,
        isOrgLoaded,
        memberships,
      });

      // Add organization information if available
      if (isOrgLoaded && organization) {
        // Find the current organization membership to get the role
        const currentOrgMembership = clerkUser.organizationMemberships?.find(
          (membership) => membership.organization.id === organization.id
        );

        const userRole = currentOrgMembership?.role;

        userData.organization = {
          id: organization.id,
          name: organization.name,
          slug: organization.slug,
          role: userRole, // This contains the user's role like 'org:admin' or 'org:member'
        };

        console.log("User's role in organization:", userRole);
      }

      setUser(userData);
      // Store in localStorage for compatibility with existing code
      localStorage.setItem("user", JSON.stringify(userData));
    } else if (isLoaded && !isSignedIn) {
      setUser(null);
      localStorage.removeItem("user");
    }
  }, [isLoaded, isSignedIn, clerkUser, isOrgLoaded, organization]);

  const loginUser = (userData) => {
    // This is kept for compatibility with existing code
    // In most cases, Clerk will handle the login process
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const logoutUser = async () => {
    try {
      // Use Clerk's signOut method
      await signOut();
      // Clear local storage
      setUser(null);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpirationTime");
      localStorage.removeItem("refresh_token");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        loginUser,
        logoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
