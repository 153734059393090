import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";

/**
 * RejectionModal component for collecting rejection reasons
 *
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to call when closing the modal
 * @param {Function} props.onReject - Function to call when rejecting with reason
 * @param {string} props.title - Modal title (optional)
 */
const ApprovalRejectionModal = ({
  isOpen,
  onClose,
  onSubmit,
  title = "",
  action = "",
}) => {
  const [commentText, setCommentText] = useState("");

  const handleSubmit = () => {
    onSubmit(commentText);
    setCommentText("");
  };

  useEffect(() => {
    setCommentText("");
  }, [isOpen]);
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded max-w-md mx-auto p-6 shadow-xl">
          <Dialog.Title className="text-lg font-medium text-gray-900 mb-4">
            {title}
          </Dialog.Title>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Reason for {action === "reject" ? "rejection" : "approval"}
            </label>
            <textarea
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              rows={4}
              placeholder="Please provide a reason..."
              autoFocus
            />
          </div>

          <div className="flex justify-end space-x-2">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            {action === "reject" && <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-red-500 text-white rounded-md text-sm font-medium hover:bg-red-600"
              disabled={!commentText.trim()}
            >
              Reject
            </button>}
            {action === "approve" && <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-green-500 text-white rounded-md text-sm font-medium hover:bg-green-600"
              disabled={!commentText.trim()}
            >
              Approve
            </button>}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ApprovalRejectionModal;
