import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

// Global tooltip component that handles all tooltip instances
export const GlobalTooltip = () => {
  return (
    <ReactTooltip
      anchorSelect="[data-tooltip-content]"
      effect="solid"
      delayShow={400}
      className="max-w-xs z-50 text-sm bg-gray-900 text-white px-2 py-1 rounded shadow-lg"
    />
  );
};

// Legacy component for backward compatibility
const Tooltip = ({ content, children, place = "top", className = "" }) => {
  return (
    <>
      <div data-tooltip-content={content}>{children}</div>
      <ReactTooltip
        anchorSelect="[data-tooltip-content]"
        effect="solid"
        place={place}
        className={`max-w-xs z-50 text-sm bg-gray-900 text-white px-2 py-1 rounded shadow-lg ${className}`}
      />
    </>
  );
};

export default Tooltip;
