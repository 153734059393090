import React, { useEffect, useState, useRef } from "react";
import useWorkspaceStore from "../../../stores/workspaceStore";
import { CreateWorkspaceModal } from "./create-workspace-modal";
import { useUser } from "../../../context/userContext";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

export const WorkspaceSelector = ({ isCollapsed }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useUser();
  const {
    workspaces,
    selectedWorkspace,
    setSelectedWorkspace,
    fetchWorkspaces,
    isFetchingWorkspaces,
  } = useWorkspaceStore();

  const menuRef = useRef(null);
  useOnClickOutside(menuRef, () => setIsMenuOpen(false));

  useEffect(() => {
    fetchWorkspaces(user.id);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    if (!isMenuOpen) {
      setTimeout(() => {
        const searchInput = document.querySelector(".workspace-search");
        if (searchInput) searchInput.focus();
      }, 100);
    }
  };

  const handleWorkspaceChange = (workspace) => {
    setSelectedWorkspace(workspace);
    setIsMenuOpen(false);
    setSearchQuery("");
    // Animate workspace transition
    const button = document.querySelector(".workspace-button");
    if (button) {
      button.classList.add("workspace-transition");
      setTimeout(() => button.classList.remove("workspace-transition"), 300);
    }
  };

  const filteredWorkspaces = workspaces.filter(
    (workspace) =>
      workspace?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      false
  );

  if (isCollapsed) return null;
  return (
    <>
      <div className="relative" ref={menuRef}>
        <div className="flex items-center justify-between mb-1.5">
          <span className="text-sm font-medium text-gray-500">Workspace</span>
          <button
            className="text-sm bg-transparent text-gray-500 hover:text-gray-700 flex items-center px-1.5 py-0.5 rounded hover:bg-gray-50"
            onClick={() => setIsCreateWorkspaceModalOpen(true)}
            data-tooltip-content="Create new workspace"
          >
            <i className="pi pi-plus text-sm mr-1"></i>
            New
          </button>
        </div>
        <button
          onClick={toggleMenu}
          disabled={isFetchingWorkspaces && workspaces.length == 0}
          className="flex items-center w-full px-2.5 py-1.5 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 rounded-md transition-all duration-200 border border-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-200"
        >
          <div className="flex-1 flex items-center">
            {isFetchingWorkspaces && workspaces.length == 0 ? (
              <div className="flex items-center">
                <i className="pi pi-spinner animate-spin text-gray-400 mr-2 text-sm"></i>
                <span className="text-gray-500">Loading workspaces...</span>
              </div>
            ) : (
              <>
                <div className="w-1.5 h-1.5 bg-green-500 rounded-full mr-2"></div>
                <span className="text-left truncate">
                  {selectedWorkspace
                    ? selectedWorkspace.name
                    : "Select Workspace"}
                </span>
              </>
            )}
          </div>
          <i
            className={`pi ${
              isMenuOpen ? "pi-chevron-up" : "pi-chevron-down"
            } ml-1.5 text-gray-400 text-sm`}
          ></i>
        </button>
        {isMenuOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg overflow-hidden transform transition-all duration-150 ease-out">
            <div className="p-1.5 border-b border-gray-100">
              <div className="relative">
                <i className="pi pi-search absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"></i>
                <input
                  type="text"
                  className="workspace-search w-full pl-7 pr-2 py-1.5 text-sm bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="Search workspaces..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="max-h-48 overflow-y-auto">
              {filteredWorkspaces.length === 0 ? (
                <div className="px-3 py-2 text-sm text-gray-500 text-center">
                  No workspaces found
                </div>
              ) : (
                <ul className="py-1">
                  {filteredWorkspaces.map((workspace) => (
                    <li key={workspace.workspace_id}>
                      <button
                        onClick={() => handleWorkspaceChange(workspace)}
                        className={`w-full bg-transparent text-left px-3 py-1.5 text-sm hover:bg-gray-50 transition-colors duration-150 flex items-center justify-between ${
                          selectedWorkspace?.workspace_id ===
                          workspace.workspace_id
                            ? "bg-blue-50 text-blue-600"
                            : "text-gray-700"
                        }`}
                      >
                        <div className="flex items-center">
                          <div
                            className={`w-1.5 h-1.5 rounded-full mr-2 ${
                              selectedWorkspace?.workspace_id ===
                              workspace.workspace_id
                                ? "bg-blue-500"
                                : "bg-gray-300"
                            }`}
                          ></div>
                          <span>{workspace.name}</span>
                        </div>
                        {selectedWorkspace?.workspace_id ===
                          workspace.workspace_id && (
                          <i className="pi pi-check text-blue-500 text-sm"></i>
                        )}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
      <CreateWorkspaceModal
        isOpen={isCreateWorkspaceModalOpen}
        onClose={() => setIsCreateWorkspaceModalOpen(false)}
      />
    </>
  );
};
