import React, { useState, useEffect, useRef } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  FaEllipsisH,
  FaComment,
  FaMagic,
  FaProjectDiagram,
  FaTable,
  FaRedo,
} from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import CScore from "./cscore";
import References from "./references";
import CommentSection from "./comments-section";
import { AiOutlineClose, AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import AssigneeSelector from "./assignee-selector";
import LabelSelector from "./label-selector";
import ReportService from "../../service/ReportService";
import VisualizationComponent from "./visualization-component";
import KnowledgeGraphComponent from "./knowledge-graph-component";
import TablesUsedComponent from "./tables-used-component";
import { FaGripVertical } from "react-icons/fa";
import { useUser } from "../../context/userContext";

const users = [
  { id: 1, name: "Vivek Vishwakarma", avatar: null },
  { id: 2, name: "Shivansh Mehendiratta", avatar: null },
  { id: 3, name: "Sainyam Galhotra", avatar: null },
  { id: 4, name: "Kevin", avatar: null },
  { id: 5, name: "Jaspreet", avatar: null },
];
const allLabels = [
  { text: "Bug", color: "red" },
  { text: "Feature", color: "purple" },
  { text: "Go to market", color: "pink" },
  { text: "Improvement", color: "blue" },
  { text: "To Discuss", color: "yellow" },
];

const QuestionItem = ({
  question,
  report,
  onShowToast,
  onDeleteQuestion,
  setReport,
  dragHandleProps,
  disableDeleteButton = false,
}) => {
  const [newQuestionText, setNewQuestionText] = useState(question.question);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [status, setStatus] = useState(question.status || "draft");
  const [showComments, setShowComments] = useState(false);
  const [isEditing, setIsEditing] = useState(!question.answer);
  const [answer, setAnswer] = useState(question.answer || "");
  const [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
  const [assignee, setAssignee] = useState(question.assignee || null);
  const [labels, setLabels] = useState(question.labels || []);
  const questionInputRef = useRef(null);
  const [showVisualization, setShowVisualization] = useState(false);
  const [isVisualizationLoading, setIsVisualizationLoading] = useState(false);
  const [showKnowledgeGraph, setShowKnowledgeGraph] = useState(false);
  const [isKnowledgeGraphLoading, setIsKnowledgeGraphLoading] = useState(false);
  const [showTablesUsed, setShowTablesUsed] = useState(false);
  const [isTablesUsedLoading, setIsTablesUsedLoading] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [regenerateFeedback, setRegenerateFeedback] = useState("");
  const [isRegenerating, setIsRegenerating] = useState(false);
  const feedbackInputRef = useRef(null);

  useEffect(() => {
    setNewQuestionText(question.question);
    setIsEditing(!question.answer);
    setAnswer(question.answer || "");
    loadComments();
    setAssignee(question.assignee || null);
    setLabels(question.labels || []);
  }, [question]);

  const loadComments = async () => {
    try {
      const commentsData = await ReportService.getCommentsForQuestion(
        report.report_id,
        question.question_id,
      );
      // Parse and transform comments data to match schema
      const parsedComments = commentsData.map((comment) => ({
        id: comment.comment_id,
        reportId: comment.report_id,
        questionId: comment.question_id,
        content:
          typeof comment.content === "string"
            ? JSON.parse(comment.content)
            : comment.content,
        authorId: comment.author_id,
        authorName: comment.author_name,
        parentCommentId: comment.parent_comment_id,
        isResolved: comment.is_resolved,
        visibility: comment.visibility,
        createdAt: new Date(comment.created_at),
        updatedAt: new Date(comment.updated_at),
        deletedAt: comment.deleted_at ? new Date(comment.deleted_at) : null,
      }));
      setComments(parsedComments);
    } catch (error) {
      console.error("Error loading comments:", error);
    }
  };

  useEffect(() => {
    if (isEditing && questionInputRef.current) {
      questionInputRef.current.focus();
    }
  }, [isEditing]);

  const updateReport = async (updatedQuestion) => {
    setReport((prevReport) => {
      const updatedQuestions = prevReport.questions.map((q) =>
        q.question_id === updatedQuestion.question_id ||
        q.question_id === question.question_id
          ? { ...q, ...updatedQuestion }
          : q,
      );

      return {
        ...prevReport,
        questions: updatedQuestions,
        updatedAt: new Date().toISOString(),
      };
    });

    try {
      await ReportService.updateQuestion(
        report.report_id,
        updatedQuestion.question_id,
        updatedQuestion,
      );
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  const handleContainerClick = () => {
    if (isEditing && questionInputRef.current) {
      questionInputRef.current.focus();
    }
  };

  const handleLabelsChange = (newLabels) => {
    setLabels(newLabels);
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id ? { ...q, labels: newLabels } : q,
    );
    updateReport(updatedQuestions);
  };

  const handleQuestionChange = (e) => {
    setNewQuestionText(e.target.value);
  };

  const handleGenerateAnswer = async () => {
    if (
      !newQuestionText ||
      newQuestionText.trim().length === 0 ||
      !report ||
      !report.report_id
    ) {
      return;
    }
    setIsGeneratingAnswer(true);
    const questionData = {
      question: newQuestionText,
      report_id: report.report_id,
    };

    try {
      const response = await ReportService.addQuestion(
        report.report_id,
        questionData,
      );
      const { question_id: newQuestionId, answer: newAnswer } = response;

      setAnswer(newAnswer);

      // Update the report state using functional updates
      setReport((prevReport) => {
        const updatedQuestions = prevReport.questions.map((q) => {
          if (q.question_id === question.question_id) {
            return {
              ...q,
              question_id: newQuestionId,
              question: newQuestionText,
              answer: newAnswer,
            };
          }
          return q;
        });

        return {
          ...prevReport,
          questions: updatedQuestions,
          updatedAt: new Date().toISOString(),
        };
      });

      setIsEditing(false);
    } catch (error) {
      console.error("Error generating answer:", error);
      setAnswer({
        text: "Error generating answer. Please try again.",
        cscore: "Low",
        references: [],
      });
    } finally {
      setIsGeneratingAnswer(false);
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const { user } = useUser();

  const handleSaveComment = async () => {
    if (newComment.trim() === "") return;

    const commentData = {
      content: {
        text: newComment,
      },
      author_id: user.id, // Assuming currentUser is available in scope
      visibility: "public", // Default visibility
      parent_comment_id: null, // No parent by default
    };

    // Create optimistic comment
    const optimisticComment = {
      id: Date.now(), // Temporary ID
      ...commentData,
      createdAt: new Date(),
      authorId: commentData.author_id,
      content: commentData.content,
      isResolved: false,
    };

    // Optimistically update UI
    setComments((prevComments) => [optimisticComment, ...prevComments]);
    setNewComment("");

    try {
      await ReportService.addComment(
        report.report_id,
        question.question_id,
        commentData,
      );

      // Refresh actual data
      await loadComments();
    } catch (error) {
      console.error("Error saving comment:", error);
      // Revert optimistic update on error
      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== optimisticComment.id),
      );
      setNewComment(commentData.content.text);
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id
        ? { ...q, status: e.target.value }
        : q,
    );
    const updatedQuestion = { ...question, status: e.target.value };
    updateReport(updatedQuestion); // as its expect single object
  };

  const handleCopy = () => {
    const textToCopy = `Question: ${question.question}\nAnswer: ${answer.text}`;
    navigator.clipboard.writeText(textToCopy);
  };

  const handleDelete = () => {
    onDeleteQuestion(question.question_id);
  };
  const handleAssigneeChange = async (user) => {
    setAssignee(user?.user_id || null);
    try {
      await ReportService.updateQuestionAssignee({
        report_id: report.report_id,
        question_id: question.question_id,
        assignee: user?.user_id || null,
      });

      const updatedQuestions = report.questions.map((q) =>
        q.question_id === question.question_id
          ? { ...q, assignee: user?.user_id || null }
          : q,
      );
      const updatedQuestion = { ...question, assignee: user?.user_id || null };
      await updateReport(updatedQuestion);
      onShowToast("success", "Assignee updated successfully", "");
    } catch (error) {
      console.error("Error updating assignee:", error);
      onShowToast("error", "Error while updating assignee", "");
      // Revert the assignee state if the API call fails
      setAssignee(question.assignee);
    }
  };

  const handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
      handleGenerateAnswer();
    }
  };

  const handleVisualize = async () => {
    setShowVisualization(true);
    setIsVisualizationLoading(true);
    try {
      const response = await ReportService.visualizeReport({
        question_id: question.question_id,
        report_id: report.report_id,
        answer: question.answer.text,
      });

      const updatedQuestion = {
        ...question,
        visualization: response.visualization,
      };

      const updatedQuestions = report.questions.map((q) =>
        q.question_id === question.question_id ? updatedQuestion : q,
      );

      updateReport(updatedQuestion); // as update report expect single object
    } catch (error) {
      console.error("Error visualizing report:", error);
    } finally {
      setIsVisualizationLoading(false);
    }
  };

  const handleKnowledgeGraph = async () => {
    setShowKnowledgeGraph(true);
    setIsKnowledgeGraphLoading(true);
    try {
      const response = await ReportService.knowledgeGraphForQuestion({
        question_id: question.question_id,
        report_id: report.report_id,
      });
      const updatedQuestion = {
        ...question,
        knowledgeGraph: response.knowledgeGraph,
      };

      const updatedQuestions = report.questions.map((q) =>
        q.question_id === question.question_id ? updatedQuestion : q,
      );

      updateReport(updatedQuestion); // as update report expect single object
    } catch (error) {
      console.error("Error generating knowledge graph:", error);
    } finally {
      setIsKnowledgeGraphLoading(false);
    }
  };

  const removeVisualization = () => {
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id
        ? { ...q, visualization: null }
        : q,
    );
    setShowVisualization(false);
    const updatedQuestion = {
      ...question,
      visualization: null,
    };
    updateReport(updatedQuestion); // as update report expect single object
  };

  const removeKnowledgeGraph = () => {
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id
        ? { ...q, knowledgeGraph: null }
        : q,
    );
    setShowKnowledgeGraph(false);
    const updatedQuestion = {
      ...question,
      knowledgeGraph: null,
    };
    updateReport(updatedQuestion); // as update report expect single object
  };
  const handleTablesUsed = async () => {
    setShowTablesUsed(true);
    setIsTablesUsedLoading(true);
    try {
      const response = await ReportService.tablesUsedForQuestion({
        question_id: question.question_id,
        report_id: report.report_id,
      });
      const updatedQuestion = {
        ...question,
        tablesUsed: response.tablesUsed,
      };

      const updatedQuestions = report.questions.map((q) =>
        q.question_id === question.question_id ? updatedQuestion : q,
      );

      updateReport(updatedQuestion); // as update report expect single object
    } catch (error) {
      console.error("Error getting tables used:", error);
    } finally {
      setIsTablesUsedLoading(false);
    }
  };

  const removeTablesUsed = () => {
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id ? { ...q, tablesUsed: null } : q,
    );
    const updatedQuestion = {
      ...question,
      tablesUsed: null,
    };
    setShowTablesUsed(false);
    updateReport(updatedQuestion);
  };

  const handleRegenerateAnswer = async () => {
    if (!regenerateFeedback || regenerateFeedback.trim() === "") {
      onShowToast("error", "Please provide feedback to regenerate answer", "");
      return;
    }
    setIsRegenerating(true);
    try {
      const response = await ReportService.regenrateAnswerForQuestion({
        report_id: report.report_id,
        question_id: question.question_id,
        regenerateFeedback,
      });

      setAnswer(response.answer);
      const updatedQuestions = report.questions.map((q) =>
        q.question_id === question.question_id
          ? { ...q, answer: response.answer }
          : q,
      );
      const updatedQuestion = {
        ...question,
        answer: response.answer,
      };
      updateReport(updatedQuestion);
    } catch (error) {
      console.error("Error regenerating answer:", error);
    } finally {
      setIsRegenerating(false);
      setShowRegenerateModal(false);
      setRegenerateFeedback("");
    }
  };
  useEffect(() => {
    if (showRegenerateModal && feedbackInputRef.current) {
      feedbackInputRef.current.focus();
    }
  }, [showRegenerateModal]);
  return (
    <div
      onClick={handleContainerClick}
      className="mb-4 p-4 rounded-lg bg-gray-50/25 border-solid border border-gray-300"
    >
      <div className="flex mb-2 items-center justify-between">
        <div className="flex w-full items-center">
          {/* Drag handle */}
          {!isEditing && (
            <div
              {...dragHandleProps}
              className="mr-2 text-gray-400 hover:text-gray-600 cursor-pointer"
            >
              <FaGripVertical />
            </div>
          )}
          {isEditing ? (
            <textarea
              value={newQuestionText}
              ref={questionInputRef}
              onChange={handleQuestionChange}
              onKeyDown={handleKeyDown}
              disabled={report?.is_submitted}
              className="w-full p-2 mb-2 border-none focus:ring-0 bg-gray-100 rounded-md placeholder-gray-400 text-gray-700"
              rows="3"
              placeholder="Enter your question here..."
            />
          ) : question.question?.includes("<") &&
            question.question?.includes(">") ? (
            <h3
              className="text-lg text-gray-900"
              dangerouslySetInnerHTML={{ __html: question.question }}
            />
          ) : (
            <h3 className="text-lg text-gray-900">{question.question}</h3>
          )}
        </div>
        {isEditing && (
          <button
            onClick={handleDelete}
            disabled={disableDeleteButton}
            className="text-gray-500 bg-transparent hover:text-gray-700 cursor-pointer disabled:cursor-not-allowed"
          >
            <AiOutlineClose />
          </button>
        )}
      </div>
      {!isEditing && (
        <div className="flex flex-col gap-3">
          <div className="flex items-center">
            <div className="text-sm w-1/5 mx-4">
              <p>STATUS</p>
            </div>
            <div className="flex-grow justify-start w-4/5">
              <select
                value={status}
                onChange={handleStatusChange}
                className="text-sm text-gray-700 border-none focus:ring-0 hover:bg-gray-200 rounded px-2 py-1"
              >
                <option value="draft">Draft</option>
                <option value="completed">Completed</option>
                <option value="pending review">Pending Review</option>
              </select>
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-sm w-1/5 mx-4">
              <p>ASSIGNEE</p>
            </div>
            <div className="flex-grow justify-start w-4/5">
              <AssigneeSelector
                assignee={assignee}
                onAssigneeChange={handleAssigneeChange}
              />
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-sm w-1/5 mx-4">
              <p>LABEL</p>
            </div>
            <div className="flex-grow justify-start w-4/5">
              <div>
                <LabelSelector
                  questionId={question.question_id}
                  reportId={report.report_id}
                  labels={question.labels}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {answer.text ? (
        <>
          <div className="mt-2 p-4 bg-gray-100 rounded-md relative group">
            <button
              onClick={() => setShowRegenerateModal(true)}
              className="absolute right-3 top-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-gray-500 hover:text-gray-700 bg-white/80 hover:bg-white rounded p-1.5 flex items-center"
              title="Regenerate Answer"
              aria-label="Regenerate Answer"
              disabled={report?.is_submitted}
            >
              <FaRedo className="h-3.5 w-3.5" />
              <span className="ml-1 text-xs">Regenerate</span>
            </button>
            {answer.text?.includes("<") && answer.text?.includes(">") ? (
              <div
                dangerouslySetInnerHTML={{ __html: answer.text }}
                className="prose max-w-none"
              />
            ) : (
              <div className="prose max-w-none">
                <ReactMarkdown>{answer.text}</ReactMarkdown>
              </div>
            )}
          </div>
          <div className="mt-2 ml-4 flex items-center justify-between">
            <CScore score={answer.cscore} />
            <References references={answer.references} />
          </div>
        </>
      ) : (
        <button
          onClick={handleGenerateAnswer}
          className="mt-2 text-blue-600 hover:bg-blue-50 px-2 py-1 rounded text-sm"
          disabled={isGeneratingAnswer || !newQuestionText || report?.is_submitted}
        >
          {isGeneratingAnswer ? "Generating..." : "Ask TIA"}
        </button>
      )}
      {!isEditing && (
        <>
          <div className="flex justify-between items-center my-4">
            <div className="flex items-center space-x-2">
              {showVisualization ? (
                <div className="flex items-center bg-gray-100 rounded-lg p-1">
                  <button
                    onClick={handleVisualize}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-l-md hover:bg-gray-200 transition-colors"
                    title="Regenerate Visualization"
                  >
                    <FaMagic className="h-3.5 w-3.5" />
                  </button>
                  <div className="w-px h-4 bg-gray-300" />
                  <button
                    onClick={removeVisualization}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-r-md hover:bg-gray-200 transition-colors"
                    title="Remove Visualization"
                  >
                    <AiOutlineClose className="h-3.5 w-3.5" />
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleVisualize}
                  className="text-gray-500 hover:text-gray-700 bg-transparent flex items-center space-x-1.5 hover:bg-gray-100 rounded-md px-2 py-1 transition-colors"
                >
                  <FaMagic className="h-3.5 w-3.5" />
                  <span className="text-sm">Visualize</span>
                </button>
              )}

              {showKnowledgeGraph ? (
                <div className="flex items-center bg-gray-100 rounded-lg p-1">
                  <button
                    onClick={handleKnowledgeGraph}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-l-md hover:bg-gray-200 transition-colors"
                    title="Regenerate Knowledge Graph"
                  >
                    <FaProjectDiagram className="h-3.5 w-3.5" />
                  </button>
                  <div className="w-px h-4 bg-gray-300" />
                  <button
                    onClick={removeKnowledgeGraph}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-r-md hover:bg-gray-200 transition-colors"
                    title="Remove Knowledge Graph"
                  >
                    <AiOutlineClose className="h-3.5 w-3.5" />
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleKnowledgeGraph}
                  className="text-gray-500 hover:text-gray-700 bg-transparent flex items-center space-x-1.5 hover:bg-gray-100 rounded-md px-2 py-1 transition-colors"
                >
                  <FaProjectDiagram className="h-3.5 w-3.5" />
                  <span className="text-sm">Knowledge Graph</span>
                </button>
              )}

              {showTablesUsed ? (
                <div className="flex items-center bg-gray-100 rounded-lg p-1">
                  <button
                    onClick={handleTablesUsed}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-l-md hover:bg-gray-200 transition-colors"
                    title="Refresh Tables Used"
                  >
                    <FaTable className="h-3.5 w-3.5" />
                  </button>
                  <div className="w-px h-4 bg-gray-300" />
                  <button
                    onClick={removeTablesUsed}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-r-md hover:bg-gray-200 transition-colors"
                    title="Remove Tables Used"
                  >
                    <AiOutlineClose className="h-3.5 w-3.5" />
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleTablesUsed}
                  className="text-gray-500 hover:text-gray-700 bg-transparent flex items-center space-x-1.5 hover:bg-gray-100 rounded-md px-2 py-1 transition-colors"
                >
                  <FaTable className="h-3.5 w-3.5" />
                  <span className="text-sm">Tables Used</span>
                </button>
              )}
            </div>
            <div className="flex items-center">
              <button
                onClick={() => setShowComments(!showComments)}
                className="text-gray-500 bg-transparent hover:bg-gray-200 rounded p-1 mr-2 relative"
              >
                <FaComment className="h-4 w-4" />
                {comments.length > 0 && (
                  <span className="absolute -top-1 -right-1 bg-blue-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                    {comments.length}
                  </span>
                )}
              </button>

              <Menu as="div" className="relative inline-block text-left">
                <MenuButton className="text-gray-500 bg-transparent flex hover:bg-gray-200 rounded p-1">
                  <FaEllipsisH className="h-4 w-4" aria-hidden="true" />
                </MenuButton>
                <MenuItems className="absolute border border-solid border-gray-200 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-lg overflow-hidden bg-transparent backdrop-blur-lg shadow-lg border border-gray-200 focus:outline-none">
                  <MenuItem>
                    {({ focus }) => (
                      <button
                        onClick={handleCopy}
                        className={`${
                          focus
                            ? "bg-gray-100/70 text-gray-900"
                            : "bg-transparent text-gray-700"
                        } group flex w-full items-center px-4 py-2 text-sm`}
                      >
                        <AiOutlineCopy className="mr-2" />
                        Copy
                      </button>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ focus }) => (
                      <button
                        onClick={handleDelete}
                        disabled={report?.is_submitted}
                        className={`${
                          focus
                            ? "bg-gray-100/70 text-gray-900"
                            : "bg-transparent text-gray-700"
                        } group border-t border-solid border-0 flex w-full items-center px-4 py-2 text-sm hover:text-red-600`}
                      >
                        <AiOutlineDelete className="mr-2" />
                        Delete Question
                      </button>
                    )}
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </>
      )}
      {showComments && (
        <CommentSection
          comments={comments}
          onAddComment={handleSaveComment}
          onCommentChange={handleCommentChange}
          newComment={newComment}
        />
      )}
      {showVisualization && (
        <VisualizationComponent
          visualization={question.visualization}
          isLoading={isVisualizationLoading}
        />
      )}
      {showKnowledgeGraph && (
        <div className="h-[600px] overflow-hidden">
          <KnowledgeGraphComponent
            data={question.knowledgeGraph}
            isLoading={isKnowledgeGraphLoading}
          />
        </div>
      )}
      {showTablesUsed && (
        <TablesUsedComponent
          data={question.tablesUsed}
          isLoading={isTablesUsedLoading}
        />
      )}
      {showRegenerateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full mx-4">
            <h3 className="text-lg font-medium mb-4">Regenerate Answer</h3>
            <textarea
              ref={feedbackInputRef}
              value={regenerateFeedback}
              onChange={(e) => setRegenerateFeedback(e.target.value)}
              className="w-full p-2 rounded-md mb-4 ring-2 focus:ring-blue-500 ring-gray-500"
              rows="4"
              placeholder="Please provide feedback or specific instructions for regenerating the answer..."
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowRegenerateModal(false)}
                disabled={isRegenerating}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleRegenerateAnswer}
                disabled={isRegenerating}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
              >
                {isRegenerating ? "Regenerating..." : "Regenerate"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionItem;
