import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import KnowledgeTabLayout from "../layout/knowledgeTabLayout";
import Data from "./components/data";
import Documents from "./components/documents";
import Flows from "./components/flows";
import Integration from "./components/integration";

export const Knowledge = () => {
  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const renderContent = () => {
    if (activeIndex === 1) {
      // Documenets tab
      return (
        <div className="grid col-span-4 w-full h-full">
          <Documents />
        </div>
      );
    } else if (activeIndex === 2) {
      // Flows Tab
      return (
        <div className="grid col-span-4 w-full h-full">
          <Flows />
        </div>
      );
    } else if (activeIndex === 3) {
      // Integration Tab
      return (
        <div className="grid col-span-4 w-full h-full">
          <Integration />
        </div>
      );
    }
    // Data (Default) tab
    return (
      <div className="grid col-span-4 w-full h-full">
        <Data />
      </div>
    );
  };

  return (
    <div className="h-full overflow-hidden">
      <KnowledgeTabLayout
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        {renderContent()}
        <Toast ref={toast} position="bottom-right" />
      </KnowledgeTabLayout>
    </div>
  );
};
