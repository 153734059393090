import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { useUser } from "../../context/userContext";
import { NotificationCenter } from "../menubar/components/notification-center";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { boot } from "@intercom/messenger-js-sdk";
import OrganizationSwitcher from "../OrganizationSwitcher";
import { useOrganization } from "@clerk/clerk-react";

const Navbar = () => {
  const location = useLocation();
  const { user } = useUser();
  const { organization, isLoaded: isOrgLoaded } = useOrganization();

  useEffect(() => {
    if (user) {
      boot({
        name: user?.name,
        email: user?.email,
        user_id: user.id,
      });
    }
  }, [user]);

  // Convert path to breadcrumb segments with paths
  const getBreadcrumbs = () => {
    const paths = location.pathname.split("/").filter(Boolean);
    return paths.map((path, index) => {
      // Build the full path up to this segment
      const fullPath = "/" + paths.slice(0, index + 1).join("/");
      // Convert path to readable format (e.g., 'data' -> 'Data')
      const label = path.charAt(0).toUpperCase() + path.slice(1);
      return { label, path: fullPath };
    });
  };

  const breadcrumbs = getBreadcrumbs();

  return (
    <nav className="flex items-center justify-between px-6 py-2 border-b border-solid border-0 border-gray-200">
      {/* Left side - Breadcrumbs */}
      <div className="flex items-center">
        <div className="flex items-center text-sm text-gray-600">
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={index}>
              {index > 0 && <span className="mx-2 text-gray-400">/</span>}
              <Link
                to={crumb.path}
                className={`${
                  index === breadcrumbs.length - 1
                    ? "font-medium text-gray-800 cursor-default pointer-events-none"
                    : "text-gray-600 hover:text-gray-900 hover:underline"
                }`}
              >
                {crumb.label}
              </Link>
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Right side - User Profile & Notifications */}
      <div className="flex items-center space-x-4">
        {/* Organization Switcher */}
        <div className="w-64">
          <OrganizationSwitcher />
        </div>

        <NotificationCenter />

        {user && (
          <Menu>
            <MenuButton className="flex bg-transparent items-center space-x-2 hover:bg-gray-50 rounded-md px-2 py-1 transition-colors duration-150">
              <Avatar
                icon="pi pi-user"
                shape="circle"
                size="small"
                className="h-7 w-7 flex-shrink-0"
              />
              <span className="text-sm font-medium text-gray-700">
                {user?.name}
              </span>
              <i className="pi pi-chevron-down text-[10px] text-gray-400 ml-1" />
            </MenuButton>

            <MenuItems
              anchor="bottom"
              className="absolute right-0 mt-1 w-56 origin-top-right bg-white rounded-lg shadow-lg ring-1 ring-black/5 focus:outline-none z-50 py-1"
            >
              <div className="px-3 py-2 border-b border-solid border-0 border-gray-100">
                <p className="text-sm font-medium text-gray-900">
                  {user?.name}
                </p>
                <p className="text-xs text-gray-500">{user?.email}</p>
                {organization && (
                  <p className="text-xs text-blue-500 mt-1">
                    Organization: {organization.name}
                  </p>
                )}
              </div>

              {user?.roles?.some((role) =>
                ["admin", "super_admin"].includes(role.name.toLowerCase())
              ) && (
                <MenuItem>
                  <Link
                    to="/portal/users"
                    className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                  >
                    <i className="pi pi-users text-gray-400 mr-2" />
                    Users
                  </Link>
                </MenuItem>
              )}
              <MenuItem>
                <Link
                  to="/portal/terms-of-use"
                  className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                  <i className="pi pi-file-o text-gray-400 mr-2" />
                  Terms of Use
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="/portal/privacy-policy"
                  className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                  <i className="pi pi-shield text-gray-400 mr-2" />
                  Privacy Policy
                </Link>
              </MenuItem>
              <MenuItem>
                <a
                  href="https://help.3rdaiautomation.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150"
                >
                  <i className="pi pi-question-circle text-gray-400 mr-2" />
                  Help Center
                </a>
              </MenuItem>

              <MenuItem>
                <Link
                  to="/portal/logout"
                  className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-red-50 text-red-600 transition-colors duration-150"
                >
                  <i className="pi pi-sign-out text-red-500 mr-2" />
                  Sign Out
                </Link>
              </MenuItem>
            </MenuItems>
          </Menu>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
