import { create } from "zustand";
import ReportService from "../service/ReportService";

const useFolderStore = create((set, get) => ({
  // State
  folders: [],
  selectedFolder: null,
  loadingFolders: new Set(),
  isFetchingReports: false,
  isCreatingFolder: false,
  isCreatingReportFolder: false,
  isDeletingFolder: false,
  error: null,
  expandedFolders: new Set(),
  folderReports: {},

  toggleFolderExpansion: (folderId) => {
    set((state) => {
      const newExpandedFolders = new Set(state.expandedFolders);
      if (newExpandedFolders.has(folderId)) {
        newExpandedFolders.delete(folderId);
      } else {
        newExpandedFolders.add(folderId);
      }
      return { expandedFolders: newExpandedFolders };
    });
  },

  fetchFolderReports: async (folderId) => {
    set({ isFetchingReports: true, error: null });
    try {
      const data = await ReportService.getFolderReports(folderId);
      set((state) => ({
        folderReports: {
          ...state.folderReports,
          [folderId]: data.reports,
        },
      }));
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to fetch folder reports:", error);
    } finally {
      set({ isFetchingReports: false });
    }
  },
  // Actions
  fetchFolders: async (projectId, folderId) => {
    set((state) => ({
      loadingFolders: new Set([...state.loadingFolders, folderId]),
      error: null,
    }));
    try {
      const fetchedFolders = await ReportService.getProjectFolders(projectId);
      set({ folders: fetchedFolders });
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to fetch folders:", error);
    } finally {
      set((state) => {
        const newLoadingFolders = new Set(state.loadingFolders);
        newLoadingFolders.delete(folderId);
        return { loadingFolders: newLoadingFolders };
      });
    }
  },

  setSelectedFolder: (folder) => {
    set({ selectedFolder: folder });
  },

  createFolder: async (folderData) => {
    set({ isCreatingFolder: true, error: null });
    try {
      const createdFolder = await ReportService.createFolder(folderData);
      set((state) => ({
        folders: [...state.folders, createdFolder],
        selectedFolder: createdFolder,
      }));
      return createdFolder;
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to create folder:", error);
      throw error;
    } finally {
      set({ isCreatingFolder: false });
    }
  },

  createReportFolder: async (reportId, folderId) => {
    set({ isCreatingReportFolder: true, error: null });
    try {
      const result = await ReportService.createReportFolder(reportId, folderId);
      return result;
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to create report folder:", error);
      throw error;
    } finally {
      set({ isCreatingReportFolder: false });
    }
  },

  updateFolders: (updatedFolders) => {
    set({ folders: updatedFolders });
  },

  deleteFolder: async (folderId) => {
    set({ isDeletingFolder: true, error: null });
    try {
      await ReportService.deleteFolder(folderId);
      set((state) => ({
        folders: state.folders.filter(
          (folder) => folder.folder_id !== folderId
        ),
        selectedFolder:
          state.selectedFolder?.id === folderId ? null : state.selectedFolder,
      }));
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to delete folder:", error);
      throw error;
    } finally {
      set({ isDeletingFolder: false });
    }
  },

  // Reset store
  reset: () => {
    set({
      folders: [],
      selectedFolder: null,
      loadingFolders: new Set(),
      isFetchingReports: false,
      isCreatingFolder: false,
      isCreatingReportFolder: false,
      isDeletingFolder: false,
      error: null,
      expandedFolders: new Set(),
      folderReports: {},
    });
  },
}));

export default useFolderStore;
