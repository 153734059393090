import React from "react";
import { OrganizationSwitcher as ClerkOrgSwitcher } from "@clerk/clerk-react";

const OrganizationSwitcher = () => {
  return (
    <ClerkOrgSwitcher
      appearance={{
        elements: {
          rootBox: {
            width: "100%",
          },
          organizationSwitcherTrigger: {
            padding: "8px 12px",
            border: "1px solid #e5e7eb",
            borderRadius: "4px",
            width: "100%",
          },
        },
      }}
    />
  );
};

export default OrganizationSwitcher;
