import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/userContext'; // Assuming you have an auth context
import ApprovalRejectionModal from "../reports/approvalRejectionModal";
import useReportStore from '../../stores/reportStore';
import useProjectStore from "../../stores/projectStore";
import ReportService from "../../service/ReportService";
import { Toast } from "primereact/toast";
import { truncateMiddle } from '../../utils/stringUtils';
const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };
const Actions = () => {
    const { user: currentUser } = useUser();
    const toast = useRef(null);
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
    const [commentAction, setCommentAction] = useState(null);
    const [processingApproval, setProcessingApproval] = useState(false);
    const [pendingApprovalReports, setPendingApprovalReports] = useState([]);
    const [pendingApprovalLoading, setPendingApprovalLoading] = useState(false);
    const [submittedReports, setSubmittedReports] = useState([]);
    const [submittedReportsLoading, setSubmittedReportsLoading] = useState(false);
    const { updateReportApprover } = useReportStore();
    const { selectedProject } = useProjectStore();
    const [reportId, setReportId] = useState(null);
    const projectId = selectedProject?.project_id;
    const navigate = useNavigate();
    const getAllActionsReports = useCallback(async () => {
        try {
            const [pendingApprovalReports, submittedReports] = await Promise.all([
                ReportService.getPedningApprovalReport(projectId, currentUser.id),
                ReportService.getSubmittedReport(projectId)
            ]);
            const pendiongReports = pendingApprovalReports?.reports || pendiongReports;
            const submittedData = submittedReports?.reports || submittedReports;
            setPendingApprovalReports(pendiongReports);
            setSubmittedReports(submittedData);
        } catch (error) {
            console.error('Error fetching reports:', error);
            onShowToast('error', 'Error', 'Failed to fetch reports');
        }
    }, [projectId, currentUser.id]);

    const init = useCallback(async () =>    {
        setPendingApprovalLoading(true);
        setSubmittedReportsLoading(true);
        await getAllActionsReports();
        setPendingApprovalLoading(false);
        setSubmittedReportsLoading(false);
    }, [getAllActionsReports, setSubmittedReportsLoading, setPendingApprovalLoading]);

    useEffect(() => {
        init();
    }, [projectId, currentUser.id]);
    const onShowToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail });
    };
    const handleApproveClick = (reportId) => () => {
        setCommentAction('approve');
        setIsCommentModalOpen(true);
        setReportId(reportId);
    };

    const handleRejectClick = (reportId) => () => {
        setCommentAction('reject');
        setIsCommentModalOpen(true);
        setReportId(reportId);
    };
    const handleCommentSubmit = async (comment) => {
        try {
            setProcessingApproval(true);
            await updateReportApprover(
              reportId,
              currentUser?.id,
              commentAction === 'approve' ? 'approved' : 'rejected',
              comment
            );
            closeCommentModal();
            onShowToast(
                "success",
                commentAction === 'approve' ? "Report Approved" : "Report Rejected",
                commentAction === 'approve'
                    ? "You have successfully approved this report"
                    : "You have rejected this report"
            );
           await getAllActionsReports();
        } catch (error) {
            console.error(`Error ${commentAction}ing report:`, error);
            onShowToast(
                "error",
                `${commentAction === 'approve' ? 'Approval' : 'Rejection'} Failed`,
                `Failed to ${commentAction} the report. Please try again.`
            );
        } finally {
            setProcessingApproval(false);
        }
    };
    const getStatusBadgeColor = (status) => {
        switch (status.toLowerCase()) {
            case 'completed':
            case 'approved':
                return 'bg-green-100 text-green-800';
            case 'pending':
                return 'bg-yellow-100 text-yellow-800';
            case 'rejected':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const handleWithdraw = async (reportId) => {
        try {
            await ReportService.updateReport(reportId, {
                is_submitted: false,
            });
            onShowToast('success', 'Report Withdrawn', 'The report has been withdrawn successfully');
            await getAllActionsReports();
        } catch (error) {
            console.error('Error withdrawing report:', error);
            onShowToast('error', 'Error', 'Failed to withdraw report');
        }
    };
    const closeCommentModal = () => {
        setIsCommentModalOpen(false);
        setReportId(null);
    };

    return (
        <div className="bg-white rounded-lg shadow flex flex-col h-full px-8 py-4 overflow-hidden">
            <div className='flex flex-col h-[400px] overflow-hidden'>
                <h1 className="text-2xl font-bold mb-4 text-gray-600">Pending Approval Reports</h1>
                <div className="flex overflow-x-auto border border-solid border-gray-200 pr-2 rounded overflow-y-auto scroll-pr-6">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 sticky top-0 z-10">
                            <tr>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Report Name</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Submitted By</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {!pendingApprovalLoading && pendingApprovalReports.length === 0 &&
                                <tr>
                                    <td colSpan={4} className='text-center text-gray-500 h-full p-10  h-[200px]'>No pending approval reports</td>
                                </tr>
                            }
                            {pendingApprovalLoading &&
                                <tr>
                                    <td colSpan={4} className='text-center text-gray-500 h-full p-10  h-[200px]'>Loading...</td>
                                </tr>
                            }
                            {!pendingApprovalLoading && pendingApprovalReports.map((report, index) => (
                                <tr key={report.report_id} className="group hover:bg-gray-50">
                                    <td
                                        className="px-6 py-4 whitespace-nowrap hover:cursor-pointer"
                                        onClick={() =>
                                            navigate(`/portal/reports/${report.report_id}`)
                                        }
                                    >
                                        <span className={`px-2 underline inline-flex text-xs leading-5 font-semibold rounded-full}`}>
                                            {truncateMiddle(report?.name)}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${report?.is_submitted ? 'bg-green-500 text-white' : 'bg-yellow-500 text-white'}`}>
                                            {report?.is_submitted ? 'Submitted' : 'Not Submitted'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {report?.submitted_by_email}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap flex justify-center">
                                        <div className="space-x-2 flex justify-center">
                                            <button
                                                onClick={handleApproveClick(report?.report_id)}
                                                className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600 text-sm outline-none"
                                            >
                                                Accept
                                            </button>
                                            <button
                                                onClick={handleRejectClick(report?.report_id)}
                                                className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 text-sm outline-none"
                                            >
                                                Reject
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mt-8 border-[0.5px] border-solid border-gray-200"></div>
            <div className='flex flex-col h-[400px]'>
                <h1 className="text-2xl font-bold mb-4 text-gray-600">Submitted Reports</h1>
                <div className="flex overflow-x-auto border border-solid border-gray-200 pr-2 rounded overflow-y-auto scroll-pr-6">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 sticky top-0 z-10">
                            <tr>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Report</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Submitted By</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Assigned To</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {!submittedReportsLoading && submittedReports.length === 0 &&
                                <tr><td colSpan={5} className='text-center text-gray-500 h-full p-10  h-[200px]'>No submitted reports</td></tr>
                            }
                            {submittedReportsLoading &&
                                <tr>
                                    <td colSpan={5} className='text-center text-gray-500 h-full p-10 h-[200px]'>Loading...</td>
                                </tr>
                            }
                            {!submittedReportsLoading && submittedReports.map((report, index) => (
                                <tr key={report.report_id} className="group hover:bg-gray-50">
                                    <td
                                        className="px-6 py-4 whitespace-nowrap hover:cursor-pointer"
                                        onClick={() =>
                                            navigate(`/portal/reports/${report.report_id}`)
                                        }
                                    >
                                        <span className={`px-2 underline inline-flex text-xs leading-5 font-semibold rounded-full}`}>
                                            {truncateMiddle(report?.name)}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${report?.is_submitted ? 'bg-green-500 text-white' : 'bg-yellow-500 text-white'}`}>
                                            {report?.is_submitted ? 'Submitted' : 'Not Submitted'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {report?.submitted_by_email}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="space-y-1">
                                            {report?.approvers?.map((approver, idx) => (
                                                <div key={idx} className="items-center space-x-2 grid grid-cols-[1fr,auto]"> 
                                                        <div className='flex flex-col items-center justify-center'>
                                                            <span className="text-sm text-gray-900">{approver?.email}</span>
                                                            <div className='text-[10px] text-gray-500'>{approver?.status === 'approved' && formatDate(approver?.approved_at)}</div>
                                                            <div className='text-[10px] text-gray-500'>{approver?.status === 'rejected' && formatDate(approver?.rejected_at)}</div>
                                                        </div>
                                                        <span className={`px-2 text-center text-xs rounded-full ${getStatusBadgeColor(approver?.status)}`}>{approver?.status}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap flex justify-center">
                                        <button
                                            onClick={() => handleWithdraw(report?.report_id)}
                                            className="bg-yellow-500 text-white px-3 py-1 rounded-md hover:bg-yellow-600 text-sm outline-none"
                                        >
                                            Withdraw
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <ApprovalRejectionModal
                isOpen={isCommentModalOpen}
                onClose={closeCommentModal}
                onSubmit={handleCommentSubmit}
                title={`${commentAction === 'approve' ? 'Approve' : 'Reject'} Report`}
                action={commentAction}
            />
            <Toast ref={toast} position="bottom-right" />
        </div>
    );
};

export default Actions;