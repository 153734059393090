import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import ActivityService from "../../service/ActivityService";
import { useUser } from "../../context/userContext";
import { useClerk, useSignIn, useSignUp } from "@clerk/clerk-react";

const Login = () => {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { loginUser } = useUser();
  const { signIn, isLoaded: isSignInLoaded } = useSignIn();
  const { signUp, isLoaded: isSignUpLoaded } = useSignUp();
  const { openSignIn, openSignUp } = useClerk();

  const logActivity = async (action, userId, email) => {
    try {
      const activityLog = {
        email_id: email,
        action: action,
      };
      await ActivityService.addActivityLog(activityLog, userId);
    } catch (error) {
      console.error("Failed to log activity:", error);
    }
  };

  const handleSignInClick = () => {
    openSignIn({
      redirectUrl: "/portal/home",
      appearance: {
        elements: {
          rootBox: {
            boxShadow: "none",
          },
          card: {
            boxShadow: "none",
            border: "1px solid #e5e7eb",
          },
        },
      },
    });
  };

  const handleSignUpClick = () => {
    openSignUp({
      redirectUrl: "/portal/home",
      appearance: {
        elements: {
          rootBox: {
            boxShadow: "none",
          },
          card: {
            boxShadow: "none",
            border: "1px solid #e5e7eb",
          },
        },
      },
    });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-[#282c34] text-[#f2f4f5]">
      <Card className="w-full max-w-[900px] p-5 border border-[#cccccc] rounded-[15px] shadow-lg shadow-[rgba(111,170,206,0.772)] bg-[#282c34] text-[#abb2bf]">
        <div className="flex items-center justify-between">
          <div className="w-1/2 p-2.5">
            <div className="relative left-1/2 -translate-x-1/2 text-center text-[#abb2bf]">
              {/* <h2>Welcome to TIA Studio</h2> */}
            </div>
            <img
              src="/asset/data/Group-trans.png"
              alt="Login"
              className="w-[85%] h-auto mx-auto"
            />
          </div>
          <div className="w-1/2">
            <div className="flex justify-between mb-5">
              {/* Login options buttons if needed */}
            </div>
            {message && (
              <p className="text-red-500 mb-5 text-center">{message}</p>
            )}
            <div className="clerk-auth-container">
              <h2 className="text-xl font-bold mb-2">Sign In</h2>
              <p className="mb-4">Welcome back! Please sign in to continue.</p>
              <Button
                label="Sign In"
                onClick={handleSignInClick}
                className="p-button-primary w-full mb-2.5"
              />
              {/* <Button
                label="Sign Up with Clerk"
                onClick={handleSignUpClick}
                className="p-button-outlined p-button-primary w-full"
              /> */}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Login;
