import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { USER_API_BASE_URL } from "../utils/config";
import { createAuthenticatedApiClient } from "../utils/clerk-auth";

class UserService {
  // Create an authenticated API client using Clerk
  static apiClient = createAuthenticatedApiClient();

  // Legacy login method - kept for backward compatibility
  static async login(email, password) {
    try {
      const response = await axios.post(`${USER_API_BASE_URL}/auth/login`, {
        email,
        password,
      });
      const { token, refresh_token } = response.data;
      if (token && refresh_token) {
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);

        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000 - 60000; // 1 minute before actual expiration
        localStorage.setItem("tokenExpirationTime", expirationTime);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Legacy register method - kept for backward compatibility
  static async register(name, email, password) {
    try {
      const response = await axios.post(`${USER_API_BASE_URL}/auth/register`, {
        name,
        email,
        password,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Updated to use Clerk authentication
  static async createUser(data) {
    try {
      const response = await this.apiClient.post("/users", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Updated to use Clerk authentication
  static async updateUser(userId, data) {
    try {
      const response = await this.apiClient.put(`/users/${userId}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Updated to use Clerk authentication
  static async getAllUser() {
    try {
      const response = await this.apiClient.get("/users");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Updated to use Clerk authentication
  static async getUserById(userId) {
    try {
      const response = await this.apiClient.get(`/users/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Updated to use Clerk authentication
  static async deleteUser(userId) {
    try {
      const response = await this.apiClient.delete(`/users/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Updated to use Clerk authentication
  static async getUserByIsApproved() {
    try {
      const response = await this.apiClient.get("/users/pending");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Updated to use Clerk authentication
  static async approveUser(userId) {
    try {
      const response = await this.apiClient.put(
        `/users/${userId}/approve`,
        null
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // With Clerk, we don't need to manually refresh the session
  static refreshSession() {
    // This is kept for backward compatibility
    // Clerk handles session management automatically
    console.log("Session refresh handled by Clerk");
  }

  // With Clerk, we don't need to manually schedule token expiration
  static scheduleTokenExpiration(expirationTime) {
    // This is kept for backward compatibility
    // Clerk handles token expiration automatically
    console.log("Token expiration handled by Clerk");
  }

  // With Clerk, we don't need to manually handle token expiration
  static handleTokenExpiration() {
    // This is kept for backward compatibility
    // Clerk handles token expiration automatically
    console.log("Token expiration handled by Clerk");
  }

  // With Clerk, we don't need to manually get token expiration time
  static getTokenExpirationTime() {
    // This is kept for backward compatibility
    // Return a future timestamp to prevent legacy code from logging out the user
    return Date.now() + 3600000; // 1 hour from now
  }

  // With Clerk, we don't need to manually logout
  static logout() {
    // This is kept for backward compatibility
    // Clerk handles logout through its own methods
    console.log("Logout handled by Clerk");
  }
}

export default UserService;
