import { Fragment, useState, useEffect } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { BiSolidError } from "react-icons/bi";
import { FaExclamationTriangle, FaCheckCircle } from "react-icons/fa";
import RcaRecommendationsFlow from "./run-rca";
import { ScatterGraph } from "./graph";

const ModalSkeleton = () => (
  <div className="animate-pulse space-y-6">
    {/* Header Skeleton */}
    <div className="flex items-center space-x-3 mb-4">
      <div className="p-2 rounded-lg bg-gray-200 w-10 h-10"></div>
      <div className="w-24 h-5 bg-gray-200 rounded"></div>
    </div>

    {/* Tabs Skeleton */}
    <div className="w-full h-8 bg-gray-100 rounded"></div>

    {/* Body Skeleton */}
    <div className="flex-1 px-6 mt-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column Skeleton */}
        <div className="space-y-6">
          {/* Observations Skeleton */}
          <div className="bg-gray-100 p-4 rounded-lg space-y-2">
            <div className="w-3/4 h-4 bg-gray-200 rounded"></div>
            {Array(5)
              .fill(null)
              .map((_, i) => (
                <div key={i} className="w-1/2 h-3 bg-gray-200 rounded" />
              ))}
          </div>

          {/* Comment Skeleton */}
          {Array(2)
            .fill(null)
            .map((_, i) => (
              <div
                key={i}
                className="bg-blue-50 border border-blue-200 rounded-lg p-4 space-y-2"
              >
                <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
                <div className="h-3 w-3/4 bg-gray-200 rounded"></div>
              </div>
            ))}

          {/* Affected Tools Skeleton */}
          <div>
            <div className="h-4 w-2/3 bg-gray-200 rounded mb-3"></div>
            <div className="grid grid-cols-3 gap-2">
              {Array(6)
                .fill(null)
                .map((_, i) => (
                  <div key={i} className="h-6 bg-gray-200 rounded"></div>
                ))}
            </div>
          </div>
        </div>

        {/* Right Column (Graph) Skeleton */}
        <div className="bg-gray-100 rounded-lg h-64 w-full"></div>
      </div>
    </div>

    {/* Footer Skeleton */}
    <div className="sticky bottom-0 bg-white pt-6 flex justify-end items-center space-x-3">
      <div className="w-16 h-8 bg-gray-200 rounded"></div>
      <div className="w-16 h-8 bg-gray-200 rounded"></div>
      <div className="w-16 h-8 bg-gray-200 rounded"></div>
    </div>
  </div>
);

/**
 * Timeline-style skeleton for the RCA tab.
 * Shows multiple vertical "steps" loading in sequence.
 * The 'active' step is at full opacity (1) while others are at 0.75.
 */
const RcaTimelineSkeleton = () => {
  const steps = [
    "Gathering data...",
    "Analyzing distributions...",
    "Correlating metrics...",
    "Finalizing recommendations...",
  ];

  // Tracks the currently "active" index for styling
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prev) => {
        if (prev < steps.length - 1) {
          return prev + 1;
        }
        clearInterval(interval);
        return prev;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col space-y-6 p-6">
      {steps.map((step, index) => {
        const isActive = index === activeStep;
        return (
          <div
            key={index}
            className={`flex items-start space-x-3 ${
              isActive ? "animate-pulse" : "opacity-25"
            }`}
          >
            <div className="flex flex-col items-center">
              {/* Circle */}
              <div className="w-4 h-4 rounded-full bg-blue-200" />
              {/* Vertical line except for last item */}
              {index < steps.length - 1 && (
                <div className="h-8 w-px bg-blue-200 mt-1" />
              )}
            </div>
            <div className="flex-1">
              <div className="text-blue-600 font-medium mb-2">{step}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const AlertModal = ({ selectedAlert, onClose }) => {
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([
    {
      author: "John Doe",
      timestamp: "1/16/2025 7:03 am",
      text: "Lot to lot sigma from ECH003 seems to tighter time to time, Further investigation needed...",
      status: null,
    },
  ]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showRcaTab, setShowRcaTab] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isRcaLoading, setIsRcaLoading] = useState(false);

  useEffect(() => {
    if (selectedAlert) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [selectedAlert]);

  const handleCommentSubmit = (status) => {
    if (newComment.trim()) {
      setComments([
        {
          author: "Current User",
          timestamp: new Date().toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
          }),
          text: newComment,
          status: status,
        },
        ...comments,
      ]);
      setNewComment("");
      setShowCommentInput(false);
    }
  };

  const handleClose = () => {
    onClose();
    setShowRcaTab(false);
    setSelectedTabIndex(0);
    setIsRcaLoading(false);
  };

  const handleRunRca = () => {
    // Switch to RCA tab
    setShowRcaTab(true);
    setSelectedTabIndex(1);
    // Begin timeline skeleton
    setIsRcaLoading(true);
    setTimeout(() => {
      setIsRcaLoading(false);
    }, 10000);
  };

  return (
    <Transition appear show={!!selectedAlert} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="relative w-full max-w-5xl transform rounded-xl bg-white p-6 shadow-xl transition-all flex flex-col max-h-[90vh] h-full">
                {isLoading ? (
                  <ModalSkeleton />
                ) : (
                  <>
                    <div className="flex items-start justify-between mb-4">
                      <div className="flex items-center space-x-3">
                        <div className="p-2 rounded-lg bg-red-100">
                          <BiSolidError className="h-6 w-6 text-red-600" />
                        </div>
                        <DialogTitle
                          as="h3"
                          className="text-xl font-semibold sticky top-0 leading-6 text-gray-900"
                        >
                          CD Sigma
                        </DialogTitle>
                      </div>
                    </div>

                    <TabGroup
                      selectedIndex={selectedTabIndex}
                      onChange={setSelectedTabIndex}
                      className="space-y-6 overflow-y-scroll"
                    >
                      <TabList className="bg-white z-10 sticky top-0 flex space-x-4 border-solid border-0 border-b-2 border-gray-200">
                        <Tab className="px-4 py-2 text-base text-gray-500 focus:outline-none bg-white data-[selected]:font-bold data-[selected]:text-blue-600 data-[selected]:border-solid data-[selected]:border-0 data-[selected]:border-b-2 data-[selected]:border-blue-600">
                          Alert Summary
                        </Tab>
                        {showRcaTab && (
                          <Tab className="px-4 py-2 text-base text-gray-500 focus:outline-none bg-white data-[selected]:font-bold data-[selected]:text-blue-600 data-[selected]:border-solid data-[selected]:border-0 data-[selected]:border-b-2 data-[selected]:border-blue-600">
                            RCA
                          </Tab>
                        )}
                      </TabList>

                      <TabPanels>
                        {/* ALERT SUMMARY TAB */}
                        <TabPanel>
                          <div className="flex-1 px-6 overflow-y-scroll">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                              {/* Left Column */}
                              <div className="space-y-6">
                                {/* Observations Block */}
                                <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                                  <p className="text-gray-700 font-medium">
                                    Key Observations:
                                  </p>
                                  <ul className="list-disc ml-5 space-y-2 text-sm text-gray-600">
                                    <li>ECH001 CD sigma is 1.2nm</li>
                                    <li>ECH002 CD sigma is 1.1</li>
                                    <li>
                                      ECH003_PM1 CD sigma is fluctuating 0.6 to
                                      1.3nm
                                    </li>
                                    <li>
                                      Suspicion: CD distribution could be tighter
                                      by 50%
                                    </li>
                                    <li>
                                      Lot to lot sigma from ECH003 CD
                                      distribution can be tighter
                                    </li>
                                  </ul>
                                </div>

                                {/* Comments Block */}
                                {showCommentInput && (
                                  <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 space-y-3">
                                    <textarea
                                      value={newComment}
                                      onChange={(e) =>
                                        setNewComment(e.target.value)
                                      }
                                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                      placeholder="Enter your comment..."
                                      rows="3"
                                    />
                                    <div className="flex flex-col justify-between items-center">
                                      <div className="flex-row space-x-2 pb-2">
                                        <button
                                          onClick={() =>
                                            handleCommentSubmit("intervention")
                                          }
                                          className="px-3 py-1.5 bg-blue-100 text-gray-700 rounded-md hover:bg-blue-200 text-sm"
                                        >
                                          Intervention Needed
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleCommentSubmit("debugged")
                                          }
                                          className="px-3 py-1.5 bg-blue-100 text-gray-700 rounded-md hover:bg-blue-200 text-sm"
                                        >
                                          Debugged
                                        </button>
                                      </div>
                                      <div className="flex-row space-x-2">
                                        <button
                                          onClick={() =>
                                            setShowCommentInput(false)
                                          }
                                          className="px-3 py-1.5 bg-blue-100 text-gray-700 rounded-md hover:bg-blue-200 text-sm"
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleCommentSubmit(null)
                                          }
                                          className="px-3 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {comments.map((comment, index) => (
                                  <div
                                    key={index}
                                    className="bg-blue-50 border border-blue-200 rounded-lg p-4"
                                  >
                                    <div className="flex items-start space-x-3">
                                      <div className="h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center">
                                        <span className="text-sm text-blue-600">
                                          {comment.author.charAt(0)}
                                        </span>
                                      </div>
                                      <div className="flex-1">
                                        <div className="flex items-center justify-between">
                                          <p className="text-sm font-medium text-gray-900">
                                            {comment.author}
                                          </p>
                                          {comment.status ===
                                            "intervention" && (
                                            <span className="px-2 py-1 bg-yellow-100 text-yellow-700 rounded-full text-xs">
                                              <FaExclamationTriangle className="inline mr-1" />
                                              Intervention Needed
                                            </span>
                                          )}
                                          {comment.status === "debugged" && (
                                            <span className="px-2 py-1 bg-green-100 text-green-700 rounded-full text-xs">
                                              <FaCheckCircle className="inline mr-1" />
                                              Debugged
                                            </span>
                                          )}
                                        </div>
                                        <p className="text-xs text-gray-500">
                                          {comment.timestamp}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-600 whitespace-pre-wrap">
                                          {comment.text}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                                {/* Affected Tools Block */}
                                <div>
                                  <h4 className="text-sm font-semibold text-gray-900 mb-3">
                                    Affected Tools (88 HCCD)
                                  </h4>
                                  <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                                    {[
                                      "ECH001_PM1",
                                      "ECH001_PM2",
                                      "ECH002_PM1",
                                      "ECH002_PM2",
                                      "ECH003_PM1",
                                      "ECH003_PM2",
                                    ].map((tool) => (
                                      <span
                                        key={tool}
                                        className="px-3 py-1.5 bg-gray-100 text-gray-700 rounded-full text-sm text-center"
                                      >
                                        {tool}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              {/* Right Column - Graph */}
                              <div className="h-full flex flex-col">
                                <ScatterGraph />
                              </div>
                            </div>
                          </div>

                          {/* Footer */}
                          <div className="sticky bottom-0 bg-white pt-6 flex justify-end items-center">
                            <div className="flex space-x-3">
                              <button
                                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
                                onClick={handleClose}
                              >
                                Back
                              </button>
                              <button
                                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
                                onClick={() => {
                                  setSelectedTabIndex(0);
                                  setShowCommentInput(!showCommentInput);
                                }}
                              >
                                {showCommentInput ? "Discard" : "Comment"}
                              </button>
                              <button
                                onClick={handleRunRca}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm"
                              >
                                Run RCA
                              </button>
                            </div>
                          </div>
                        </TabPanel>

                        {/* RCA Tab */}
                        {showRcaTab && (
                          <TabPanel>
                            {isRcaLoading ? (
                              <RcaTimelineSkeleton />
                            ) : (
                              <div className="flex-1 px-6 overflow-y-scroll">
                                <RcaRecommendationsFlow />
                              </div>
                            )}
                            <div className="sticky bottom-0 bg-white pt-6 flex justify-end items-center">
                              <div className="flex space-x-3">
                                <button
                                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm"
                                  onClick={handleClose}
                                >
                                  Back
                                </button>
                                <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm">
                                  Data Talk
                                </button>
                              </div>
                            </div>
                          </TabPanel>
                        )}
                      </TabPanels>
                    </TabGroup>
                  </>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};