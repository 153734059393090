import { useClerk, useUser } from "@clerk/clerk-react";
import axios from "axios";
import { USER_API_BASE_URL } from "./config";

/**
 * Custom hook to handle Clerk authentication and integrate with the existing backend
 */
export const useClerkAuth = () => {
  const { user, isSignedIn, isLoaded } = useUser();
  const { signOut } = useClerk();

  /**
   * Get the session token from Clerk
   * @returns {Promise<string>} The session token
   */
  const getToken = async () => {
    if (!isSignedIn || !user) return null;
    return await user.getToken();
  };

  /**
   * Authenticate with the backend using Clerk's token
   * This function can be used to exchange Clerk's token for your backend's token if needed
   */
  const authenticateWithBackend = async () => {
    try {
      const clerkToken = await getToken();
      if (!clerkToken) return null;

      // Call your backend to validate the Clerk token and get your backend token if needed
      const response = await axios.post(
        `${USER_API_BASE_URL}/auth/clerk-auth`,
        {
          clerkToken,
        }
      );

      // If your backend returns a token, store it
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        return response.data;
      }

      return null;
    } catch (error) {
      console.error("Error authenticating with backend:", error);
      return null;
    }
  };

  /**
   * Sign out from Clerk and clear local storage
   */
  const logout = async () => {
    try {
      await signOut();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("tokenExpirationTime");
      localStorage.removeItem("refresh_token");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return {
    user,
    isSignedIn,
    isLoaded,
    getToken,
    authenticateWithBackend,
    logout,
  };
};

/**
 * Utility to create an axios instance with Clerk authentication
 * @returns {Object} Axios instance with auth headers
 */
export const createAuthenticatedApiClient = () => {
  const apiClient = axios.create({
    baseURL: USER_API_BASE_URL,
  });

  // Add a request interceptor to add the Clerk token to each request
  apiClient.interceptors.request.use(async (config) => {
    try {
      // Get the current user from Clerk
      const clerk = window.Clerk;
      if (!clerk || !clerk.session) {
        return config;
      }

      const token = await clerk.session.getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } catch (error) {
      console.error("Error getting Clerk token:", error);
      return config;
    }
  });

  return apiClient;
};

export default useClerkAuth;
