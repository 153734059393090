import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { UserProvider, useUser } from "./context/userContext";
import Login from "./page/login/login";
import Portal from "./page/portal/portal";
import UserService from "./service/UserService";
import { Toast } from "primereact/toast";
import Forbidden from "./utils/forbidden";
import SessionTimeoutPopup from "./utils/session/session-timeout";
import Preview from "./page/preview/preview";
import NotFound from "./page/notFound";
import Intercom from "@intercom/messenger-js-sdk";
import "react-tooltip/dist/react-tooltip.css";
import posthog from "posthog-js";
import {
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  SignIn,
  SignUp,
  useClerk,
  useUser as useClerkUser,
} from "@clerk/clerk-react";

posthog.init("phc_N7htrYv6Yk02MqoNVN8D0o08WXF8NG0khNARwlO7lrB", {
  api_host: "https://us.i.posthog.com",
});

const App = () => {
  const toast = useRef(null);
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [countdown, setCountdown] = useState(60); // 60 seconds countdown
  const { isSignedIn, user: clerkUser } = useClerkUser();
  const { signOut } = useClerk();

  useEffect(() => {
    Intercom({
      app_id: "gi85od1m",
    });
  }, []);

  // Handle session refresh with Clerk
  const handleSessionRefresh = () => {
    // With Clerk, we don't need to manually refresh the session
    setShowSessionPopup(false);
    setCountdown(60);
  };

  return (
    <Router>
      <UserProvider>
        <Toast ref={toast} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <SignedIn>
                  <Navigate to="/portal/home" replace />
                </SignedIn>
                <SignedOut>
                  <Login />
                </SignedOut>
              </>
            }
          />
          <Route
            path="/sign-in/*"
            element={<SignIn routing="path" path="/sign-in" />}
          />
          <Route
            path="/sign-up/*"
            element={<SignUp routing="path" path="/sign-up" />}
          />
          <Route
            path="/portal/*"
            element={
              <ClerkProtectedRoute>
                <Portal />
              </ClerkProtectedRoute>
            }
          />
          <Route path="/preview/*" element={<Preview />} />
          <Route path="/forbidden" element={<Forbidden />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {showSessionPopup && (
          <SessionTimeoutPopup
            countdown={countdown}
            onRefresh={handleSessionRefresh}
          />
        )}
      </UserProvider>
    </Router>
  );
};

// Clerk Protected Route component
const ClerkProtectedRoute = ({ children }) => {
  const { isSignedIn, isLoaded } = useClerkUser();
  const navigate = useNavigate();
  const toast = useRef(null);

  if (!isLoaded) {
    // Show loading state while Clerk is loading
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (!isSignedIn) {
    return <RedirectToSignIn />;
  }

  return (
    <>
      <Toast ref={toast} />
      {children}
    </>
  );
};

// Legacy Protected Route - keeping for reference
const ProtectedRoute = ({
  children,
  toast,
  setShowSessionPopup,
  setCountdown,
}) => {
  const { user, logoutUser } = useUser();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const timeLeft = UserService.getTokenExpirationTime() - Date.now();

      if (timeLeft <= 0) {
        logoutUser();
        toast.current.show({
          severity: "error",
          summary: "Session expired",
          detail: "Please login again.",
          life: 3000,
        });
        window.location.href = "/"; // Redirect to login page
      } else if (timeLeft <= 60000) {
        // Show popup 1 minute before expiration
        setShowSessionPopup(true);
        startCountdown(timeLeft);
      }
    };

    const startCountdown = (timeLeft) => {
      const countdownTime = Math.ceil(timeLeft / 1000);
      setCountdown(countdownTime);
      const countdownInterval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    };

    checkTokenExpiration();

    const interval = setInterval(checkTokenExpiration, 10000); // Check every 10 seconds

    return () => clearInterval(interval); // Clear interval on unmount
  }, [logoutUser, toast, setShowSessionPopup, setCountdown]);

  return user ? children : <Navigate to="/" replace />;
};

export default App;
