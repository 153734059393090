import React, { Fragment, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import CustomRulesModal from "./components/custom-rules-modal";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Input,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { BiSolidError } from "react-icons/bi";
import DocumentUploadModal from "../document/document-upload-modal";

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 shadow-xl transition-all">
                <div className="flex items-start space-x-3">
                  <div className="p-2 rounded-lg bg-red-100">
                    <BiSolidError className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="flex-1">
                    <DialogTitle
                      as="h3"
                      className="text-lg font-semibold text-gray-900 mb-6 mt-2"
                    >
                      Delete Alert Rule
                    </DialogTitle>
                    <p className="text-gray-600">
                      Are you sure you want to delete this alert rule? This
                      action cannot be undone.
                    </p>
                  </div>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={onClose}
                    className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={onConfirm}
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  >
                    Delete Rule
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

// Table row skeleton placeholder
const TableRowSkeleton = () => (
  <tr className="animate-pulse">
    <td className="px-6 py-4 w-12">
      <div className="h-4 w-4 bg-gray-200 rounded"></div>
    </td>
    <td className="px-6 py-4">
      <div className="h-4 w-48 bg-gray-200 rounded"></div>
    </td>
    <td className="px-6 py-4">
      <div className="h-4 w-6 bg-gray-200 rounded"></div>
    </td>
  </tr>
);

export default function RcaDeveloper() {
  const rcaAlertRules = [
    "FCCD distribution is possibily wider by 20% than optimal at M1 FCCD",
    "Etch failing, array is out of bound for lot impacting point of contact",
  ];

  const [isImportRulesModalOpen, setIsImportRulesModalOpen] =
    useState(false);
  const [isCustomRulesModalOpen, setIsCustomRulesModalOpen] =
    useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  // Add a simple loading state for demonstration
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="rca-container">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="mb-6 sticky top-4 bg-white z-10">
          <Input
            type="text"
            placeholder="Search..."
            className="w-full p-4 border border-solid border-gray-200 shadow-md rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 border-solid">
          <div className="px-4 py-3 border-b border-gray-200">
            <h3 className="text-base font-medium text-gray-900">Alert Rules</h3>
          </div>
          {/* Table component */}
          <div className="overflow-x-auto flex-1">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left w-12">
                    <input
                      type="checkbox"
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                  </th>
                  {[
                    { key: "alert", label: "Alert" },
                    { key: "trash", label: "Trash" },
                  ].map(({ key, label }) => (
                    <th
                      key={key}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 select-none"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <>
                    <TableRowSkeleton />
                    <TableRowSkeleton />
                    <TableRowSkeleton />
                  </>
                ) : (
                  rcaAlertRules.map((alert, index) => (
                    <tr
                      key={index}
                      className="group hover:bg-gray-50 transition-colors duration-150"
                    >
                      <td className="px-6 py-4 w-12 shrink-0">
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                      </td>
                      <td className="px-6 py-4 min-w-[300px]">
                        <div className="text-sm text-gray-900">{alert}</div>
                      </td>
                      <td className="px-6 py-4">
                        <button
                          className="text-gray-500 hover:text-gray-500 transition-colors bg-white"
                          onClick={() => setIsDeleteConfirmationModalOpen(true)}
                        >
                          <FaTrash className="h-5 w-5" />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            {/* Table Actions Footer */}
            <div className="sticky bottom-0 bg-white border-t border-gray-200 px-6 py-4 flex justify-between items-center">
              <div className="flex items-center space-x-4">
                <button className="px-4 py-2 border border-gray-300 rounded-md text-blue-700 hover:bg-blue-700 hover:text-white text-sm">
                  Export Rules
                </button>
                <button
                  className="px-4 py-2 border border-gray-300 rounded-md text-blue-700 hover:bg-blue-700 hover:text-white text-sm"
                  onClick={() => setIsImportRulesModalOpen(true)}
                >
                  Import Rules
                </button>
                <button
                  className="px-4 py-2 border border-gray-300 rounded-md text-blue-700 hover:bg-blue-700 hover:text-white text-sm"
                  onClick={() => setIsCustomRulesModalOpen(true)}
                >
                  Custom Rules
                </button>
              </div>

              <div className="flex space-x-3">
                <button className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm">
                  Cancel
                </button>
                <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Import Rules Modal */}
      <DocumentUploadModal
        isOpen={isImportRulesModalOpen}
        onClose={() => setIsImportRulesModalOpen(false)}
        onUpload
      />

      <CustomRulesModal
        isOpen={isCustomRulesModalOpen}
        onClose={() => setIsCustomRulesModalOpen(false)}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
      />
    </div>
  );
}
