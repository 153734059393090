import React, { useState } from "react";
import {
  Dialog,
  Input,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { Fragment } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaDatabase, FaLink } from "react-icons/fa";
import { BsGoogle, BsMicrosoft } from "react-icons/bs";

const dbTypes = [
  { id: "tool_logs", name: "Tool Logs" },
  { id: "defmet_data", name: "Defmet Data" },
  { id: "pcsa", name: "PCSA" },
  { id: "yield_data", name: "Yield Data" },
  { id: "etest_data", name: "Etest Data" },
  { id: "msc", name: "Msc" },
];

const fetchFrequency = [
  { id: "realtime", name: "Real-time", frequency: null },
  { id: "hourly", name: "Hourly", frequency: "hours" },
  { id: "daily", name: "Daily", frequency: "days" },
  { id: "weekly", name: "Weekly", frequency: "weeks" },
  { id: "monthly", name: "Monthly", frequency: "months" },
];

const datasetTypes = [
  { id: "spec", name: "Specifications" },
  { id: "wp", name: "WP" },
  { id: "materials", name: "Materials" },
  { id: "bkms", name: "BKMs" },
  { id: "tool_manuals", name: "Tool Manuals" },
  { id: "msc", name: "Msc" },
];

const DocumentUploadModal = ({
  isOpen,
  onClose,
  onUpload,
  isUploading,
  showConnectToDb = false,
  showDatasetType = false,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadMethod, setUploadMethod] = useState("file");
  const [link, setLink] = useState("");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [crawlOptions, setCrawlOptions] = useState({
    recursiveCrawling: false,
    crawlDepth: "1",
    treatAsSitemap: false,
    requiresAuth: false,
    authUsername: "",
    authPassword: "",
    respectRobots: true,
  });
  const [selectedDbType, setSelectedDbType] = useState(dbTypes[0]);
  const [selectedFetchFrequency, setSelectedFetchFrequency] = useState(
    fetchFrequency[0],
  );
  const [selectedDatasetType, setSelectedDatasetType] = useState(
    datasetTypes[0],
  );

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const files = Array.from(e.dataTransfer.files || []);
    if (files.length > 0) {
      setSelectedFile(files);
    }
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files || []);
    if (files.length > 0) {
      setSelectedFile(files);
    }
  };

  const handleOptionChange = (key, value) => {
    setCrawlOptions((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleUpload = async () => {
    try {
      if (uploadMethod === "link" && link) {
        const linkPayload = {
          type: "link",
          link: link,
          crawl_options: {
            recursiveCrawling: crawlOptions.recursiveCrawling,
            crawlDepth: parseInt(crawlOptions.crawlDepth),
            treatAsSitemap: crawlOptions.treatAsSitemap,
            auth: crawlOptions.requiresAuth
              ? {
                  username: crawlOptions.authUsername,
                  password: crawlOptions.authPassword,
                }
              : null,
            respectRobots: crawlOptions.respectRobots,
          },
        };
        await onUpload(linkPayload);
        setLink("");
        setCrawlOptions({
          recursiveCrawling: false,
          crawlDepth: "1",
          treatAsSitemap: false,
          requiresAuth: false,
          authUsername: "",
          authPassword: "",
          respectRobots: true,
        });
        onClose();
      } else if (selectedFile) {
        const files = Array.isArray(selectedFile)
          ? selectedFile
          : [selectedFile];
        await Promise.all(files.map((file) => onUpload(file)));
        setSelectedFile(null);
        onClose();
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const renderSourceContent = (uploadMethod) => {
    if (uploadMethod === "link") {
      return (
        <div className="space-y-4">
          <input
            type="url"
            placeholder="Paste your link here"
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <div>
            <button
              type="button"
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="text-sm bg-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md px-2 py-1 flex items-center gap-1 transition-colors duration-200"
              aria-expanded={showAdvanced}
              aria-controls="advanced-options-panel"
            >
              <svg
                className={`h-4 w-4 transform transition-transform duration-200 ${
                  showAdvanced ? "rotate-180" : ""
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              Advanced options
            </button>
            {showAdvanced && (
              <div className="space-y-3 mt-2 bg-gray-50 p-4 rounded-lg">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="crawlDepth"
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={crawlOptions.recursiveCrawling}
                    onChange={(e) =>
                      handleOptionChange("recursiveCrawling", e.target.checked)
                    }
                  />
                  <div className="flex flex-col">
                    <label
                      htmlFor="crawlDepth"
                      className="text-sm font-medium text-gray-700"
                    >
                      Recursive crawling
                    </label>
                    <select
                      className="mt-1 block w-32 rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
                      value={crawlOptions.crawlDepth}
                      onChange={(e) =>
                        handleOptionChange("crawlDepth", e.target.value)
                      }
                      disabled={!crawlOptions.recursiveCrawling}
                    >
                      <option value="1">1 level deep</option>
                      <option value="2">2 levels deep</option>
                      <option value="3">3 levels deep</option>
                    </select>
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="sitemapMode"
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={crawlOptions.treatAsSitemap}
                    onChange={(e) =>
                      handleOptionChange("treatAsSitemap", e.target.checked)
                    }
                  />
                  <div className="flex flex-col">
                    <label
                      htmlFor="sitemapMode"
                      className="text-sm font-medium text-gray-700"
                    >
                      Treat as sitemap
                    </label>
                    <span className="text-xs text-gray-500">
                      Parse URL as XML sitemap and crawl all listed URLs
                    </span>
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="requiresAuth"
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={crawlOptions.requiresAuth}
                    onChange={(e) =>
                      handleOptionChange("requiresAuth", e.target.checked)
                    }
                  />
                  <div className="flex flex-col">
                    <label
                      htmlFor="requiresAuth"
                      className="text-sm font-medium text-gray-700"
                    >
                      Requires authentication
                    </label>
                    <div className="mt-1 space-y-2">
                      <input
                        type="text"
                        placeholder="Username"
                        className="block w-full rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
                        value={crawlOptions.authUsername}
                        onChange={(e) =>
                          handleOptionChange("authUsername", e.target.value)
                        }
                        disabled={!crawlOptions.requiresAuth}
                      />
                      <input
                        type="password"
                        placeholder="Password"
                        className="block w-full rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
                        value={crawlOptions.authPassword}
                        onChange={(e) =>
                          handleOptionChange("authPassword", e.target.value)
                        }
                        disabled={!crawlOptions.requiresAuth}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="respectRobots"
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={crawlOptions.respectRobots}
                    onChange={(e) =>
                      handleOptionChange("respectRobots", e.target.checked)
                    }
                  />
                  <div className="flex flex-col">
                    <label
                      htmlFor="respectRobots"
                      className="text-sm font-medium text-gray-700"
                    >
                      Respect robots.txt
                    </label>
                    <span className="text-xs text-gray-500">
                      Follow website crawling rules and restrictions
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 bg-yellow-50 border border-yellow-200 p-4 rounded-lg">
            <p className="text-sm text-yellow-800">
              <strong>Note:</strong>
              <ul className="list-disc ml-4 mt-1">
                <li>
                  Only the visible text on the website will be imported at this
                  moment
                </li>
                <li>
                  Paid articles and content behind paywalls are not supported
                </li>
                <li>JavaScript-rendered content may not be captured</li>
              </ul>
            </p>
          </div>

          <p className="text-sm text-gray-500">
            Supported: Website URLs, XML Sitemaps
          </p>
        </div>
      );
    } else if (uploadMethod === "db") {
      return (
        <div className="space-y-4">
          <input
            type="url"
            placeholder="Paste your DB connection string here"
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <div>
            <button
              type="button"
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="text-sm bg-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md px-2 py-1 flex items-center gap-1 transition-colors duration-200"
              aria-expanded={showAdvanced}
              aria-controls="advanced-options-panel"
            >
              <svg
                className={`h-4 w-4 transform transition-transform duration-200 ${
                  showAdvanced ? "rotate-180" : ""
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              Advanced options
            </button>
            {showAdvanced && (
              <div className="space-y-3 mt-2 bg-gray-50 p-4 rounded-lg">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="requiresAuth"
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={crawlOptions.requiresAuth}
                    onChange={(e) =>
                      handleOptionChange("requiresAuth", e.target.checked)
                    }
                  />
                  <div className="flex flex-col">
                    <label
                      htmlFor="requiresAuth"
                      className="text-sm font-medium text-gray-700"
                    >
                      Requires authentication
                    </label>
                    <div className="mt-1 space-y-2">
                      <input
                        type="text"
                        placeholder="Username"
                        className="block w-full rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
                        value={crawlOptions.authUsername}
                        onChange={(e) =>
                          handleOptionChange("authUsername", e.target.value)
                        }
                        disabled={!crawlOptions.requiresAuth}
                      />
                      <input
                        type="password"
                        placeholder="Password"
                        className="block w-full rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
                        value={crawlOptions.authPassword}
                        onChange={(e) =>
                          handleOptionChange("authPassword", e.target.value)
                        }
                        disabled={!crawlOptions.requiresAuth}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 bg-yellow-50 border border-yellow-200 p-4 rounded-lg">
            <p className="text-sm text-yellow-800">
              <strong>Note:</strong>
              <ul className="list-disc ml-4 mt-1">
                <li>
                  To connect to a database, you'll typically need a database
                  client, credentials, and a connection string.
                </li>
                <li>
                  A connection string provides details like the server address,
                  port number, database name, and user credentials.
                </li>
                <li>Database connection string should be read-only</li>
              </ul>
            </p>
          </div>

          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 bg-gray-100 rounded-lg p-4">
            <div className="flex flex-col space-y-2 w-full md:w-auto">
              <span className="text-sm text-gray-500 font-semibold">
                Database Type
              </span>
              <Listbox
                as="div"
                className="relative text-left"
                value={selectedDbType}
                onChange={setSelectedDbType}
              >
                <ListboxButton className="text-gray-500 bg-white flex hover:bg-gray-50 rounded-lg w-full md:w-56 px-4 py-2 border border-solid border-gray-200">
                  {selectedDbType.name}
                </ListboxButton>
                <ListboxOptions className="z-10 absolute border border-solid border-gray-200 left-0 mt-2 w-full md:w-56 origin-top-right divide-y divide-gray-200 rounded-lg overflow-y-scroll max-h-32 bg-transparent backdrop-blur-lg shadow-lg focus:outline-none">
                  {dbTypes.map((type) => (
                    <ListboxOption
                      key={type.id}
                      value={type}
                      className="group flex w-full items-center px-4 py-2 text-sm bg-white text-gray-900 data-[focus]:bg-gray-50 data-[selected]:bg-blue-50"
                    >
                      {type.name}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Listbox>
            </div>

            <div className="flex flex-col space-y-2 w-full md:w-auto">
              <span className="text-sm text-gray-500 font-semibold">
                Fetch Frequency
              </span>
              <div className="flex items-center space-x-2">
                <Listbox
                  as="div"
                  className="relative text-left w-full md:w-56"
                  value={selectedFetchFrequency}
                  onChange={setSelectedFetchFrequency}
                >
                  <ListboxButton className="text-gray-500 bg-white flex hover:bg-gray-50 rounded-lg w-full md:w-56 px-4 py-2 border border-solid border-gray-200">
                    {selectedFetchFrequency.name}
                  </ListboxButton>
                  <ListboxOptions className="z-10 absolute border border-solid border-gray-200 left-0 mt-2 w-full md:w-56 origin-top-right divide-y divide-gray-200 rounded-lg overflow-y-scroll max-h-32 bg-transparent backdrop-blur-lg shadow-lg focus:outline-none">
                    {fetchFrequency.map((type) => (
                      <ListboxOption
                        key={type.id}
                        value={type}
                        className="group flex w-full items-center px-4 py-2 text-sm bg-white text-gray-900 data-[focus]:bg-gray-50 data-[selected]:bg-blue-50"
                      >
                        {type.name}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Listbox>
                {selectedFetchFrequency.frequency && (
                  <>
                    <Input
                      type="number"
                      className="text-gray-500 bg-white flex hover:bg-gray-50 rounded-lg w-full md:w-56 px-4 py-2 border border-solid border-gray-200"
                      placeholder="Fetch interval"
                    />
                    <span className="text-gray-500 text-sm font-medium italic">
                      {selectedFetchFrequency.frequency}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>

          <p className="text-sm text-gray-500">
            Supported: PostgreSQL, MySQL, MongoDB, SQL Server
          </p>
        </div>
      );
    }
    return (
      <>
        {showDatasetType && (
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 bg-gray-100 rounded-lg p-4 mb-4">
            <div className="flex flex-col space-y-2 w-full md:w-auto">
              <span className="text-sm text-gray-500 font-semibold">
                Dataset Type
              </span>
              <Listbox
                as="div"
                className="relative text-left"
                value={selectedDatasetType}
                onChange={setSelectedDatasetType}
              >
                <ListboxButton className="text-gray-500 bg-white flex hover:bg-gray-50 rounded-lg w-full md:w-56 px-4 py-2 border border-solid border-gray-200">
                  {selectedDatasetType.name}
                </ListboxButton>
                <ListboxOptions className="z-10 absolute border border-solid border-gray-200 left-0 mt-2 w-full md:w-56 origin-top-right divide-y divide-gray-200 rounded-lg overflow-y-scroll max-h-32 bg-transparent backdrop-blur-lg shadow-lg focus:outline-none">
                  {datasetTypes.map((type) => (
                    <ListboxOption
                      key={type.id}
                      value={type}
                      className="group flex w-full items-center px-4 py-2 text-sm bg-white text-gray-900 data-[focus]:bg-gray-50 data-[selected]:bg-blue-50"
                    >
                      {type.name}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Listbox>
            </div>
          </div>
        )}
        <div
          className={`relative border-2 border-dashed rounded-lg p-8 text-center mb-6 ${
            dragActive ? "border-blue-500 bg-blue-50" : "border-gray-300"
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            multiple
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            onChange={handleFileSelect}
            accept=".pdf,.doc,.docx,.txt,.rtf,.md,.odt,.ods,.odp,.csv,.xls,.xlsx,.ppt,.pptx,.pages,.numbers,.key,.epub,.mobi,.mp3,.mp4,.wav,.avi,.mov,.wmv,.json"
          />

          <div className="space-y-3">
            <AiOutlineCloudUpload className="mx-auto h-12 w-12 text-gray-400" />
            <div className="text-gray-600">
              <span className="text-gray-800 text-xl font-semibold">
                Upload sources
              </span>
            </div>
            <div className="text-gray-600">
              Drag & drop or <span className="text-blue-600">choose file</span>{" "}
              to upload
            </div>
            {selectedFile && (
              <div className="text-sm text-gray-500">
                Selected:{" "}
                {Array.isArray(selectedFile)
                  ? `${selectedFile.length} files`
                  : selectedFile.name}
              </div>
            )}
            <div className="text-sm text-gray-500">
              Supported file types: PDF, .txt, Markdown
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <button
            className="group p-4 border bg-transparent border-solid border-gray-200 rounded-lg hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-colors"
            onClick={() => {
              /* Google Drive implementation */
            }}
            title="Upload from Google Drive"
            aria-label="Upload from Google Drive"
            disabled={isUploading}
          >
            <div className="flex flex-col items-center">
              <BsGoogle className="h-6 w-6 text-gray-600 group-hover:text-blue-600 transition-colors" />
              <span className="mt-2 text-sm font-medium text-gray-600 group-hover:text-gray-900">
                Google Drive
              </span>
            </div>
          </button>

          {showConnectToDb ? (
            <button
              className="group p-4 border bg-transparent border-solid border-gray-200 rounded-lg hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-colors"
              onClick={() => setUploadMethod("db")}
              title="Upload from Database"
              aria-label="Upload from Database"
              disabled={isUploading}
            >
              <div className="flex flex-col items-center">
                <FaDatabase className="h-6 w-6 text-gray-600 group-hover:text-blue-600 transition-colors" />
                <span className="mt-2 text-sm font-medium text-gray-600 group-hover:text-gray-900">
                  Connect to Database
                </span>
              </div>
            </button>
          ) : (
            <button
              className="group p-4 border bg-transparent border-solid border-gray-200 rounded-lg hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-colors"
              onClick={() => setUploadMethod("link")}
              title="Upload from URL"
              aria-label="Upload from URL link"
              disabled={isUploading}
            >
              <div className="flex flex-col items-center">
                <FaLink className="h-6 w-6 text-gray-600 group-hover:text-blue-600 transition-colors" />
                <span className="mt-2 text-sm font-medium text-gray-600 group-hover:text-gray-900">
                  Link
                </span>
              </div>
            </button>
          )}
          <button
            className="group p-4 border bg-transparent border-solid border-gray-200 rounded-lg hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-colors"
            onClick={() => {
              /* Microsoft implementation */
            }}
            title="Upload from OneDrive"
            aria-label="Upload from Microsoft OneDrive"
            disabled={isUploading}
          >
            <div className="flex flex-col items-center">
              <BsMicrosoft className="h-6 w-6 text-gray-600 group-hover:text-blue-600 transition-colors" />
              <span className="mt-2 text-sm font-medium text-gray-600 group-hover:text-gray-900">
                OneDrive
              </span>
            </div>
          </button>
        </div>
      </>
    );
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-xl bg-white p-6 shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-semibold leading-6 text-gray-900 mb-2"
                >
                  Add sources
                </Dialog.Title>
                <p className="text-gray-600 mb-6">
                  Sources let ThirdAI base its responses on the information that
                  matters most to you.
                </p>
                {renderSourceContent(uploadMethod)}
                <div className="mt-6 flex justify-end space-x-3">
                  {(uploadMethod === "link" || uploadMethod === "db") && (
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      onClick={() => setUploadMethod("file")}
                    >
                      Back
                    </button>
                  )}
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={handleUpload}
                    disabled={
                      isUploading ||
                      (uploadMethod === "file" && !selectedFile) ||
                      (uploadMethod === "link" && !link)
                    }
                  >
                    {isUploading ? "Uploading..." : "Upload"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DocumentUploadModal;
